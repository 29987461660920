import { createContext, useState } from "react";
import { getTheme, setTheme } from "../utils/Storage";

export const MenuContext = createContext({
    sidebarMenu: false,
    setSidebarMenu: () => {},
    sidebarActiveSubMenu: { i: null, j: null },
    setSidebarActiveSubMenu: (value) => {},
    setSidebarMenuParam: (value) => {},
    mobileTopbar: false,
    setMobileTopBar: () => {},
    listViewLang: false,
    setListViewLang: () => {},
    listViewDropDown: false,
    setListViewDropDown: () => {},
    setListViewDropDownFalse: () => {},
    setListViewLangFalse: () => {},
    dark: false,
    setDark: () => {},
    sidebarMenuItems: [],
    setSidebarMenuItems: (data) => {},
    sidebarMenuItemsChanged: true,
    setSidebarMenuItemsChanged: () => {},
});

export default function MenuContextProvider({ children }) {
    const [sidebarMenu, setSidebarMenu] = useState(true);
    const [sidebarActiveSubMenu, setSidebarActiveSubMenu] = useState({
        i: 0,
        j: 0,
    });
    const [mobileTopbar, setMobileTopBar] = useState(true);
    const [listViewLang, setListViewLang] = useState(false);
    const [listViewDropDown, setListViewDropDown] = useState(false);
    const [dark, setDark] = useState(getTheme() === "dark" ? true : false);
    const [sidebarMenuItems, setSidebarMenuItems] = useState([]);
    const [sidebarMenuItemsChanged, setSidebarMenuItemsChanged] =
        useState(true);

    function sideBarMenuHandler() {
        setSidebarMenu(!sidebarMenu);
    }
    function sideBarSubMenuHandler(value) {
        setSidebarActiveSubMenu(value);
    }

    function sideBarMenuHandlerParam(value) {
        setSidebarMenu(value);
    }

    function mobileTopbarHandler() {
        setMobileTopBar(!mobileTopbar);
    }

    function listViewDropDownHandler() {
        setListViewDropDown(!listViewDropDown);
    }
    function listViewDropDownFalseHandler() {
        setListViewDropDown(false);
    }
    function listViewLangFalseHandler() {
        setListViewLang(false);
    }

    function listViewLangHandler() {
        setListViewLang(!listViewLang);
    }

    function darkHandler() {
        setTheme(!dark);
        setDark(!dark);
    }

    function menuItemsHandler(data) {
        setSidebarMenuItems(data);
    }

    function sidebarMenuItemsChangedHandler() {
        setSidebarMenuItemsChanged(!sidebarMenuItemsChanged);
    }

    const value = {
        sidebarMenu: sidebarMenu,
        setSidebarMenu: sideBarMenuHandler,
        setSidebarMenuParam: sideBarMenuHandlerParam,
        mobileTopbar: mobileTopbar,
        setMobileTopBar: mobileTopbarHandler,
        listViewLang: listViewLang,
        setListViewLang: listViewLangHandler,
        listViewDropDown: listViewDropDown,
        setListViewDropDown: listViewDropDownHandler,
        setListViewDropDownFalse: listViewDropDownFalseHandler,
        setListViewLangFalse: listViewLangFalseHandler,
        dark: dark,
        setDark: darkHandler,
        sidebarActiveSubMenu: sidebarActiveSubMenu,
        setSidebarActiveSubMenu: sideBarSubMenuHandler,
        sidebarMenuItems: sidebarMenuItems,
        setSidebarMenuItems: menuItemsHandler,
        sidebarMenuItemsChanged: sidebarMenuItemsChanged,
        setSidebarMenuItemsChanged: sidebarMenuItemsChangedHandler,
    };

    return (
        <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
    );
}
