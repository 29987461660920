import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Icon } from "@iconify/react";
import useScreen from "../../hooks/useScreen";

const PageLink = ({ active, label, url, paginateTo }) => {
    const className = classNames([
        "pagination-list select-none",
        active && "pagination-active",
    ]);
    const paginationHandler = (label) => {
        if (label === "Next &raquo;" || label === "&laquo; Previous") {
            const params = new URLSearchParams(url);
            if (params.has("page")) {
                let page = params.get("page");
                paginateTo(parseInt(page));
                return;
            }
        }
        paginateTo(parseInt(label));
    };

    var setitem = label;
    if (label === "Next &raquo;") {
        setitem = <Icon icon="material-symbols:keyboard-arrow-right" />;
    } else if (label === "&laquo; Previous") {
        setitem = <Icon icon="material-symbols:keyboard-arrow-left" />;
    }
    return (
        <>
            {!active ? (
                <Link
                    className={className}
                    onClick={() => paginationHandler(label)}
                >
                    <span>{setitem}</span>
                </Link>
            ) : (
                <span className={className}>
                    <span>{setitem}</span>
                </span>
            )}
        </>
    );
};

// Previous, if on first page
// Next, if on last page
// and dots, if exists (...)
const PageInactive = ({ label }) => {

    var setitem = label;
    if (label === "Next &raquo;") {
        setitem = <Icon icon="material-symbols:keyboard-arrow-right" />;
    } else if (label === "&laquo; Previous") {
        setitem = <Icon icon="material-symbols:keyboard-arrow-left" />;
    }

    return <div className="pagination-list-disabled">{setitem}</div>;
};

export default function PaginationMini({ links = [], paginateTo }) {
    // dont render, if there's only 1 page (previous, 1, next)
    const screen = useScreen();
    if (links.length === 3) return null;
    if (screen.innerWidth < 639) {
        return (
            <div className="pagination-area flex flex-wrap gap-3 items-center justify-center pt-8 px-8">
                {links.map(({ active, label, url }) => {
                    if (
                        label === "Next &raquo;" ||
                        label === "&laquo; Previous"
                    ) {
                        return url === null ? (
                            <PageInactive key={label} label={label} />
                        ) : (
                            <PageLink
                                paginateTo={paginateTo}
                                key={label}
                                label={label}
                                active={active}
                                url={url}
                            />
                        );
                    }else{
                        return null
                    }
                })}
            </div>
        );
    }


    return (
        <div className="pagination-area flex flex-wrap gap-3 items-center justify-center pt-8 px-8">
            {links.map(({ active, label, url }) => {
                return url === null ? (
                    <PageInactive key={label} label={label} />
                ) : (
                    <PageLink
                        paginateTo={paginateTo}
                        key={label}
                        label={label}
                        active={active}
                        url={url}
                    />
                );
            })}
        </div>
    );
}
