import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import apiRouteList from "../../config/api-route-list-config";
import { getTheme, getUserData } from "../../utils/Storage";
import { toast } from "react-toastify";
import { AuthContext } from "../../contexts/AuthContext";
import ValidationError from "../Alerts/ValidationError";
import SubmitButton from "../Buttons/SubmitButton";
export default function ProfileUpdateForm({ setPreview, setRefresh, refresh }) {
    const [userDataStore, setUserDataStore] = useState(getUserData());
    const [profilePic, setProfilePic] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState({});
    const [t] = useTranslation();
    const [msg, setMsg] = useState(localStorage.getItem("is_pending_email"));

    const loginCtx = useContext(AuthContext);
    function getInput(event, input) {
        setUserDataStore((prevState) => ({
            ...prevState,
            [input]: event.target.value,
        }));
    }
    function getProfilePicture(event) {
        setProfilePic(event.target.files[0]);
    }

    const profileUpdateHandler = async (e) => {
        setProcessing(true);

        //preparing form data
        e.preventDefault();
        const profileUpdateFormData = new FormData();
        profileUpdateFormData.append("name", userDataStore.name);
        profileUpdateFormData.append("email", userDataStore.email);
        profileUpdateFormData.append("phone", userDataStore.phone);
        profileUpdateFormData.append("post_code", userDataStore.post_code);
        profileUpdateFormData.append("city", userDataStore.city);
        profileUpdateFormData.append("country", userDataStore.country);
        if (profilePic !== null) {
            profileUpdateFormData.append("photo", profilePic);
        }
        profileUpdateFormData.append("_method", "PUT");

        //geting backend api
        const profileUpdateApi = apiRouteList.loggedRoutes.updateProfile;

        try {
            await axios({
                method: profileUpdateApi.method,
                url: profileUpdateApi.endpoint,
                data: profileUpdateFormData,
                headers: profileUpdateApi.headers,
            })
                .then(function (response) {
                    const data = response.data;
                    localStorage.setItem("userData", JSON.stringify(data.data));
                    setUserDataStore(data.data);
                    setProcessing(false);
                    setError({});
                    setProfilePic(null);
                    setRefresh(!refresh);
                    loginCtx.setLogin_trigger();
                    toast.success(data.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: getTheme() === "dark" ? "dark" : "light",
                    });
                    localStorage.setItem(
                        "is_pending_email",
                        data.data.is_pending_email
                    );
                    setMsg(data.data.is_pending_email);
                })
                .catch(function (error) {
                    setError(error.response.data.errors);
                    setProcessing(false);
                });
        } catch (error) {
            setProcessing(false);
        }
    };

    useEffect(() => {
        if (!profilePic) {
            setPreview(undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(profilePic);
        setPreview(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profilePic]);

    return (
        <>
            {msg === "true" && (
                <div className="bg-red-100 text-red-600 border-2 border-red-600 p-4 mb-2 rounded-md">
                    {t(
                        "Please check your email to verify your new email address. You cant use your new email to login until you verify it."
                    )}
                </div>
            )}
            <div className="rounded-md overflow-hidden">
                <div className="bg-white dark:bg-slate-800 px-5 py-7 border-b border-b-slate-100 dark:border-b-slate-900">
                    <h1 className="font-Outfit text-2xl text-black dark:text-white font-semibold">
                        {t("Edit Profile")}
                    </h1>
                </div>
                <div className="bg-white dark:bg-slate-800 px-5 py-6">
                    <form onSubmit={(e) => profileUpdateHandler(e)}>
                        <div className="grid sm:grid-cols-1 gap-5 pb-2">
                            <div className="input-group">
                                <label htmlFor="name" className="inputLabel">
                                    {t("Name")}
                                </label>
                                <input
                                    onChange={(e) => getInput(e, "name")}
                                    defaultValue={
                                        userDataStore.name !== "null"
                                            ? userDataStore.name
                                            : ""
                                    }
                                    type="text"
                                    id="name"
                                    className="inputField2 w-full"
                                    placeholder="Name"
                                    required
                                />
                                <ValidationError value={error.name} />
                            </div>
                        </div>
                        <div className="grid sm:grid-cols-2 gap-5">
                            <div className="input-group">
                                <label htmlFor="email" className="inputLabel">
                                    {t("Email")}
                                </label>
                                <input
                                    onChange={(e) => getInput(e, "email")}
                                    defaultValue={
                                        userDataStore.email !== "null"
                                            ? userDataStore.email
                                            : ""
                                    }
                                    type="email"
                                    id="email"
                                    className="inputField2 w-full"
                                    placeholder="Enter your email"
                                    required
                                />
                                <ValidationError value={error.email} />
                            </div>
                            <div className="input-group">
                                <label htmlFor="phone" className="inputLabel">
                                    {t("Phone")}
                                </label>
                                <input
                                    onChange={(e) => getInput(e, "phone")}
                                    defaultValue={
                                        userDataStore.phone !== "null"
                                            ? userDataStore.phone
                                            : ""
                                    }
                                    type="tel"
                                    id="phone"
                                    className="inputField2 w-full"
                                    placeholder="Phone"
                                />
                                <ValidationError value={error.phone} />
                            </div>
                            <div className="input-group">
                                <label
                                    htmlFor="postcode"
                                    className="inputLabel"
                                >
                                    {t("Post Code")}
                                </label>
                                <input
                                    onChange={(e) => getInput(e, "post_code")}
                                    defaultValue={
                                        userDataStore.post_code !== "null"
                                            ? userDataStore.post_code
                                            : ""
                                    }
                                    type="text"
                                    id="post"
                                    className="inputField2 w-full"
                                    placeholder="Post Code"
                                />
                                <ValidationError value={error.post_code} />
                            </div>
                            <div className="input-group">
                                <label htmlFor="state" className="inputLabel">
                                    {t("State/City")}
                                </label>
                                <input
                                    onChange={(e) => getInput(e, "city")}
                                    defaultValue={
                                        userDataStore.city !== "null"
                                            ? userDataStore.city
                                            : ""
                                    }
                                    type="text"
                                    id="state"
                                    className="inputField2 w-full"
                                    placeholder="State/City"
                                />
                                <ValidationError value={error.city} />
                            </div>
                            <div className="input-group">
                                <label htmlFor="country" className="inputLabel">
                                    {t("Country")}
                                </label>
                                <input
                                    onChange={(e) => getInput(e, "country")}
                                    defaultValue={
                                        userDataStore.country !== "null"
                                            ? userDataStore.country
                                            : ""
                                    }
                                    type="text"
                                    id="country"
                                    className="inputField2 w-full"
                                    placeholder="Country"
                                />
                                <ValidationError value={error.country} />
                            </div>
                            <div className="input-group">
                                <label className="inputLabel w-full">
                                    {t("File Upload")}
                                    <input
                                        onChange={(e) => {
                                            getProfilePicture(e);
                                        }}
                                        className="hidden"
                                        id="file_input"
                                        type="file"
                                    />
                                    <div className="inputField2 p-0 w-full mt-[9px]">
                                        <span
                                            htmlFor="file_input"
                                            className="cursor-pointer bg-slate-100 hover:bg-slate-200 dark:bg-slate-600 text-Color p-3 rounded inline-flex items-center"
                                        >
                                            {t("Browse")}
                                        </span>
                                        <span className="px-1">
                                            {profilePic === null ? (
                                                t("No file choosen")
                                            ) : (
                                                <span className="bg-green-400 text-zinc-800 p-2 py-1 rounded-md">
                                                    {profilePic.name.substring(
                                                        0,
                                                        20
                                                    )}
                                                    {profilePic.name.length <=
                                                    20
                                                        ? ""
                                                        : "...."}
                                                </span>
                                            )}
                                        </span>
                                    </div>
                                </label>
                                <ValidationError value={error.photo} />
                            </div>
                        </div>
                        <SubmitButton processing={processing}>
                            {t("Update info")}
                        </SubmitButton>
                    </form>
                </div>
            </div>
        </>
    );
}
