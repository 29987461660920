import Table from "../../../../components/Tables/Table";
import TableCol from "../../../../components/Tables/TableCol";
import TableRow from "../../../../components/Tables/TableRow";
import ActionBtnGroupWrapper from "../../../../Layouts/Containers/ActionBtnGroupWrapper";
import { useEffect, useState } from "react";
import { loggedRoutes } from "../../../../config/apiRouteLists/logged-route-lists";
import axios from "axios";
import { Icon } from "@iconify/react";
import { getTheme, getToken } from "../../../../utils/Storage";
import reactRouteList from "../../../../config/react-route-list-config";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import apiRouteList from "../../../../config/api-route-list-config";
import { permissionCheck } from "../../../../utils/permission";
import { useTranslation } from "react-i18next";
import { formatedDate } from "../../../../utils/Date";
import { processSortParams } from "../../../../utils/String";
import { TableDataNoRecords } from "../../../../components/Alerts/TableDataNoRecords";
import TableHeaderCol from "../../../../components/Tables/TableHeaderCol";
import { Tooltip } from "react-tippy";
import { toast } from "react-toastify";
export default function PermissionTable() {
    const [, setError] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [pageInfo, setPageInfo] = useState({});
    const [qParams, setQParams] = useState({});
    const [slStep, setSlStep] = useState(10);
    const [sortParams, setSortParams] = useState({});
    const [dataAvailable, setDataAvailable] = useState(true);
    let sl_base = 1;
    const [t] = useTranslation();

    const [deleted, setDeleted] = useState(false);

    async function fetchPermissions(params = {}) {
        const sortingQuery = processSortParams(sortParams);
        try {
            const permissionsApi = loggedRoutes.permissions;
            await axios({
                method: permissionsApi.method,
                url: permissionsApi.endpoint,
                headers: permissionsApi.headers,
                params: { ...params, ...sortingQuery },
            })
                .then((response) => {
                    const data = response.data;
                    setPermissions(data.data);
                    setPageInfo(data.meta);
                    setDataAvailable(() =>
                        data.data.length > 0 ? true : false
                    );
                    setSlStep(
                        data.meta.per_page * (data.meta.current_page - 1)
                    );
                })
                .catch((error) => {
                    setError(error.response.data.errors);
                    toast.error("Error!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: getTheme() === "dark" ? "dark" : "light",
                    });
                });
        } catch (error) {
            toast.error("Error!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: getTheme() === "dark" ? "dark" : "light",
            });
        }
    }

    useEffect(() => {
        fetchPermissions();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleted]);

    const queryHandler = (searchThis) => {
        fetchPermissions(searchThis);
        setQParams(searchThis);
    };

    useEffect(() => {
        const sortFetchHandler = () => {
            fetchPermissions(qParams);
        };
        sortFetchHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortParams]);

    async function deleteHandler(permission) {
        const willDelete = await swal({
            title: t("Are you sure?"),
            text: t("Are you sure that you want to delete this Permission?"),
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: t("No"),
                yes: t("yes"),
            },
        });

        if (willDelete === "yes") {
            try {
                const deletePermission =
                    apiRouteList.loggedRoutes.deletePermission;
                deletePermission.headers.Authorization = "Bearer " + getToken();

                await axios({
                    method: deletePermission.method,
                    url: deletePermission.endpoint + permission.id,
                    headers: deletePermission.headers,
                })
                    .then((response) => {
                        setDeleted(!deleted);
                        swal({
                            title: t("Deleted!"),
                            text: t("Permission deleted successfully!"),
                            icon: "success",
                            buttons: {
                                cancel: t("Ok"),
                            },
                        });
                    })
                    .catch(() => {
                        swal({
                            title: t("Failed!"),
                            text: t("Something went wrong! Try again!"),
                            icon: "error",
                            buttons: {
                                cancel: t("Ok"),
                            },
                        });
                    });
            } catch (error) {
                await swal({
                    title: t("Failed!"),
                    text: t("Something went wrong! Try again!"),
                    icon: "error",
                    buttons: {
                        cancel: t("Ok"),
                    },
                });
            }
        }
    }

    return (
        <>
            <Table
                tableInfo={{
                    title: t("Permissions"),
                    currentPageInfo: {
                        from: slStep + sl_base,
                        to: slStep + permissions.length,
                    },
                    total: pageInfo.total,
                    checkboxInputArray: [],
                }}
                tableOptions={{
                    search: true,
                    queryHandler: queryHandler,
                    filter: true,
                    title: true,
                    pageDropdown: true,
                    paginationBtnGroup: true,
                }}
                links={pageInfo.links}
            >
                <TableRow header={true}>
                    <TableHeaderCol sort={false} sticky={true}>
                        {t("Sl No.")}
                    </TableHeaderCol>
                    <TableHeaderCol
                        sort={true}
                        setSortParams={setSortParams}
                        sortParamName={"name"}
                    >
                        {t("Name")}
                    </TableHeaderCol>

                    <TableHeaderCol
                        sort={true}
                        setSortParams={setSortParams}
                        sortParamName={"created_at"}
                    >
                        {t("Created At")}
                    </TableHeaderCol>
                    <TableHeaderCol sort={false}>{t("Action")}</TableHeaderCol>
                </TableRow>
                {!dataAvailable ? (
                    <TableDataNoRecords colSpan={5} />
                ) : (
                    permissions.map((item, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCol sticky={true}>
                                    # {slStep + sl_base++}
                                </TableCol>
                                <TableCol>{item.name}</TableCol>
                                <TableCol>
                                    {formatedDate(item.created_at)}
                                </TableCol>
                                <TableCol>
                                    <ActionBtnGroupWrapper>
                                        {permissionCheck(
                                            "permission update"
                                        ) && (
                                            <Tooltip
                                                title={t("Edit")}
                                                position="top"
                                                trigger="mouseenter"
                                            >
                                                <Link
                                                    to={
                                                        reactRouteList
                                                            .loggedRoutes
                                                            .edit_permission
                                                            .link +
                                                        "/" +
                                                        item.id
                                                    }
                                                    id={"edit-button" + item.id}
                                                >
                                                    <Icon icon={"uil:edit"} />
                                                </Link>
                                            </Tooltip>
                                        )}
                                        {permissionCheck(
                                            "permission delete"
                                        ) && (
                                            <Tooltip
                                                title={t("Delete")}
                                                position="top"
                                                trigger="mouseenter"
                                            >
                                                <Link
                                                    onClick={() =>
                                                        deleteHandler({
                                                            id: item.id,
                                                            name: item.name,
                                                        })
                                                    }
                                                    id={
                                                        "delete-button" +
                                                        item.id
                                                    }
                                                >
                                                    <Icon
                                                        icon={
                                                            "fluent:delete-24-regular"
                                                        }
                                                    />
                                                </Link>
                                            </Tooltip>
                                        )}
                                    </ActionBtnGroupWrapper>
                                </TableCol>
                            </TableRow>
                        );
                    })
                )}
            </Table>
        </>
    );
}
