import { useTranslation } from "react-i18next";
import TableRow from "../Tables/TableRow";

export function TableDataNoRecords({colSpan}){
    const [t] = useTranslation();
    return (<TableRow>
        <td className="py-10 text-black dark:text-white" colSpan={colSpan}>
            {t("No data found!")}
        </td>
    </TableRow>)
}