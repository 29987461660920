import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import apiRouteList from "../../config/api-route-list-config";
import { getTheme } from "../../utils/Storage";
import ValidationError from "../Alerts/ValidationError";
import SubmitButton from "../Buttons/SubmitButton";

export default function SettingsForm({ inputs }) {
    const [formdata, setFormData] = useState({});
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState({});
    const [t] = useTranslation();

    function getInput(event, input) {
        setFormData((prevState) => ({
            ...prevState,
            [input]: event.target.value,
        }));
    }

    async function formSubmitHandler(e) {
        e.preventDefault();
        setProcessing(true);

        let submitableFormData = new FormData();

        for (var key in formdata) {
            submitableFormData.append(key, formdata[key]);
        }
        submitableFormData.append("_method", "PUT");

        //geting backend api
        const envUpdateApi = apiRouteList.loggedRoutes.envUpdate;

        try {
            await axios({
                method: envUpdateApi.method,
                url: envUpdateApi.endpoint,
                data: submitableFormData,
                headers: envUpdateApi.headers,
            })
                .then(function (response) {
                    setProcessing(false);
                    setError({});
                    toast.success(t("Successfully Updated!"), {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: getTheme() === "dark" ? "dark" : "light",
                    });
                })
                .catch(function (error) {
                    setError(error.response.data.errors);
                    setProcessing(false);
                });
        } catch (error) {
            setProcessing(false);
        }
    }

    return (
        <form onSubmit={(e) => formSubmitHandler(e)} className="space-y-5 sdc">
            {inputs.map((item, index) => {
                return (
                    <div key={index} className="input-group">
                        <label htmlFor={item.key} className="inputLabel">
                            {item.key}
                        </label>
                        <input
                            type="text"
                            name={item.key}
                            id={item.key}
                            className="inputField2 w-full"
                            defaultValue={item.data.value}
                            placeholder={item.key}
                            onChange={(e) => getInput(e, item.key)}
                        />
                        <ValidationError value={error[item.key]} />
                    </div>
                );
            })}

            <SubmitButton processing={processing}>{t("Save")}</SubmitButton>
        </form>
    );
}
