import axios from "axios";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import apiRouteList from "../../config/api-route-list-config";
import ValidationError from "../Alerts/ValidationError";
import SubmitButton from "../Buttons/SubmitButton";

export default function CreatePermissionForm() {
    const [permissionData, setPermissionData] = useState({});
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState({});
    const [t] = useTranslation();
    const nameInputRef = useRef(null);
    const moduleInputRef = useRef(null);
    function getInput(event, input) {
        let userDataTemp = permissionData;
        userDataTemp[input] = event.target.value;
        setPermissionData(userDataTemp);
    }

    const createPermissionHandler = async (e) => {
        setProcessing(true);
        //preparing form data
        e.preventDefault();
        const createPermissionFormData = new FormData();
        createPermissionFormData.append("name", permissionData.name);
        createPermissionFormData.append("module_name", permissionData.module);

        //geting backend api
        let createPermissionApi = apiRouteList.loggedRoutes.createPermission;
        try {
            await axios({
                method: createPermissionApi.method,
                url: createPermissionApi.endpoint,
                data: createPermissionFormData,
                headers: createPermissionApi.headers,
            })
                .then((response) => {
                    setProcessing(false);
                    setError({});
                    moduleInputRef.current.value = "";
                    nameInputRef.current.value = "";
                    swal({
                        title: t("Successful"),
                        text: t("Permission created successfully!"),
                        icon: "success",
                        buttons: {
                            cancel: t("Ok"),
                        },
                    });
                })
                .catch((error) => {
                    setError(error.response.data.errors);
                    setProcessing(false);
                });
        } catch (error) {
            setProcessing(false);
        }
    };

    return (
        <>
            <form
                onSubmit={(e) => createPermissionHandler(e)}
                className="lg:w-full  bg-white dark:bg-slate-800 rounded-md p-5 pb-6 flex justify-center"
            >
                <div className="lg:w-2/4">
                    <div className="grid-cols-2 gap-x-8 gap-y-4">
                        <div className="input-group">
                            <label htmlFor="name" className="inputLabel">
                                {t("Module Name")}
                            </label>
                            <input
                                onChange={(e) => getInput(e, "module")}
                                ref={moduleInputRef}
                                name="module_name"
                                type="text"
                                id="module_name"
                                className="inputField2 w-full"
                                placeholder={t("Enter module name")}
                                required={true}
                            />
                            <ValidationError value={error.module_name} />
                        </div>

                        <div className="input-group">
                            <label htmlFor="name" className="inputLabel">
                                {t("Name")}
                            </label>
                            <input
                                onChange={(e) => getInput(e, "name")}
                                ref={nameInputRef}
                                name="name"
                                type="text"
                                id="name"
                                className="inputField2 w-full"
                                placeholder={t("Enter permission name")}
                                required={true}
                            />
                            <ValidationError value={error.name} />
                        </div>
                    </div>
                    <SubmitButton processing={processing}>
                        {t("Save")}
                    </SubmitButton>
                </div>
            </form>
        </>
    );
}
