import { Icon } from "@iconify/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import apiRouteList from "../../config/api-route-list-config";
import { getToken } from "../../utils/Storage";
import ValidationError from "../Alerts/ValidationError";
import SubmitButton from "../Buttons/SubmitButton";
import Spinner from "../Preloader/Spinner";
export default function CreateUserForm() {
    const [userData, setUserData] = useState({});
    const [rolesData, setRolesData] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [processingRole, setProcessingRole] = useState(false);
    const [error, setError] = useState({});
    const [, setErrorRole] = useState(null);
    const [t] = useTranslation();

    function getInput(event, input) {
        let userDataTemp = userData;
        userDataTemp[input] = event.target.value;
        setUserData(userDataTemp);
    }

    const createUserHandler = async (e) => {
        setProcessing(true);
        //preparing form data
        e.preventDefault();
        const createUserFormData = new FormData();
        createUserFormData.append("name", userData.name);
        createUserFormData.append("email", userData.email);
        createUserFormData.append("password", userData.password);
        createUserFormData.append("role", userData.role);

        //geting backend api
        let createUserApi = apiRouteList.loggedRoutes.createUser;
        createUserApi.headers.Authorization = "Bearer " + getToken();

        try {
            await axios({
                method: createUserApi.method,
                url: createUserApi.endpoint,
                data: createUserFormData,
                headers: createUserApi.headers,
            })
                .then(() => {
                    setProcessing(false);
                    setError(() => []);
                    swal({
                        title: t("Successful"),
                        text: t("User Created Successfully!"),
                        icon: "success",
                        buttons: {
                            cancel: t("Ok"),
                        },
                    });
                })
                .catch((error) => {
                    setError(error.response.data.errors);
                    setProcessing(false);
                });
        } catch (error) {
            setProcessing(false);
        }
    };

    useEffect(() => {
        const showRoleHandler = async (e) => {
            setProcessingRole(true);

            //geting backend api
            let fetchRolesApi = apiRouteList.loggedRoutes.roles;

            try {
                await axios({
                    method: fetchRolesApi.method,
                    url: fetchRolesApi.endpoint,
                    headers: fetchRolesApi.headers,
                })
                    .then((response) => {
                        setProcessingRole(false);
                        const data = response.data;
                        setRolesData(data.data);
                    })
                    .catch((error) => {
                        setErrorRole(error.response.data.errors);
                        setProcessingRole(false);
                    });
            } catch (error) {
                setProcessingRole(false);
            }
        };
        showRoleHandler();
    }, []);

    return (
        <>
            <form
                onSubmit={(e) => createUserHandler(e)}
                className="bg-white dark:bg-slate-800 rounded-md p-5 pb-6"
            >
                <div className="grid sm:grid-cols-2 gap-x-8 gap-y-4">
                    <div className="input-group">
                        <label htmlFor="name" className="inputLabel">
                            {t("Name")}
                        </label>
                        <input
                            onChange={(e) => getInput(e, "name")}
                            name="name"
                            type="text"
                            id="name"
                            className="inputField2 w-full"
                            placeholder={t("Enter your name")}
                            required={true}
                        />
                        <ValidationError value={error.name} />
                    </div>

                    <div className="input-group">
                        <label htmlFor="email" className="inputLabel">
                            {t("Email")}
                        </label>
                        <input
                            onChange={(e) => getInput(e, "email")}
                            name="email"
                            type="email"
                            id="email"
                            className="inputField2 w-full"
                            placeholder={t("Enter your email")}
                            required={true}
                        />
                        <ValidationError value={error.email} />
                    </div>

                    <div className="input-group">
                        <label htmlFor="password" className="inputLabel">
                            {t("Password")}
                        </label>
                        <input
                            onChange={(e) => getInput(e, "password")}
                            name="password"
                            type="password"
                            id="password"
                            className="inputField2 w-full"
                            placeholder={t("Enter your password")}
                        />
                        <ValidationError value={error.password} />
                    </div>

                    <div className="input-group w-full sm:w-auto">
                        <label className="inputLabel">{t("Role")}</label>
                        {processingRole ? (
                            <>
                                <Spinner />
                                <select
                                    disabled={true}
                                    defaultValue={null}
                                    name="role"
                                    className="dropdownTableSelect h-12 px-4 w-full font-Outfit text-textColor font-medium "
                                ></select>
                            </>
                        ) : (
                            <select
                                defaultValue={null}
                                onChange={(e) => getInput(e, "role")}
                                name="role"
                                className="dropdownTableSelect h-12 px-4 w-full font-Outfit text-textColor font-medium "
                            >
                                <option value={null}>{t("Select role")}</option>
                                {rolesData.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>
                                            {item.name}
                                        </option>
                                    );
                                })}
                            </select>
                        )}
                        <Icon
                            className={`absolute right-3 ${
                                error.role ? "bottom-9" : "bottom-3"
                            } text-xl dark:text-white`}
                            icon="material-symbols:keyboard-arrow-down-rounded"
                        ></Icon>
                        <ValidationError value={error.role} />
                    </div>
                </div>

                <SubmitButton processing={processing}>{t("Save")}</SubmitButton>
            </form>
        </>
    );
}
