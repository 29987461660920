import { Icon } from "@iconify/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../Pagination/Pagination";
import { Tooltip } from "react-tippy";

export default function Table({ children, tableInfo, tableOptions, links , buttons=''}) {
    const [t] = useTranslation();
    const [search, setSearch] = useState({});

    function getSearch(e) {
        setSearch({
            q: e.target.value,
        });
    }

    function searchHandler(e) {
        if (e.key === "Enter") {
            tableOptions.queryHandler(search);
        }
    }

    function paginateTo(current_page) {
        let obj = search;
        obj.page = current_page;
        setSearch(obj);

        tableOptions.queryHandler(search);
    }

    function getPerPageItem(e) {
        let obj = search;
        obj.per_page = e.target.value;
        obj.page = 1;
        setSearch(obj);

        tableOptions.queryHandler(search);
    }

    return (
        <>
            <div className="bg-white dark:bg-slate-800 rounded-lg">
                <div className="py-8">
                    {
                        <div className="block items-center justify-between lg:flex px-8">
                            {tableOptions.title && (
                                <h3 className="font-medium text-lg text-black font-Outfit dark:text-white text-center mb-5 lg:mb-0 lg:text-left">
                                    {tableInfo.title}
                                </h3>
                            )}

                            {tableInfo.checkboxInputArray.length > 0 && (
                                <div className="w-full block   text-zinc-900 dark:text-white">
                                    <div className="flex text-lg items-center md:justify-start sm:justify-center xs:justify-between py-1 px-4 space-x-2 w-full">
                                        <span className="select-none  px-2 flex items-center justify-start">
                                            {tableInfo.checkboxInputArray
                                                .length <
                                            tableInfo.currentPageInfo.total ? (
                                                <Icon
                                                    
                                                    icon={
                                                        "ri:checkbox-indeterminate-fill"
                                                    }
                                                />
                                            ) : (
                                                <Icon
                                                    icon={"ri:checkbox-fill"}
                                                />
                                            )}
                                            {
                                                tableInfo.checkboxInputArray
                                                    .length
                                            }
                                        </span>

                                        <Tooltip
                                            title={t("Delete Selected")}
                                            position="top"
                                            trigger="mouseenter"
                                        >
                                            <button
                                                onClick={() =>
                                                    tableOptions.batchDeleteHandler()
                                                }
                                                id="delete-selected-button"
                                                className=" px-2  text-red-500 select-none cursor-pointer pr-1 flex items-center justify-start"
                                            >
                                                <Icon
                                                    icon={
                                                        "material-symbols:delete-outline"
                                                    }
                                                />
                                                (
                                                {
                                                    tableInfo.checkboxInputArray
                                                        .length
                                                }
                                                )
                                            </button>
                                        </Tooltip>

                                        <Tooltip
                                            title={t("Reload page")}
                                            position="top"
                                            trigger="mouseenter"
                                        >
                                            <button
                                                id="reload-page-button"
                                                onClick={() =>
                                                    window.location.reload()
                                                }
                                                className=" px-2 pl-4 select-none cursor-pointer pr-1 flex items-center justify-start"
                                            >
                                                <Icon
                                                    icon={
                                                        "zondicons:reload"
                                                    }
                                                />
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>
                            )}

                            <div className="justify-center flex flex-wrap sm:flex items-center lg:justify-end gap-3">
                                {tableOptions.search && (
                                    <div className="relative w-full sm:w-auto">
                                        <input
                                            onKeyDown={(e) => searchHandler(e)}
                                            onChange={(e) => getSearch(e)}
                                            type="text"
                                            className="inputField pl-8"
                                            placeholder={t("Search")}
                                        />
                                        <Icon
                                            className="absolute text-textColor left-2 top-3 dark:text-white"
                                            icon="quill:search-alt"
                                        ></Icon>
                                    </div>
                                )}
                                {buttons}
                            </div>
                        </div>
                    }
                    <div className="pt-3 w-full overflow-hidden overflow-x-scroll lg:overflow-auto">
                        <table className="w-full border-collapse text-center">
                            <tbody>{children}</tbody>
                        </table>
                    </div>
                    <div className="pagination-area flex flex-wrap gap-3 items-center justify-between pt-8 px-8">
                        {tableOptions.pageDropdown && (
                            <div className="font-medium font-Outfit text-sm text-textColor dark:text-white flex items-center">
                                <div className="relative">
                                    <select
                                        defaultValue={10}
                                        onChange={(e) => getPerPageItem(e)}
                                        id="tableRow"
                                        className="dropdownTableSelect"
                                    >
                                        <option value="10">10</option>
                                        <option value="15">15</option>
                                        <option value="25">25</option>
                                    </select>
                                    <Icon
                                        className="absolute right-3 top-2 text-xl dark:text-white z-10"
                                        icon="material-symbols:keyboard-arrow-down-rounded"
                                    ></Icon>
                                </div>
                                <p>
                                    {(tableInfo.currentPageInfo.from?tableInfo.currentPageInfo.from:0) +
                                        " - " +
                                        (tableInfo.currentPageInfo.to?tableInfo.currentPageInfo.to:0) +
                                        " of " +
                                        (tableInfo.total?tableInfo.total:0) +
                                        " " +
                                        t("items")}
                                </p>
                            </div>
                        )}
                        {tableOptions.paginationBtnGroup && (
                            <Pagination links={links} paginateTo={paginateTo} />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}
