import { Routes, Route } from "react-router-dom";
import reactRouteList from "../config/react-route-list-config";
import NotfoundPage from "../pages/error/NotFoundPage/NotfoundPage";
import AnalyticDashboardPage from "../pages/user/analyticdashboardpage/AnalyticDashboardPage";
import EcommerceDashboardPage from "../pages/user/ecommercedashboardpage/EcommerceDashboardPage";
import PermissionCreatePage from "../pages/user/permission_page_group/permissioncreatepage/PermissionCreatePage";
import PermissionListPage from "../pages/user/permission_page_group/permissionlistpage/PermissionListPage";
import PermissionEditPage from "../pages/user/permission_page_group/permissionreditpage/PermissionEditPage";
import ProfilePage from "../pages/user/profilepage/ProfilePage";
import RoleCreatePage from "../pages/user/roles_page_group/rolecreatepage/RoleCreatePage";
import RoleEditPage from "../pages/user/roles_page_group/roleeditpage/RoleEditPage";
import RoleListPage from "../pages/user/roles_page_group/rolelistpage/RoleListPage";
import RoleShowPage from "../pages/user/roles_page_group/roleshowpage/RoleShowPage";
import UserCreatePage from "../pages/user/userlist_page_group/usercreatepage/UserCreatePage";
import UserEditPage from "../pages/user/userlist_page_group/usereditpage/UserEditPage";
import UserListPage from "../pages/user/userlist_page_group/userlistpage/UserListPage";
import UserShowPage from "../pages/user/userlist_page_group/usershowpage/UserShowPage";
import usePermission from "../hooks/usePermission";
import UnAuthorizedPage from "../pages/error/UnAuthorizedPage/UnAuthorizedPage";
import SettingsPage from "../pages/user/settingspage/SettingsPage";
import SamplePage from "../pages/user/samplepage/SamplePage";
import DatabaseBackupPage from "../pages/backup/databasebackuppage/DatabaseBackupPage";

export default function LoggedRoutes() {
    const routes = reactRouteList.loggedRoutes;
    return (
        <>
            <Routes>
                {/* basic routes ======================================================================================= */}
                <Route
                    path={routes.dashboard}
                    element={<AnalyticDashboardPage />}
                />
                <Route
                    path={routes.dashboard_ecommerce}
                    element={<EcommerceDashboardPage />}
                />
                <Route path={routes.profile} element={<ProfilePage />} />
                <Route
                    path={routes.single_link_menu}
                    element={<SamplePage />}
                />
                <Route
                    path={routes.nested_menu_link_1_1}
                    element={<SamplePage />}
                />
                <Route
                    path={routes.nested_menu_link_1_2}
                    element={<SamplePage />}
                />
                <Route
                    path={routes.nested_menu_link_2}
                    element={<SamplePage />}
                />
                <Route
                    path={routes.database_backup}
                    element={
                        usePermission("database_backup viewAny") ? (
                            <DatabaseBackupPage />
                        ) : (
                            <UnAuthorizedPage />
                        )
                    }
                />

                {/* list related ==========================================================================================*/}
                <Route
                    path={routes.role_list}
                    element={
                        usePermission("role index") ? (
                            <RoleListPage />
                        ) : (
                            <UnAuthorizedPage />
                        )
                    }
                />

                <Route
                    path={routes.permission_list}
                    element={
                        usePermission("permission index") ? (
                            <PermissionListPage />
                        ) : (
                            <UnAuthorizedPage />
                        )
                    }
                />

                <Route
                    path={routes.user_list}
                    element={
                        usePermission("user index") ? (
                            <UserListPage />
                        ) : (
                            <UnAuthorizedPage />
                        )
                    }
                />

                {/* create ==================================================================================================*/}
                <Route
                    path={routes.create_users}
                    element={
                        usePermission("user create") ? (
                            <UserCreatePage />
                        ) : (
                            <UnAuthorizedPage />
                        )
                    }
                />

                <Route
                    path={routes.create_permissions}
                    element={
                        usePermission("permission create") ? (
                            <PermissionCreatePage />
                        ) : (
                            <UnAuthorizedPage />
                        )
                    }
                />

                <Route
                    path={routes.create_roles}
                    element={
                        usePermission("role create") ? (
                            <RoleCreatePage />
                        ) : (
                            <UnAuthorizedPage />
                        )
                    }
                />

                {/* show by id ===========================================================================================*/}
                <Route
                    path={routes.show_user.link + routes.show_user.param.id}
                    element={
                        usePermission("user show") ? (
                            <UserShowPage />
                        ) : (
                            <UnAuthorizedPage />
                        )
                    }
                />

                <Route
                    path={routes.show_role.link + routes.show_role.param.id}
                    element={
                        usePermission("role show") ? (
                            <RoleShowPage />
                        ) : (
                            <UnAuthorizedPage />
                        )
                    }
                />

                {/* edit by id ==============================================================================================*/}
                <Route
                    path={routes.edit_user.link + routes.edit_user.param.id}
                    element={
                        usePermission("user update") ? (
                            <UserEditPage />
                        ) : (
                            <UnAuthorizedPage />
                        )
                    }
                />
                <Route
                    path={routes.edit_role.link + routes.edit_role.param.id}
                    element={
                        usePermission("role update") ? (
                            <RoleEditPage />
                        ) : (
                            <UnAuthorizedPage />
                        )
                    }
                />
                <Route
                    path={
                        routes.edit_permission.link +
                        routes.edit_permission.param.id
                    }
                    element={
                        usePermission("permission update") ? (
                            <PermissionEditPage />
                        ) : (
                            <UnAuthorizedPage />
                        )
                    }
                />
                <Route path={routes.settings} element={<SettingsPage />} />
                <Route path={"*"} element={<NotfoundPage />} />
            </Routes>
        </>
    );
}
