import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RolePermissionCard from "../../../../components/Cards/RolePermissionCard";
import apiRouteList from "../../../../config/api-route-list-config";

export default function ShowRole({ id }) {
    const [roleData, setRoleData] = useState([]);
    const [roleName, setRoleName] = useState(null);
    const [, setProcessing] = useState(false);
    const [, setError] = useState(null);
    const [t] = useTranslation();

    useEffect(() => {
        const showRoleHandler = async (e) => {
            setProcessing(true);

            //geting backend api
            let roleShowApi = apiRouteList.loggedRoutes.getRoleByID;

            try {
                await axios({
                    method: roleShowApi.method,
                    url: roleShowApi.endpoint + id,
                    headers: roleShowApi.headers,
                })
                    .then((response) => {
                        setProcessing(false);
                        const data = response.data;
                        setRoleName(data.data.name);

                        let array = [];
                        let permissionModules = data.data.modules;
                        for (const key in permissionModules) {
                            array.push(
                                <RolePermissionCard
                                    permittedId={data.data.permissionIds}
                                    title={key}
                                    permissionList={permissionModules[key]}
                                    disabledChecked={true}
                                />
                            );
                        }
                        setRoleData(array);
                    })
                    .catch((error) => {
                        setError(error.response.data.errors);
                        setProcessing(false);
                    });
            } catch (error) {
                setProcessing(false);
            }
        };

        showRoleHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <>
            <div className="rounded-md overflow-hidden">
                <div className="bg-white dark:bg-slate-800 px-5 py-7 border-b border-b-slate-100 dark:border-b-slate-900">
                    <h1 className="font-Outfit text-2xl text-black dark:text-white font-semibold">
                        {t("View Role")}
                    </h1>
                </div>
                <div className="bg-white dark:bg-slate-800 px-5 py-7 ">
                    <form>
                        <div className="input-group">
                            <label htmlFor="name" className="inputLabel">
                                {t("Role Name")}
                            </label>

                            <input
                                type="text"
                                value={roleName}
                                disabled={true}
                                id="name"
                                className="inputField2 w-full"
                                placeholder={t("Role")}
                                required
                            />
                        </div>
                        <h3 className="font-Outfit font-semibold text-xl text-black dark:text-white py-5">
                            {t("Permissions")}
                        </h3>
                        <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-7">
                            {roleData}
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}
