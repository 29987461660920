import Table from "../../../../components/Tables/Table";
import TableCol from "../../../../components/Tables/TableCol";
import Checkbox from "../../../../components/Inputs/Checkbox";
import TableRow from "../../../../components/Tables/TableRow";
import Badge from "../../../../components/Badges/Badge";
import ActionBtnGroupWrapper from "../../../../Layouts/Containers/ActionBtnGroupWrapper";
import { useEffect, useRef, useState } from "react";
import { loggedRoutes } from "../../../../config/apiRouteLists/logged-route-lists";
import axios from "axios";
import { Icon } from "@iconify/react";
import reactRouteList from "../../../../config/react-route-list-config";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import apiRouteList from "../../../../config/api-route-list-config";
import { processSortParams } from "../../../../utils/String";
import { dateToYears } from "../../../../utils/Date";
import Avatar from "react-avatar";
import { permissionCheck } from "../../../../utils/permission";
import { useTranslation } from "react-i18next";
import { TableDataNoRecords } from "../../../../components/Alerts/TableDataNoRecords";
import TableHeaderCol from "../../../../components/Tables/TableHeaderCol";
import { Tooltip } from "react-tippy";
import { toast } from "react-toastify";
import { getTheme } from "../../../../utils/Storage";

export default function UserTable() {
    const [, setError] = useState(null);
    const [users, setUsers] = useState([]);
    const [pageInfo, setPageInfo] = useState({});
    const [qParams, setQParams] = useState({});
    const [slStep, setSlStep] = useState(10);
    const [sortParams, setSortParams] = useState({});
    const [dataAvailable, setDataAvailable] = useState(true);

    const checkBoxArrayRef = useRef([]);
    const checkBoxRef = useRef(false);
    const [checkboxInputArray, setCheckBoxInputArray] = useState([]);

    const [t] = useTranslation();
    let sl_base = 1;

    const [deleted, setDeleted] = useState(false);

    async function fetchUser(params = {}) {
        //clear checkbox
        setCheckBoxInputArray(() => []);
        checkBoxRef.current.checked = false;
        checkBoxArrayRef.current = [];

        const sortingQuery = processSortParams(sortParams);

        try {
            const usersApi = loggedRoutes.users;
            await axios({
                method: usersApi.method,
                url: usersApi.endpoint,
                headers: usersApi.headers,
                params: { ...params, ...sortingQuery },
            })
                .then((response) => {
                    const data = response.data;
                    setUsers(data.data);
                    setPageInfo(data.meta);

                    setDataAvailable(() =>
                        data.data.length > 0 ? true : false
                    );
                    setSlStep(
                        data.meta.per_page * (data.meta.current_page - 1)
                    );
                })
                .catch((error) => {
                    setError(error.response.data.errors);
                    toast.error(t("Error!"), {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: getTheme() === "dark" ? "dark" : "light",
                    });
                });
        } catch (error) {
            toast.error(t("Error!"), {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: getTheme() === "dark" ? "dark" : "light",
            });
        }
    }

    useEffect(() => {
        var params = {};
        try {
            const usersApi = loggedRoutes.users;
            axios({
                method: usersApi.method,
                url: usersApi.endpoint,
                headers: usersApi.headers,
                params: params,
            })
                .then((response) => {
                    const data = response.data;
                    setUsers(data.data);
                    setPageInfo(data.meta);
                    setDataAvailable(() =>
                        data.data.length > 0 ? true : false
                    );
                    setSlStep(
                        data.meta.per_page * (data.meta.current_page - 1)
                    );
                })
                .catch((error) => {
                    setError(error.response.data.errors);
                    toast.error(t("Error!"), {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: getTheme() === "dark" ? "dark" : "light",
                    });
                });
        } catch (error) {
            toast.error(t("Error!"), {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: getTheme() === "dark" ? "dark" : "light",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleted]);

    const queryHandler = (searchThis) => {
        fetchUser(searchThis);
        setQParams(searchThis);
    };

    const selectAllHandler = () => {
        if (checkBoxRef.current.checked) {
            let tempArray = [];
            for (let i = 0; i < users.length; i++) {
                checkBoxArrayRef.current[users[i].id].checked = true;
                tempArray.push(users[i].id);
            }
            setCheckBoxInputArray(tempArray.length > 0 ? tempArray : []);
        } else {
            for (let i = 0; i < users.length; i++) {
                checkBoxArrayRef.current[users[i].id].checked = false;
                setCheckBoxInputArray([]);
            }
        }
    };

    const selectSingleCheckHandler = (id) => {
        let data = checkboxInputArray;
        if (checkBoxArrayRef.current[id].checked) {
            if (!checkboxInputArray.includes(id)) {
                data.push(id);
            }
        } else {
            if (checkboxInputArray.includes(id)) {
                const index = data.indexOf(id);
                if (index > -1) {
                    data.splice(index, 1);
                }
            }
        }
        if (data.length < users.length) {
            checkBoxRef.current.checked = false;
        }
        setCheckBoxInputArray(() => [...data]);
    };

    useEffect(() => {
        const sortFetchHandler = () => {
            fetchUser(qParams);
        };
        sortFetchHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortParams]);

    async function deleteHandler(user) {
        const willDelete = await swal({
            title: t("Are you sure?"),
            text: t("Are you sure that you want to delete this user?"),
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: t("No"),
                yes: t("Yes"),
            },
        });

        if (willDelete === "yes") {
            try {
                const deleteUser = apiRouteList.loggedRoutes.deleteUser;
                await axios({
                    method: deleteUser.method,
                    url: deleteUser.endpoint + user.id,
                    headers: deleteUser.headers,
                })
                    .then((response) => {
                        setDeleted(!deleted);

                        swal({
                            title: t("Deleted!"),
                            text: t("User successfully deleted!"),
                            icon: "success",
                            buttons: {
                                cancel: t("Ok"),
                            },
                        });
                    })
                    .catch((error) => {
                        swal({
                            title: t("Failed!"),
                            text: t("Something went wrong! Try again!"),
                            icon: "error",
                            buttons: {
                                cancel: t("Ok"),
                            },
                        });
                    });
            } catch (error) {}
        }
    }

    async function batchDeleteHandler() {
        const willDelete = await swal({
            title: t("Are you sure?"),
            text:
                t("Are you sure that you want to delete") +
                " " +
                checkboxInputArray.length +
                " users",
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: t("No"),
                yes: t("Yes"),
            },
        });

        if (willDelete === "yes") {
            checkBoxRef.current.checked = false;
            checkBoxArrayRef.current = [];
            setCheckBoxInputArray(() => []);

            try {
                const deleteUsersApi = apiRouteList.loggedRoutes.deleteManyUser;
                await axios({
                    method: deleteUsersApi.method,
                    url: deleteUsersApi.endpoint,
                    headers: deleteUsersApi.headers,
                    data: { users: checkboxInputArray },
                })
                    .then((response) => {
                        setDeleted(!deleted);

                        swal({
                            title:
                                t("Deleted!") +
                                " " +
                                checkboxInputArray.length +
                                " users",
                            text: t("Users successfully deleted!"),
                            icon: "success",
                            buttons: {
                                cancel: t("Ok"),
                            },
                        });
                    })
                    .catch((error) => {
                        swal({
                            title: t("Failed!"),
                            text: t("Something went wrong! Try again!"),
                            icon: "error",
                            buttons: {
                                cancel: t("Ok"),
                            },
                        });
                    });
            } catch (error) {}
        }
    }

    return (
        <>
            <Table
                tableInfo={{
                    title: t("Users"),
                    currentPageInfo: {
                        from: slStep + sl_base,
                        to: slStep + users.length,
                        total: users.length,
                    },
                    total: pageInfo.total,
                    checkboxInputArray: checkboxInputArray,
                }}
                tableOptions={{
                    search: true,
                    queryHandler: queryHandler,
                    filter: true,
                    title: true,
                    pageDropdown: true,
                    paginationBtnGroup: true,
                    batchDeleteHandler: batchDeleteHandler,
                }}
                links={pageInfo.links}
            >
                <TableRow header={true}>
                    <TableHeaderCol sort={false}>
                        <Checkbox
                            ref={checkBoxRef}
                            onChange={selectAllHandler}
                        />
                    </TableHeaderCol>
                    <TableHeaderCol sort={false} sticky={true}>
                        {t("Sl No.")}
                    </TableHeaderCol>
                    <TableHeaderCol
                        setSortParams={setSortParams}
                        sortParamName={"name"}
                    >
                        {t("Name")}
                    </TableHeaderCol>
                    <TableHeaderCol
                        setSortParams={setSortParams}
                        sortParamName={"email"}
                    >
                        {t("Email")}
                    </TableHeaderCol>
                    <TableHeaderCol
                        setSortParams={setSortParams}
                        sortParamName={"created_at"}
                    >
                        {t("Member Since")}
                    </TableHeaderCol>
                    <TableHeaderCol sort={false}>{t("Role")}</TableHeaderCol>
                    <TableHeaderCol sort={false}>
                        {t("Verified")}
                    </TableHeaderCol>
                    <TableHeaderCol sort={false}>{t("Action")}</TableHeaderCol>
                </TableRow>

                {!dataAvailable ? (
                    <TableDataNoRecords colSpan={8} />
                ) : (
                    users.map((item, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCol>
                                    <Checkbox
                                        defaultChecked={false}
                                        onChange={() =>
                                            selectSingleCheckHandler(item.id)
                                        }
                                        ref={(el) =>
                                            (checkBoxArrayRef.current[item.id] =
                                                el)
                                        }
                                    />
                                </TableCol>
                                <TableCol sticky={true}>
                                    # {slStep + sl_base++}
                                </TableCol>
                                <TableCol>
                                    <Avatar
                                        src={item.photo}
                                        size="35"
                                        round={true}
                                        name={item.name}
                                    />

                                    <span className="pl-1">{item.name}</span>
                                </TableCol>
                                <TableCol>{item.email}</TableCol>
                                <TableCol>
                                    {dateToYears(item.created_at)}
                                </TableCol>
                                <TableCol>{item.role}</TableCol>
                                <TableCol>
                                    <Badge
                                        variant={
                                            item.is_email_verified
                                                ? "success"
                                                : "danger"
                                        }
                                    >
                                        {item.is_email_verified
                                            ? t("Yes")
                                            : t("No")}
                                    </Badge>
                                </TableCol>
                                <TableCol>
                                    <ActionBtnGroupWrapper>
                                        {permissionCheck("user show") && (
                                            <Tooltip
                                                title={t("View")}
                                                position="top"
                                                trigger="mouseenter"
                                            >
                                                <Link
                                                    to={
                                                        reactRouteList
                                                            .loggedRoutes
                                                            .show_user.link +
                                                        "/" +
                                                        item.id
                                                    }
                                                    id={"view-button" + item.id}
                                                >
                                                    <Icon
                                                        icon={"ph:eye-light"}
                                                    />
                                                </Link>
                                            </Tooltip>
                                        )}

                                        {permissionCheck("user update") && (
                                            <Tooltip
                                                title={t("Edit")}
                                                position="top"
                                                trigger="mouseenter"
                                            >
                                                <Link
                                                    to={
                                                        reactRouteList
                                                            .loggedRoutes
                                                            .edit_user.link +
                                                        "/" +
                                                        item.id
                                                    }
                                                >
                                                    <Icon icon={"uil:edit"} />
                                                </Link>
                                            </Tooltip>
                                        )}

                                        {permissionCheck("user delete") && (
                                            <Tooltip
                                                title={t("Delete")}
                                                position="top"
                                                trigger="mouseenter"
                                            >
                                                <Link
                                                    onClick={() =>
                                                        deleteHandler({
                                                            id: item.id,
                                                            name: item.name,
                                                        })
                                                    }
                                                    id={
                                                        "delete-button" +
                                                        item.id
                                                    }
                                                >
                                                    <Icon
                                                        icon={
                                                            "fluent:delete-24-regular"
                                                        }
                                                    />
                                                </Link>
                                            </Tooltip>
                                        )}
                                    </ActionBtnGroupWrapper>
                                </TableCol>
                            </TableRow>
                        );
                    })
                )}
            </Table>
        </>
    );
}
