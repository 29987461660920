import { useContext } from "react";
import NoTokenLogout from "../../components/Auth/NoTokenLogout";
import { MenuContext } from "../../contexts/MenuContext";

export default function DashboardContentContainer({ children }) {
    useContext(MenuContext);
    return (
        <>
            <NoTokenLogout />
            <div>
                <div className=" bg-gray-100 dark:bg-slate-900">{children}</div>
            </div>
        </>
    );
}
