import { useTranslation } from "react-i18next";
import { getUserData } from "../../utils/Storage";

export default function EcommerceCardBG() {
    const [t] = useTranslation();
    return (
        <div className="dasboardCard bg-white rounded-md px-5 py-4 flex items-center justify-between bg-ecommerce-wid-bg bg-center bg-cover bg-no-repeat">
            <div className="w-56 ">
                <h3 className="font-Outfit font-normal text-white text-lg">
                    {t("Good evening")},
                </h3>
                <h3 className="font-Outfit font-medium text-white text-2xl pb-2">
                    {getUserData().name}
                </h3>
                <p className="font-outfit text-base text-white font-normal">
                    {t("Welcome to DashKit")}
                </p>
            </div>
        </div>
    );
}
