import Breadcrumb from "../../components/Breadcrumbs/Breadcrumb";

export default function ContentBodyHeader({ buttons, breadcrumb, info }) {
    return (
        <div className="block sm:flex items-center justify-between mb-6">
            <div className="block  md:flex items-center">
                <h5 className="text-textColor font-Outfit font-medium text-2xl mr-4 dark:text-white mb-1 sm:mb-0">
                    {info.title}
                </h5>
                {breadcrumb && <Breadcrumb list={breadcrumb} />}
            </div>
            <div className="border-t border-t-slate-300 mt-4 pt-5 text-center sm:border-none sm:pt-0 sm:mt-0 sm:text-end">
                {buttons}
            </div>
        </div>
    );
}
