import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import SocialAuth from "../../auth/SocialAuth";
import reactRouteList from "../../config/react-route-list-config";
import NoBorderButton from "../Buttons/NoBorderButton";

import DividerText from "../Texts/DividerText";
import FormTitleDescription from "../Texts/FormTitleDescription";
import FormFooter from "./FormFooter";
import LoginForm from "./LoginForm";
export default function Login(){
    const routes = reactRouteList.guestRoutes;
    const navigate = useNavigate();
    const [t] = useTranslation();
    return (<>
      <div className="w-full px-4 sms:px-0 sm:w-[450px]">
        <FormTitleDescription title={t('Sign in')} description={t('Login to your account to start using Dashcode')}/>
        <LoginForm/>
        <div className="mt-8">
          <DividerText>{t('Or continue with')}</DividerText>
          <SocialAuth/>
          <FormFooter>{t("Don't have an account?")} <NoBorderButton onClick={()=>navigate(routes.register)}>{t('Sign Up')}</NoBorderButton></FormFooter>
        </div>
      </div>
    </>) ;
}