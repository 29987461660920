import TableColMini from "../../../components/Tables/TableColMini";
import TableRow from "../../../components/Tables/TableRow";
import { useEffect, useState } from "react";
import { loggedRoutes } from "../../../config/apiRouteLists/logged-route-lists";
import axios from "axios";
import { dateToYears } from "../../../utils/Date";
import Avatar from "react-avatar";
import TableMini from "../../../components/Tables/TableMini";
import TableDataPreloader from "../../../components/Preloader/TableDataPreloader";
import { TableDataNoRecords } from "../../../components/Alerts/TableDataNoRecords";
import { t } from "i18next";

export default function UserTable() {
    const [processing, setProcessing] = useState(false);
    const [, setError] = useState(null);
    const [users, setUsers] = useState([]);
    const [pageInfo, setPageInfo] = useState({});
    const [slStep, setSlStep] = useState(10);
    let sl_base = 1;

    async function fetchUser(params = { per_page: 3 }) {
        setProcessing(true);
        try {
            const usersApi = loggedRoutes.users;
            await axios({
                method: usersApi.method,
                url: usersApi.endpoint,
                headers: usersApi.headers,
                params: params,
            })
                .then((response) => {
                    setProcessing(false);
                    const data = response.data;
                    setUsers(data.data);
                    setPageInfo(data.meta);
                    setSlStep(
                        data.meta.per_page * (data.meta.current_page - 1)
                    );
                })
                .catch((error) => {
                    setError(error.response.data.errors);
                    setProcessing(false);
                });
        } catch (error) {
            setProcessing(false);
        }
    }

    useEffect(() => {
        fetchUser();
    }, []);

    const queryHandler = (searchThis) => {
        fetchUser(searchThis);
    };

    return (
        <>
            <TableMini
                tableInfo={{
                    title: "Users",
                    currentPageInfo: {
                        from: slStep + sl_base,
                        to: slStep + users.length,
                    },
                    total: pageInfo.total,
                }}
                tableOptions={{
                    search: false,
                    title: false,
                    queryHandler: queryHandler,
                    filter: false,
                    pageDropdown: true,
                    paginationBtnGroup: true,
                }}
                links={pageInfo.links}
            >
                <TableRow header={true}>
                    <TableColMini header={true} sticky={true}>
                        {t("Sl No.")}
                    </TableColMini>
                    <TableColMini header={true}>{t("Name")}</TableColMini>
                    <TableColMini header={true}>{t("Email")}</TableColMini>
                    <TableColMini header={true}>
                        {t("Member Since")}
                    </TableColMini>
                </TableRow>
                {processing ? (
                    <TableRow>
                        <TableDataPreloader columnSpan={4} />
                    </TableRow>
                ) : users.length === 0 ? (
                    <TableDataNoRecords colSpan={4} />
                ) : (
                    users.map((item, index) => {
                        return (
                            <TableRow key={index}>
                                <TableColMini sticky={true}>
                                    # {slStep + sl_base++}
                                </TableColMini>
                                <TableColMini>
                                    <Avatar
                                        src={item.photo}
                                        size="35"
                                        round={true}
                                        name={item.name}
                                    />

                                    <span className="pl-1">{item.name}</span>
                                </TableColMini>
                                <TableColMini>{item.email}</TableColMini>
                                <TableColMini>
                                    {dateToYears(item.created_at)}
                                </TableColMini>
                            </TableRow>
                        );
                    })
                )}
            </TableMini>
        </>
    );
}
