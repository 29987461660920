import DashboardLayout from "../../../Layouts/DashboardLayout";
import ContentBodyWrapper from "../../../Layouts/Containers/ContentBodyWrapper";
import ContentBodyHeader from "../../../Layouts/Containers/ContentBodyHeader";
import TopCardGroupSection from "./TopCardGroupSection";
import ChartSection from "./ChartSection";
import TableAndChartSection from "./TableAndChartSection";
import { changeBrowserTabTitle } from "../../../utils/Browser";
export default function EcommerceDashboardPage() {
    changeBrowserTabTitle("Ecommerce Dashboard");
    return (
        <>
            <DashboardLayout>
                <ContentBodyWrapper showFooter={true}>
                    <div className="space-y-8">
                        <ContentBodyHeader
                            info={{
                                title: "DashKit",
                            }}
                        />
                        <TopCardGroupSection />
                        <ChartSection />
                        <TableAndChartSection />
                    </div>
                </ContentBodyWrapper>
            </DashboardLayout>
        </>
    );
}
