export default function BadgeMini({children, variant}){
    let classNames = "badge-mini";
    if(variant==="danger"){
        classNames = "badge-mini danger";
    }
    else if(variant==='due'){
        classNames = "badge-mini due";
    }
    else if(variant==='success'){
        classNames = "badge-mini success";
    }
    return (<>
        <span className={classNames}>{children}</span>
    </>);
}