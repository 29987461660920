export const radialBarChart = {
    series: [66],
    options: {
        fill: {
            type: "gradient",
            gradient: {
                shade: "dark",
                shadeIntensity: 0.15,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [0, 50, 65],
            },
        },
        stroke: {
            dashArray: 3,
        },
        labels: ["Total"],
        chart: {
            height: 350,
            type: "radialBar",
            offsetY: -10,
        },
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
            name: {
                fontSize: "20px",
                fontFamily: "OutFit",
                fontWeight: 400,
                color: "#707070",
                offsetY: 50,
            },
            value: {
                offsetY: 6,
                fontSize: "22px",
                fontFamily: "OutFit",
                color: undefined,
                formatter: function (val) {
                    return val + "%";
                },
            },
        },
    },

};
