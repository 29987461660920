import LogoAndTitle from "../Logo/LogoAndTitle";

export default function FormTitleDescription({ title, description, children }) {
    return (
        <div className="text-center">
            <div className="md:hidden pb-8">
                <LogoAndTitle />
            </div>
            <h3 className="font-Outfit font-medium text-2xl text-black pb-3">{title}</h3>
            <p className="font-Outfit font-light text-base text-textColor">{description}</p>
            {children}
        </div>
    );
}