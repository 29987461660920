import GuestRoutes from "../routes/GuestRoutes";
import LoggedRoutes from "../routes/LoggedRoutes";
import { useContext, useEffect, useState } from "react";
import {
    getCurrentLanguage,
    getTheme,
    getToken,
    isLogged,
    setCurrentTheme,
} from "../utils/Storage";
import { AuthContext } from "../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import axios from "axios";
import apiRouteList from "../config/api-route-list-config";
import { toast } from "react-toastify";
import { changeBrowserFavicon } from "../utils/Browser";
import { SettingsContext } from "../contexts/SettingsContext";
import { MenuContext } from "../contexts/MenuContext";
import reactRouteList from "../config/react-route-list-config";
import { useLocation, useNavigate } from "react-router-dom";

export default function Auth() {
    const loginCtx = useContext(AuthContext);
    const menuCtx = useContext(MenuContext);
    const settingsCtx = useContext(SettingsContext);
    const location = useLocation();
    const [login, setLogin] = useState(isLogged());
    const [, i18n] = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        i18n.changeLanguage(getCurrentLanguage().code);
        setCurrentTheme();

        try {
            const settingsApi = apiRouteList.guestRoutes.currentGeneraSettings;

            axios({
                method: settingsApi.method,
                url: settingsApi.endpoint,
                headers: settingsApi.headers,
            })
                .then((response) => {
                    const data = response.data.data;
                    localStorage.setItem(
                        "generalSettings",
                        JSON.stringify(data)
                    );
                    changeBrowserFavicon(data.favicon);
                    settingsCtx.setGeneralSettings(data);
                })
                .catch((error) => {
                    localStorage.setItem("generalSettings", JSON.stringify({}));
                    settingsCtx.setGeneralSettings({});
                });
        } catch (error) {
            localStorage.setItem("generalSettings", JSON.stringify({}));
            settingsCtx.setGeneralSettings({});
        }

        if (isLogged() && getToken()) {
            //geting backend api
            const userApi = apiRouteList.loggedRoutes.currentUser;
            axios({
                method: userApi.method,
                url: userApi.endpoint,
                headers: userApi.headers,
            })
                .then((response) => {
                    const userData = response.data;
                    localStorage.setItem(
                        "userData",
                        JSON.stringify(userData.data)
                    );
                    localStorage.setItem(
                        "is_pending_email",
                        JSON.stringify(userData.data.is_pending_email)
                    );
                    localStorage.setItem(
                        "is_pending_email",
                        JSON.stringify(userData.data.is_pending_email)
                    );
                    if (userData.data.is_email_verified) {
                        if (
                            location.pathname ===
                            reactRouteList.guestRoutes.emailVerify
                        ) {
                            navigate(reactRouteList.home);
                        }
                        setLogin(true);
                        localStorage.setItem("userIsVerified", true);
                    } else {
                        navigate(reactRouteList.guestRoutes.emailVerify);
                    }
                    menuCtx.setSidebarMenuItemsChanged();
                })
                .catch((error) => {
                    toast.error(
                        "Could not fetch user data, try reloading the page!",
                        {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: getTheme() === "dark" ? "dark" : "light",
                        }
                    );
                });
        } else {
            setLogin(false);
            navigate(reactRouteList.home);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginCtx.login_trigger, i18n]);

    return <>{login ? <LoggedRoutes /> : <GuestRoutes />}</>;
}
