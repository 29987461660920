import Register from "../../../components/Forms/Register";
import GeneralLayout from "../../../Layouts/GeneralLayout";
import { changeBrowserTabTitle } from "../../../utils/Browser";

export default function RegisterPage() {
    changeBrowserTabTitle("Register");
    return (
        <>
            <GeneralLayout>
                <Register />
            </GeneralLayout>
        </>
    );
}
