import { useTranslation } from "react-i18next";
import reactRouteList from "../../../config/react-route-list-config";
import ContentBodyHeader from "../../../Layouts/Containers/ContentBodyHeader";
import ContentBodyWrapper from "../../../Layouts/Containers/ContentBodyWrapper";
import DashboardLayout from "../../../Layouts/DashboardLayout";
import { changeBrowserTabTitle } from "../../../utils/Browser";
import DBBackupListTables from "./DBBackupListTables";

export default function DatabaseBackupPage() {
    changeBrowserTabTitle("Database Backup");
    const [t] = useTranslation();

    return (
        <>
            <DashboardLayout>
                <ContentBodyWrapper showFooter={true}>
                    <ContentBodyHeader
                        info={{
                            title: t("Database Backup"),
                        }}
                        breadcrumb={[
                            {
                                linkitem: t("Database Backup"),
                                link: reactRouteList.loggedRoutes
                                    .database_backup,
                            },
                        ]}
                    />
                    <DBBackupListTables />
                </ContentBodyWrapper>
            </DashboardLayout>
        </>
    );
}
