import DashboardLayout from "../../../../Layouts/DashboardLayout";
import ContentBodyWrapper from "../../../../Layouts/Containers/ContentBodyWrapper";
import ContentBodyHeader from "../../../../Layouts/Containers/ContentBodyHeader";
import ShowUser from "./ShowUser";
import reactRouteList from "../../../../config/react-route-list-config";
import { useTranslation } from "react-i18next";
import { changeBrowserTabTitle } from "../../../../utils/Browser";
import ButtonBlack from "../../../../components/Buttons/ButtonBlack";
import { useParams } from "react-router-dom";

export default function UserShowPage() {
    const [t] = useTranslation();
    let { id } = useParams();
    changeBrowserTabTitle("View User");
    const breadCrumblink =
        reactRouteList.loggedRoutes.show_user.link + "/" + id;
    const breadCrumbItem = id;

    const buttons = (
        <ButtonBlack
            to={reactRouteList.loggedRoutes.user_list}
            icon={"material-symbols:arrow-back"}
        >
            {t("Back")}
        </ButtonBlack>
    );

    return (
        <>
            <DashboardLayout>
                <ContentBodyWrapper showFooter={true}>
                    <ContentBodyHeader
                        info={{
                            title: t("Users"),
                        }}
                        breadcrumb={[
                            {
                                linkitem: t("Users"),
                                link: reactRouteList.loggedRoutes.user_list,
                            },
                            { linkitem: breadCrumbItem, link: breadCrumblink },
                        ]}
                        buttons={buttons}
                    />
                    <ShowUser />
                </ContentBodyWrapper>
            </DashboardLayout>
        </>
    );
}
