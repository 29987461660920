import {useEffect, useState} from 'react';

export default function useScreen() {
  const [screen, setScreen] = useState(getSize());

  useEffect(() => {
    function handler() {
      setScreen(getSize());
    }
    window.addEventListener('resize', handler);
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  return screen;
}

function getSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}
