export const donutChart = {
    series: [44, 55],
    options:{
        chart: {
            type: 'donut',
          },
          labels: ["Success", "Return"],
          dataLabels: {
            enabled: false,
          },
        
          colors: ["#0CE7FA", "#FA916B"],
          legend: {
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Outfit",
            fontWeight: 400,
          },
          plotOptions: {
            pie: {
              donut: {
                size: "65%",
              },
            },
          },
        
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
    }
  };