import { useState } from "react";
import apiRouteList from "../../config/api-route-list-config";
import SubmitButtonBlock from "../Buttons/SubmitButtonBlock";
import axios from "axios";
import BoxText from "../Texts/BoxText";
import { useTranslation } from "react-i18next";
import ValidationError from "../Alerts/ValidationError";
import { toast } from "react-toastify";
import { getTheme } from "../../utils/Storage";

export default function ForgotPasswordForm() {
    const [userData, setUserData] = useState({});
    const [error, setError] = useState({});
    const [processing, setProcessing] = useState(false);

    const [t] = useTranslation();

    function getInput(event, input) {
        setUserData((prevState) => ({
            ...prevState,
            [input]: event.target.value,
        }));
    }

    const forgetPasswordHandler = async (e) => {
        //preparing form data
        setProcessing(true);
        setError({});
        e.preventDefault();
        const forgetPassFormData = new FormData();
        forgetPassFormData.append("email", userData.email);

        //getting backend api
        const fpassApi = apiRouteList.guestRoutes.forgotPassword;

        try {
            await axios({
                method: fpassApi.method,
                url: fpassApi.endpoint,
                data: forgetPassFormData,
                headers: fpassApi.headers,
            })
                .then((response) => {
                    const data = response.data;
                    toast.success(data.message, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: getTheme() === "dark" ? "dark" : "light",
                    });
                    setProcessing(false);
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        setError(error.response.data.errors);
                    } else {
                        toast.error(error.response.data.message, {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: getTheme() === "dark" ? "dark" : "light",
                        });
                    }
                    setProcessing(false);
                });
        } catch (error) {
            setProcessing(false);
        }
    };

    return (
        <>
            <BoxText>
                {t("Enter your Email and instructions will be sent to you!")}
            </BoxText>
            <form
                onSubmit={(e) => forgetPasswordHandler(e)}
                className="mt-8 md:mt-12"
            >
                <div className="mt-3">
                    <label
                        htmlFor="email"
                        className="font-Outfit font-medium text-sm text-textColor mb-2 inline-block"
                    >
                        {t("Email")}
                    </label>
                    <input
                        onChange={(e) => getInput(e, "email")}
                        type="email"
                        name="email"
                        id="email"
                        className="w-full border border-gray-300 bg-[#FBFBFB] py-[10px] px-4 outline-none focus:outline-none focus:ring-0 focus:border-[#000000] shadow-none rounded-md font-Outfit text-base text-black"
                        placeholder={t("Enter your email")}
                        required
                        autoFocus
                    />
                    <ValidationError value={error.email} />
                </div>
                <SubmitButtonBlock processing={processing}>
                    {t("Send recovery email")}
                </SubmitButtonBlock>
            </form>
        </>
    );
}
