import { Icon } from "@iconify/react";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import axios from "axios";

import {
    LoginSocialGoogle,
    LoginSocialFacebook,
    LoginSocialLinkedin,
    LoginSocialGithub,
} from "reactjs-social-login";

import { AuthContext } from "../contexts/AuthContext";
import apiRouteList from "../config/api-route-list-config";
import reactRouteList from "../config/react-route-list-config";
import { getTheme, setLoggedVerified, storeToken } from "../utils/Storage";
import { toast } from "react-toastify";

export default function SocialAuth() {
    const navigate = useNavigate();
    const loginCtx = useContext(AuthContext);

    const LoginHandler = async (provider, token) => {
        //preparing form data
        const loginFormData = new FormData();
        loginFormData.append("provider", provider);
        loginFormData.append("access_token", token);

        //geting backend api
        const loginApi = apiRouteList.guestRoutes.loginOauth;

        try {
            await axios({
                method: loginApi.method,
                url: loginApi.endpoint,
                data: loginFormData,
                headers: loginApi.headers,
            })
                .then(function (response) {
                    const data = response.data;
                    storeToken(data.data.token, true);
                    if (data.data.is_email_verified === true) {
                        function loginSwitchHandler() {
                            loginCtx.setLogin_trigger();
                            navigate(reactRouteList.loggedRoutes.dashboard);
                            toast.success(data.message, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: getTheme() === "dark" ? "dark" : "light",
                            });
                        }

                        let myPromise = new Promise(function (
                            myResolve,
                            myReject
                        ) {
                            try {
                                setLoggedVerified(data.data);
                                myResolve();
                            } catch {
                                myReject();
                            }
                        });

                        myPromise.then(
                            function (value) {
                                loginSwitchHandler(value);
                            },
                            function (error) {
                                loginSwitchHandler(error);
                            }
                        );
                    } else {
                        navigate(reactRouteList.guestRoutes.emailVerify);
                    }
                })
                .catch(function (error) {
                    toast.error("Something went wrong!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: getTheme() === "dark" ? "dark" : "light",
                    });
                });
        } catch (error) {
            toast.error("Something went wrong!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: getTheme() === "dark" ? "dark" : "light",
            });
        }
    };

    return (
        <>
            <ul className="list-none text-center mt-7">

                <li className="inline-block">
                    <LoginSocialFacebook
                        isOnlyGetToken
                        appId={process.env.REACT_APP_FACEBOOK_APP_ID || ""}
                        onLoginStart={() => console.log("Started...")}
                        onResolve={({ provider, data }) => {
                            console.log("Login Success!", data);
                            LoginHandler(provider, data.accessToken);
                        }}
                        onReject={(err) => {
                            console.log(err);
                        }}
                    >
                        <span
                            className={`cursor-pointer inline-flex items-center justify-center font-medium text-xl w-10 h-10 rounded-full border mx-3 text-white bg-[#395599]`}
                        >
                            <Icon
                                icon={
                                    process.env.REACT_APP_FACEBOOK_ICONIFY_ICON
                                }
                            />
                        </span>
                    </LoginSocialFacebook>
                </li>

                <li className="inline-block">
                    <LoginSocialLinkedin
                        client_id={
                            process.env.REACT_APP_LINKEDIN_CLIENT_ID || ""
                        }
                        client_secret={
                            process.env.REACT_APP_LINKEDIN_CLIENT_SECRET || ""
                        }
                        redirect_uri={
                            process.env.REACT_APP_LINKEDIN_REDIRECT_URL || ""
                        }
                        scope="r_liteprofile r_emailaddress"
                        onLoginStart={() => console.log("Started...")}
                        onResolve={({ provider, data }) => {
                            console.log("Login Success!", data);
                            LoginHandler(provider, data.access_token);
                        }}
                        onReject={(err) => {
                            console.log(err);
                        }}
                    >
                        <span
                            className={`cursor-pointer inline-flex items-center justify-center font-medium text-xl w-10 h-10 rounded-full border mx-3 text-white bg-[#0A63BC]`}
                        >
                            <Icon
                                icon={
                                    process.env.REACT_APP_LINKEDIN_ICONIFY_ICON
                                }
                            />
                        </span>
                    </LoginSocialLinkedin>
                </li>

                <li className="inline-block">
                    <LoginSocialGoogle
                        client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID || ""}
                        scope={
                            "https://www.googleapis.com/auth/userinfo.profile"
                        }
                        onLoginStart={() =>
                            console.log("Google oauth Started...")
                        }
                        onResolve={({ provider, data }) => {
                            console.log("Login Success!", data);
                            LoginHandler("google", data.access_token);
                        }}
                        onReject={(err) => {
                            console.log(err);
                        }}
                    >
                        <span
                            className={`cursor-pointer inline-flex items-center justify-center font-medium text-xl w-10 h-10 rounded-full border mx-3 text-white bg-[#EA4335]`}
                        >
                            <Icon
                                icon={process.env.REACT_APP_GOOGLE_ICONIFY_ICON}
                            />
                        </span>
                    </LoginSocialGoogle>
                </li>

                <li className="inline-block">
                    <LoginSocialGithub
                        client_id={process.env.REACT_APP_GITHUB_CLIENT_ID || ""}
                        client_secret={
                            process.env.REACT_APP_GITHUB_CLIENT_SECRET || ""
                        }
                        redirect_uri={
                            process.env.REACT_APP_GITHUB_REDIRECT_URL || ""
                        }
                        onLoginStart={() => console.log("Started...")}
                        onResolve={({ provider, data }) => {
                            console.log(data);
                            LoginHandler(provider, data.access_token);
                        }}
                        onReject={(err) => {
                            console.log(err);
                        }}
                        scope={"user:email"}
                    >
                        <span
                            className={`cursor-pointer inline-flex items-center justify-center font-medium text-xl w-10 h-10 rounded-full border mx-3 text-white bg-[#000000]`}
                        >
                            <Icon
                                icon={process.env.REACT_APP_GITHUB_ICONIFY_ICON}
                            />
                        </span>
                    </LoginSocialGithub>
                </li>
            </ul>
        </>
    );
}
