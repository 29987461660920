import { useState } from "react";
import ProfileCard from "../../../components/Cards/ProfileCard";
import ProfileUpdateForm from "../../../components/Forms/ProfileUpdateForm";

export default function ProfileSection() {
    const [preview, setPreview] = useState();
    const [refresh, setRefresh] = useState(false);

    return (
        <>
            <div className="xl:grid xl:grid-cols-3 space-y-8 xl:space-x-8 xl:space-y-0 sm:mr-0 lg:mr-8">
            <ProfileCard preview={preview} refresh={refresh} />
                <div className="w-full col-span-2">
                    <div>
                        <ProfileUpdateForm
                            setPreview={setPreview}
                            refresh={refresh}
                            setRefresh={setRefresh}
                        />
                    </div>
                    {/* // eslint-disable-next-line react-hooks/exhaustive-deps */}
                </div>
            </div>
        </>
    );
}
