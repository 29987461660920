import { Icon } from "@iconify/react";
import { useContext } from "react";
import { MenuContext } from "../../contexts/MenuContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { getTheme } from "../../utils/Storage";

export default function SidebarLogo() {
    const menuCtx = useContext(MenuContext);
    const settingsCtx = useContext(SettingsContext);
    return (
        <>
            <div className="sidebar-logo-area h-20">
                <span className="text-xl font-semibold flex align-middle">
                    <img
                        alt="sidebar logo"
                        src={
                            getTheme() === "dark"
                                ? settingsCtx.generalSettings.dark_logo
                                : settingsCtx.generalSettings.logo
                        }
                        width={32}
                        height={32}
                    />
                    <span className="brand-text">DashKit</span>
                </span>
                <button
                    className="toggle-btn"
                    onClick={() => {
                        menuCtx.setSidebarMenu(!menuCtx.sidebarMenu);
                    }}
                >
                    <Icon
                        className="text-2xl mt-1 text-black dark:text-white"
                        icon={
                            menuCtx.sidebarMenu
                                ? "ic:baseline-gps-not-fixed"
                                : "lucide:locate-fixed"
                        }
                    ></Icon>
                </button>
            </div>
        </>
    );
}
