import { useTranslation } from "react-i18next";
import AnalyticCardBG from "../../../components/Cards/AnalyticCardBG";
import AnalyticCard from "../../../components/Cards/AnalytictCard";
import { aChart1 } from "../../../data/achartdata1";
import { aChart2 } from "../../../data/achartdata2";
import { aChart3 } from "../../../data/achartdata3";

export default function TopCardGroupSection() {
    const [t] = useTranslation();
    return (<>
          <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-7">
            <AnalyticCardBG/>
            <AnalyticCard chartdata={aChart1} title={t("Total revenue")} data={"$1494"}/>
            <AnalyticCard chartdata={aChart2} title={t("Products sold")} data={"944"}/>
            <AnalyticCard chartdata={aChart3} title={t("Growth")}        data={"+11%"}/>
          </div>
    </>);
}