import Spinner from "../Preloader/Spinner";

export default function SubmitButton({ children, processing }) {
    return (
        <button type="submit" className="defaultButton submitButton">
            <span className="flex items-center justify-center">
                <span className="flex items-center">
                    {processing && <Spinner bg={"dark"} />}
                    <span className="pl-1">{children}</span>
                </span>
            </span>
        </button>
    );
}
