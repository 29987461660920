import { useState } from "react";
import PaginationMini from "../Pagination/PaginationMini";
export default function TableMini({ children, tableInfo, tableOptions, links}) {
    const [search, setSearch ] = useState({});

    function paginateTo(current_page){
        let obj = search;
        obj.page = current_page;
        obj.per_page = 3;
        setSearch(obj);

        tableOptions.queryHandler(search);
    }

    return (
        <>
            <div className="bg-white dark:bg-slate-800 rounded-lg">

                    <div className="w-full overflow-hidden overflow-x-scroll lg:overflow-auto overflow-y-hidden">
                        <table className="w-full border-collapse text-center overflow-y-hidden">
                            <tbody>
                                {children}
                            </tbody>
                        </table>
                    </div>
                    <div className="gap-3 pt-8 px-8">
                        {tableOptions.paginationBtnGroup &&
                            <PaginationMini links={links} paginateTo={paginateTo} /> 
                        }
                    </div>
            </div>

        </>
    );
}