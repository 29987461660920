import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../contexts/MenuContext";
import { Icon } from "@iconify/react";
import { languageList, topBarDropDownOptions } from "../../data/lists";
import {
    clearSubmenu,
    forgetLogged,
    getCurrentLanguage,
    getTheme,
    getUserData,
    setCurrentLanguage,
} from "../../utils/Storage";
import { useNavigate, Link, useLocation } from "react-router-dom";
import reactRouteList from "../../config/react-route-list-config";
import { AuthContext } from "../../contexts/AuthContext";
import useScreen from "../../hooks/useScreen";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import apiRouteList from "../../config/api-route-list-config";
import axios from "axios";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { useDetectClickOutside } from "react-detect-click-outside";
import { SettingsContext } from "../../contexts/SettingsContext";
export default function TopBar() {
    const location = useLocation();
    const screen = useScreen();
    const [update, setUpdate] = useState(true);
    const menuCtx = useContext(MenuContext);
    const loginCtx = useContext(AuthContext);
    const navigate = useNavigate();
    const generalSettingsCtx = useContext(SettingsContext);
    const refOutsideClick1 = useDetectClickOutside({
        onTriggered: () => {
            menuCtx.setListViewLangFalse();
        },
    });

    const refOutsideClick2 = useDetectClickOutside({
        onTriggered: () => {
            menuCtx.setListViewDropDownFalse();
        },
    });

    const [t, i18n] = useTranslation();

    useEffect(() => {}, [update]);

    const setLanguage = (id) => {
        setCurrentLanguage(id);
        setUpdate(!update);
    };

    async function logoutHandler() {
        const willLogout = await swal({
            title: t("Are you sure?"),
            text: t("Are you sure that you want to logout?"),
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: t("No"),
                yes: t("Yes"),
            },
        });

        if (willLogout === "yes") {
            //geting backend api
            const logoutApi = apiRouteList.loggedRoutes.logout;

            try {
                await axios({
                    method: logoutApi.method,
                    url: logoutApi.endpoint,
                    headers: logoutApi.headers,
                })
                    .then(function (response) {
                        function logoutSwitchHandler() {
                            loginCtx.setLogin_trigger();
                            navigate(reactRouteList.guestRoutes.login);
                            toast.success(response.data.message, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: getTheme() === "dark" ? "dark" : "light",
                            });
                        }

                        let myPromise = new Promise(function (
                            myResolve,
                            myReject
                        ) {
                            try {
                                forgetLogged();
                                myResolve();
                            } catch {
                                myReject();
                            }
                        });

                        myPromise.then(
                            function (value) {
                                logoutSwitchHandler(value);
                            },
                            function (error) {
                                logoutSwitchHandler(error);
                            }
                        );
                    })
                    .catch(function (error) {
                        if (error.response.status === 401) {
                            forgetLogged();
                            loginCtx.setLogin_trigger();
                            navigate(reactRouteList.guestRoutes.login);
                            toast.success("Logged out!", {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: getTheme() === "dark" ? "dark" : "light",
                            });
                        } else {
                            toast.error("Something is not right. Try again.", {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: getTheme() === "dark" ? "dark" : "light",
                            });
                        }
                    });
            } catch (error) {
                toast.error("Something is not right. Try again.", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: getTheme() === "dark" ? "dark" : "light",
                });
            }
        }
    }

    return (
        <section className="py-[21px] h-20 bg-white dark:bg-slate-800 dark:border-b-slate-900 border-b border-b-gray-200 transition-all duration-300">
            <nav className="relative flex items-center md:justify-end">
                <div className="flex items-center w-full md:w-auto md:justify-end transition-all duration-300">
                    <div className="flex w-full justify-between items-center px-4 md:hidden">
                        <button
                            className="flex"
                            onClick={() => menuCtx.setSidebarMenu()}
                        >
                            {
                                <Icon
                                    className="text-xl mt-2 dark:text-white "
                                    icon={
                                        menuCtx.sidebarMenu
                                            ? "ri:menu-line"
                                            : "bi:x-lg"
                                    }
                                />
                            }
                        </button>
                        <div className="w-2/4 flex items-center justify-center">
                            <img
                                width="32"
                                height="32"
                                alt="logo"
                                src={
                                    getTheme() === "dark"
                                        ? generalSettingsCtx.generalSettings
                                              .dark_logo
                                        : generalSettingsCtx.generalSettings
                                              .logo
                                }
                            />
                            <span className="font-medium text-xl font-Outfit text-black dark:text-white ml-3">
                                DashKit
                            </span>
                        </div>
                        <button
                            className=""
                            onClick={() => menuCtx.setMobileTopBar()}
                        >
                            <Icon
                                className="text-xl mt-2 dark:text-white"
                                icon={
                                    menuCtx.mobileTopbar
                                        ? "mingcute:dot-grid-line"
                                        : "bi:x-lg"
                                }
                            ></Icon>
                        </button>
                    </div>
                    <div
                        className={
                            (screen.innerWidth < 767
                                ? "dark:bg-slate-800 bg-white "
                                : "") +
                            "justify-center md:!flex items-center border-t border-b border-b-slate-50 dark:border-b-slate-900 dark:border-t-slate-900 md:border-0 py-4 px-3 sm:px-4 absolute w-full transition-all duration-300 md:bg-transparent md:py-0 md:px-6 md:relative md:top-0 z-40 " +
                            (menuCtx.mobileTopbar
                                ? "top-0 hidden"
                                : "top-[58px] flex")
                        }
                    >
                        <div
                            ref={refOutsideClick1}
                            className="dropdown-select relative"
                        >
                            <button
                                className="flex px-4 md:px-5 pt-1"
                                onClick={() => menuCtx.setListViewLang()}
                            >
                                <Icon
                                    className="topbar-icon mr-1"
                                    icon={getCurrentLanguage().icon}
                                ></Icon>
                                <span className="topbar-icon dropdown-option pt-[1px]">
                                    {getCurrentLanguage().title}
                                </span>
                            </button>
                            <ul
                                className={
                                    "dropdown-list w-35 " +
                                    (menuCtx.listViewLang
                                        ? "z-20 opacity-100 top-[56px] left-1 inline-block "
                                        : "opacity-0 -z-20 top-5 hidden")
                                }
                            >
                                <>
                                    {languageList.map((item, index) => {
                                        return (
                                            <li key={index}>
                                                <span
                                                    onClick={() => {
                                                        setLanguage(item.id);
                                                        i18n.changeLanguage(
                                                            getCurrentLanguage()
                                                                .code
                                                        );
                                                        window.location.reload();
                                                    }}
                                                    className={
                                                        "cursor-pointer country-list " +
                                                        (getCurrentLanguage()
                                                            .id === item.id
                                                            ? "active"
                                                            : "")
                                                    }
                                                >
                                                    <Icon
                                                        className="topbar-icon inline-block text-lg mt-1 mr-2"
                                                        icon={item.icon}
                                                    ></Icon>
                                                    <span className="topbar-icon dropdown-option pt-[1px]">
                                                        {item.title}
                                                    </span>
                                                </span>
                                            </li>
                                        );
                                    })}
                                </>
                            </ul>
                        </div>

                        <ul className="flex items-center space-x-4 mr-5 md:space-x-6 md:mr-6">
                            <li>
                                <button
                                    className="text-textColor pt-[5px] dark:text-white cursor-pointer"
                                    onClick={() => {
                                        menuCtx.setDark();
                                    }}
                                >
                                    <Icon
                                        className="topbar-icon"
                                        icon={
                                            menuCtx.dark
                                                ? "clarity:moon-solid"
                                                : "carbon:light-filled"
                                        }
                                    ></Icon>
                                </button>
                            </li>
                            <li>
                                <a
                                    className="text-textColor dark:text-white"
                                    href="/"
                                >
                                    <Icon
                                        className="topbar-icon"
                                        icon="carbon:notification"
                                    ></Icon>
                                </a>
                            </li>
                        </ul>
                        <div
                            ref={refOutsideClick2}
                            className="dropdown-select relative"
                        >
                            <button
                                className="flex items-center"
                                onClick={() => menuCtx.setListViewDropDown()}
                            >
                                <div className="mr-0 hidden md:inline-block md:mr-3">
                                    <p className="text-xs md:text-sm text-textColor dark:text-white font-Outfit font-medium">
                                        {getUserData().name}
                                    </p>
                                    <p className="text-xs text-textColor dark:text-white font-Outfit font-light">
                                        {getUserData().role}
                                    </p>
                                </div>
                                <div className="mr-2">
                                    <Avatar
                                        src={getUserData().photo}
                                        size="40"
                                        round={true}
                                        className="object-cover object-right"
                                        name={getUserData().name}
                                    />
                                </div>
                                <span>
                                    <Icon
                                        icon="ep:arrow-down-bold"
                                        className={
                                            "text-sm transition-all duration-500 inline-block dark:text-white " +
                                            (menuCtx.listViewDropDown
                                                ? "rotate-180"
                                                : "rotate-0")
                                        }
                                    ></Icon>
                                </span>
                            </button>
                            <ul
                                className={
                                    "dropdown-list w-40 " +
                                    (menuCtx.listViewDropDown
                                        ? "z-20 opacity-100 top-[60px] inline-block "
                                        : "hidden opacity-0 -z-20 top-5")
                                }
                            >
                                {topBarDropDownOptions.map((item, index) => {
                                    var active_item = "";
                                    if (location.pathname === item.link) {
                                        active_item = "active";
                                    }

                                    return (
                                        <li key={index}>
                                            <Link
                                                to={item.link}
                                                className={
                                                    "country-list pb-1 " +
                                                    active_item
                                                }
                                                onClick={() => {
                                                    clearSubmenu();
                                                    menuCtx.setSidebarActiveSubMenu(
                                                        {}
                                                    );
                                                    menuCtx.setSidebarMenuParam(
                                                        true
                                                    );
                                                }}
                                            >
                                                <Icon
                                                    className="topbar-icon text-lg mt-1 text-textColor dark:text-white"
                                                    icon={item.icon}
                                                ></Icon>
                                                <span className="ml-1 topbar-icon dropdown-option">
                                                    {t(item.title + "")}
                                                </span>
                                            </Link>
                                        </li>
                                    );
                                })}
                                <li onClick={() => logoutHandler()}>
                                    <span
                                        className={
                                            "country-list cursor-pointer "
                                        }
                                    >
                                        <Icon
                                            className="topbar-icon text-lg mt-1 text-textColor dark:text-white"
                                            icon="carbon:logout"
                                        ></Icon>
                                        <span className="ml-1 topbar-icon dropdown-option">
                                            {t("Log out")}
                                        </span>
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </section>
    );
}
