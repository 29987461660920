import DashboardLayout from "../../../Layouts/DashboardLayout";
import ContentBodyWrapper from "../../../Layouts/Containers/ContentBodyWrapper";
import ProfileSection from "./ProfileSection";
import { changeBrowserTabTitle } from "../../../utils/Browser";
export default function ProfilePage() {
    changeBrowserTabTitle("Profile");
    return (
        <>
            <DashboardLayout>
                <ContentBodyWrapper showFooter={true}>
                    <ProfileSection />
                </ContentBodyWrapper>
            </DashboardLayout>
        </>
    );
}
