export const barChartOne = {
    series: [
        {
            name: "Revenue",
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
        },
        {
            name: "Net Profit",
            data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
        },
        {
            name: "Cash Flow",
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
        },
    ],

    options: {
        chart: {
            type: "bar",
            height: 350,
            width: "100%",
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "45%",
                endingShape: "rounded",
            },
        },
        legend: {
            show: true,
            position: "top",
            horizontalAlign: "right",
            fontSize: "13px",
            fontFamily: "Outfit",
            offsetY: -30,
            markers: {
                width: 12,
                height: 12,
                radius: 12,
            },
            itemMargin: {
                horizontal: 8,
                vertical: 0,
            },
            onItemClick: {
                toggleDataSeries: true,
            },
            onItemHover: {
                highlightDataSeries: true,
            },
        },
        title: {
            text: "Revenue Report",
            align: "left",
            offsetX: 0,
            offsetY: 13,
            floating: false,
            style: {
                fontSize: "20px",
                fontWeight: "medium",
                fontFamily: "Outfit",
                color: "##111112",
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            show: true,
            width: 2,
            colors: ["transparent"],
        },
        yaxis: {
            labels: {
                style: {
                    fontFamily: "Outfit",
                },
            },
        },
        xaxis: {
            categories: [
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
            ],
            labels: {
                style: {
                    fontFamily: "Outfit",
                },
            },
        },
        fill: {
            opacity: 1,
        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return "$ " + val + " thousands";
                },
            },
        },
        colors: ["#4669FA", "#0CE7FA", "#FA916B"],
        grid: {
            show: true,
            borderColor: "#E2E8F0",
            strokeDashArray: 10,
            position: "back",
        },
        responsive: [
            {
                breakpoint: 600,
                options: {
                    legend: {
                        position: "bottom",
                        offsetY: 0,
                        horizontalAlign: "center",
                    },
                    options: {
                        bar: {
                            columnWidth: "80%",
                        },
                    },
                },
            },
        ],
    },
};
