import DashboardLayout from "../../../../Layouts/DashboardLayout";
import ContentBodyWrapper from "../../../../Layouts/Containers/ContentBodyWrapper";
import ContentBodyHeader from "../../../../Layouts/Containers/ContentBodyHeader";
import reactRouteList from "../../../../config/react-route-list-config";
import { useTranslation } from "react-i18next";
import { changeBrowserTabTitle } from "../../../../utils/Browser";
import ButtonBlack from "../../../../components/Buttons/ButtonBlack";
import RolePermissionContexttProvider from "../../../../contexts/RolePermissionContext";
import CreateRoleForm from "../../../../components/Forms/CreateRoleForm";
export default function RoleCreatePage() {
    const [t] = useTranslation();
    changeBrowserTabTitle("Create Role");

    const buttons = (
        <ButtonBlack
            to={reactRouteList.loggedRoutes.role_list}
            icon={"material-symbols:arrow-back"}
        >
            {t("Back")}
        </ButtonBlack>
    );

    return (
        <>
            <DashboardLayout>
                <ContentBodyWrapper showFooter={true}>
                    <ContentBodyHeader
                        info={{
                            title: t("Create Role"),
                        }}
                        breadcrumb={[
                            {
                                linkitem: t("Roles"),
                                link: reactRouteList.loggedRoutes.role_list,
                            },
                            {
                                linkitem: t("Create"),
                                link: reactRouteList.loggedRoutes.create_roles,
                            },
                        ]}
                        buttons={buttons}
                    />
                    <RolePermissionContexttProvider>
                        <CreateRoleForm />
                    </RolePermissionContexttProvider>
                </ContentBodyWrapper>
            </DashboardLayout>
        </>
    );
}
