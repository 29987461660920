import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import Spinner from "../Preloader/Spinner";

export default function ButtonBlack({
    children,
    icon,
    to,
    onClick,
    processing = false,
}) {
    return (
        <Link
            onClick={onClick}
            to={to}
            className="defaultButton cursor-pointer"
        >
            <span className="flex items-center">
                <span className="flex items-center">
                    {processing && <Spinner bg={"dark"} />}
                    <Icon icon={icon} className="text-lg mr-1"></Icon>
                    <span className="pl-1">{children}</span>
                </span>
            </span>
        </Link>
    );
}
