import ForgotPassword from "../../../components/Forms/ForgotPassword";
import GeneralLayout from "../../../Layouts/GeneralLayout";
import { changeBrowserTabTitle } from "../../../utils/Browser";

export default function ForgotPasswordPage() {
    changeBrowserTabTitle("Forgot Password");
    return (
        <>
            <GeneralLayout>
                <ForgotPassword />
            </GeneralLayout>
        </>
    );
}
