import { getUserData } from "../../utils/Storage";
import Avatar from "react-avatar";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
export default function ProfileCard({ preview, refresh }) {
    const [t] = useTranslation();
    const [fullAddress, setFullAddress] = useState("N/A");
    const [userData, setUserData] = useState({});
    useEffect(() => {
        let userDataTemp = getUserData();
        userDataTemp = {
            city: userDataTemp.city === "null" ? null : userDataTemp.city,
            country:
                userDataTemp.country === "null" ? null : userDataTemp.country,
            post_code:
                userDataTemp.post_code === "null"
                    ? null
                    : userDataTemp.post_code,
            phone: userDataTemp.phone === "null" ? null : userDataTemp.phone,
            email: userDataTemp.email === "null" ? null : userDataTemp.email,
        };

        setUserData(userDataTemp);

        try {
            if (
                userDataTemp.city === null &&
                userDataTemp.country === null &&
                userDataTemp.post_code === null
            ) {
                setFullAddress("N/A");
            } else {
                const address = [];
                if (userDataTemp.city) {
                    address.push(userDataTemp.city);
                }
                if (userDataTemp.post_code) {
                    address.push(userDataTemp.post_code);
                }
                if (userDataTemp.country) {
                    address.push(userDataTemp.country);
                }

                let str = "";

                for (let i = 0; i < address.length; i++) {
                    str += address[i] + ", ";
                }
                setFullAddress(str.substring(0, str.length - 2));
            }
        } catch {
            setFullAddress("N/A");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    return (
        <div className="">
            <div className="bg-white dark:bg-slate-800 px-5 py-3 pt-1 lg:py-6 rounded-md">
                <div className="pt-6 pb-7 text-center">
                    <h1 className="font-Outfit text-2xl font-semibold text-black pb-2 dark:text-white">
                        {getUserData().name}
                    </h1>
                    <h5 className="font-Outfit text-sm font-normal text-textColor dark:text-white">
                        {getUserData().role}
                    </h5>
                </div>
                <div className="flex justify-center">
                    {preview ? (
                        <Avatar
                            src={preview}
                            name={getUserData().name}
                            size={120}
                            round={true}
                        />
                    ) : (
                        <Avatar
                            src={getUserData().photo}
                            name={getUserData().name}
                            size={120}
                            round={true}
                        />
                    )}
                </div>
                <div className="font-Outfit text-sm font-normal text-textColor dark:text-white py-5 md:py-7 space-y-4">
                    <h3 className="pt-3 font-Outfit font-medium text-xl text-black dark:text-white text-center relative ">
                        {t("Contact")}
                    </h3>
                    <ul className="space-y-3 max-w-sm mx-auto">
                        <li className="relative font-medium text-sm lg:text-base pl-24">
                            <span className="profileContactList">
                                {t("Email")}:
                            </span>
                            <span className="break-words">
                                {userData.email
                                    ? userData.email
                                    : t("N/A")}
                            </span>
                        </li>

                        <li className="relative font-medium text-sm lg:text-base pl-24 block">
                            <span className="profileContactList">
                                {t("Phone")}:
                            </span>{" "}
                            <span className="break-words">
                                {userData.phone?
                                    userData.phone: t("N/A")}
                            </span>
                        </li>

                        <li className="relative font-medium text-sm lg:text-base pl-24 block">
                            <span className="profileContactList">
                                {t("Address")}:
                            </span>{" "}
                            <span className="break-words">{fullAddress}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}
