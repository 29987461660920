import Cookies from "js-cookie";
import { languageList } from "../data/lists";
const access_cookie_name = "access_token";

//login and verification related
export const isLogged = () => {
    return localStorage.getItem("userIsLoggedIn") === "true";
};

export const isVerified = () => {
    return localStorage.getItem("userIsVerified") === "true";
};

export const setLoggedVerified = (data) => {
    localStorage.setItem("userIsLoggedIn", true);
    localStorage.setItem("userIsVerified", true);

    if (localStorage.setItem("lang") === null) {
        localStorage.setItem("lang", languageList[0].id);
    }

    localStorage.setItem("userData", JSON.stringify(data));
    localStorage.setItem(
        "is_pending_email",
        JSON.stringify(data.is_pending_email)
    );
};

export const setLogged = (data) => {
    localStorage.setItem("userIsLoggedIn", true);
    localStorage.setItem("userIsVerified", false);

    if (localStorage.setItem("lang") === null) {
        localStorage.setItem("lang", languageList[0].id);
    }

    localStorage.setItem("userData", JSON.stringify(data));
    localStorage.setItem(
        "is_pending_email",
        JSON.stringify(data.is_pending_email)
    );
};

export const forgetLogged = () => {
    Cookies.remove(access_cookie_name);

    localStorage.removeItem("userIsLoggedIn");
    localStorage.removeItem("userIsVerified");
};

//Token related
export const storeToken = (token, willExpire) => {
    if (willExpire) {
        Cookies.set(access_cookie_name, token, {
            expires: parseInt(process.env.REACT_APP_KEEP_LOGGED_IN_DAYS),
        });
        return;
    }
    Cookies.set(access_cookie_name, token);
    return;
};

export const getToken = (token) => {
    if (Cookies.get(access_cookie_name) === null) {
        forgetLogged();
        return null;
    }
    return Cookies.get(access_cookie_name);
};

//user data related
export const getUserData = () => {
    if (JSON.parse(localStorage.getItem("userData")) === null) {
        return {
            name: "No Name",
            email: "No email",
            role: "No Role",
        };
    }
    let data = JSON.parse(localStorage.getItem("userData"));
    return data;
};

//language
export const setCurrentLanguage = (id) => {
    localStorage.setItem("lang", id);
    return;
};

export const getCurrentLanguage = () => {
    var res = null;
    if (localStorage.getItem("lang") !== null) {
        let id = parseInt(localStorage.getItem("lang"));
        res = languageList.find((item) => item.id === id);
    } else {
        res = {
            id: 1,
            title: "EN",
            code: "en",
            icon: "emojione:flag-for-united-states",
        };
    }
    return res;
};

//theme
export const setTheme = (isDark) => {
    if (isDark) {
        localStorage.setItem("theme", "dark");
        document.documentElement.classList.remove("light");
        document.documentElement.classList.add("dark");
        return;
    }
    document.documentElement.classList.remove("dark");
    document.documentElement.classList.add("light");
    localStorage.setItem("theme", "");
    return;
};

export const getTheme = () => {
    let res = localStorage.getItem("theme");
    return res;
};

export function setCurrentTheme() {
    if (getTheme() === "dark") {
        document.documentElement.classList.remove("light");
        document.documentElement.classList.add("dark");
    } else {
        document.documentElement.classList.remove("dark");
        document.documentElement.classList.add("light");
    }
}

export const getSubmenu = () => {
    let res = localStorage.getItem("submenu_num");
    return res === null ? null : parseInt(res);
};

export const getSubmenuLink = () => {
    let res = localStorage.getItem("submenu_item_num");
    return res === null ? null : parseInt(res);
};

export const clearSubmenu = () => {
    localStorage.removeItem("submenu_item_num");
    localStorage.removeItem("submenu_num");
    return;
};
