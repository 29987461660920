import { useTranslation } from "react-i18next";

export default function MainContentFooter() {
    const [t] = useTranslation();
    return (
        <>
            <div className="bg-[#FAFBFC] dark:bg-slate-800 py-4 px-8 rounded-md font-Outfit font-normal text-slate-700 mt-8">
                <div className="grid md:grid-cols-2 grid-cols-1 md:gap-5">
                    <div className="text-center text-textColor dark:text-white md:text-start">
                        {new Date().getFullYear()} © <strong>DashKit</strong>
                    </div>
                    <div className="md:text-right text-textColor dark:text-white text-center">
                        {t("Design & Developed by")+" "}
                        <a href="/">
                            <strong>Codeshaperbd</strong>
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}
