import Table from "../../../../components/Tables/Table";
import TableCol from "../../../../components/Tables/TableCol";
import TableRow from "../../../../components/Tables/TableRow";
import ActionBtnGroupWrapper from "../../../../Layouts/Containers/ActionBtnGroupWrapper";
import { useEffect, useState } from "react";
import { loggedRoutes } from "../../../../config/apiRouteLists/logged-route-lists";
import axios from "axios";
import { Icon } from "@iconify/react";
import reactRouteList from "../../../../config/react-route-list-config";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import apiRouteList from "../../../../config/api-route-list-config";
import { permissionCheck } from "../../../../utils/permission";
import { useTranslation } from "react-i18next";
import { formatedDate } from "../../../../utils/Date";
import { processSortParams } from "../../../../utils/String";
import { TableDataNoRecords } from "../../../../components/Alerts/TableDataNoRecords";
import { Tooltip } from "react-tippy";
import TableHeaderCol from "../../../../components/Tables/TableHeaderCol";
import { toast } from "react-toastify";
import { getTheme } from "../../../../utils/Storage";
export default function RoleTable() {
    const [, setError] = useState(null);
    const [roles, setRoles] = useState([]);
    const [pageInfo, setPageInfo] = useState({});
    const [qParams, setQParams] = useState({});
    const [slStep, setSlStep] = useState(10);
    let sl_base = 1;
    const [t] = useTranslation();
    const [deleted, setDeleted] = useState(false);
    const [sortParams, setRoleSortParams] = useState({});
    const [dataAvailable, setDataAvailable] = useState(true);

    async function fetchRoles(params = {}) {
        const sortingQuery = processSortParams(sortParams);

        try {
            const rolesApi = loggedRoutes.roles;
            await axios({
                method: rolesApi.method,
                url: rolesApi.endpoint,
                headers: rolesApi.headers,
                params: { ...params, ...sortingQuery },
            })
                .then((response) => {
                    const data = response.data;
                    setRoles(data.data);
                    setPageInfo(data.meta);
                    setDataAvailable(() =>
                        data.data.length > 0 ? true : false
                    );
                    setSlStep(
                        data.meta.per_page * (data.meta.current_page - 1)
                    );
                })
                .catch((error) => {
                    setError(error.response.data.errors);
                    toast.error("Error!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: getTheme() === "dark" ? "dark" : "light",
                    });
                });
        } catch (error) {
            toast.error("Error!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: getTheme() === "dark" ? "dark" : "light",
            });
        }
    }

    useEffect(() => {
        let params = {};
        try {
            const rolesApi = loggedRoutes.roles;
            axios({
                method: rolesApi.method,
                url: rolesApi.endpoint,
                headers: rolesApi.headers,
                params: params,
            })
                .then((response) => {
                    const data = response.data;
                    setRoles(data.data);
                    setPageInfo(data.meta);
                    setDataAvailable(() =>
                        data.data.length > 0 ? true : false
                    );
                    setSlStep(
                        data.meta.per_page * (data.meta.current_page - 1)
                    );
                })
                .catch((error) => {
                    setError(error.response.data.errors);
                    toast.error("Error!", {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: getTheme() === "dark" ? "dark" : "light",
                    });
                });
        } catch (error) {
            toast.error("Error!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: getTheme() === "dark" ? "dark" : "light",
            });
        }
    }, [deleted]);

    const queryHandler = (searchThis) => {
        fetchRoles(searchThis);
        setQParams(searchThis);
    };

    useEffect(() => {
        const sortFetchHandler = () => {
            fetchRoles(qParams);
        };
        sortFetchHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortParams]);

    async function deleteHandler(role) {
        const willDelete = await swal({
            title: t("Are you sure?"),
            text: t("Are you sure that you want to delete this role?"),
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: t("No"),
                yes: t("Yes"),
            },
        });

        if (willDelete === "yes") {
            try {
                const deleteRole = apiRouteList.loggedRoutes.deleteRole;

                await axios({
                    method: deleteRole.method,
                    url: deleteRole.endpoint + role.id,
                    headers: deleteRole.headers,
                })
                    .then((response) => {
                        setDeleted(!deleted);
                        swal(
                            t("Deleted!"),
                            t("Role has been deleted!"),
                            "success"
                        );
                    })
                    .catch((error) => {
                        swal(
                            t("Failed!"),
                            t("Something went wrong! Try again!"),
                            "error"
                        );
                    });
            } catch (error) {
                toast.error("Error!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: getTheme() === "dark" ? "dark" : "light",
                });
            }
        }
    }

    return (
        <>
            <Table
                tableInfo={{
                    title: t("Roles"),
                    currentPageInfo: {
                        from: slStep + sl_base,
                        to: slStep + roles.length,
                    },
                    total: pageInfo.total,
                    checkboxInputArray: [],
                }}
                tableOptions={{
                    search: true,
                    queryHandler: queryHandler,
                    filter: true,
                    title: true,
                    pageDropdown: true,
                    paginationBtnGroup: true,
                }}
                links={pageInfo.links}
            >
                <TableRow header={true}>
                    <TableHeaderCol sort={false} sticky={true}>
                        {t("Sl No.")}
                    </TableHeaderCol>

                    <TableHeaderCol
                        sort={true}
                        setSortParams={setRoleSortParams}
                        sortParamName={"name"}
                    >
                        {t("Name")}
                    </TableHeaderCol>

                    <TableHeaderCol
                        sort={true}
                        setSortParams={setRoleSortParams}
                        sortParamName={"created_at"}
                    >
                        {t("Created At")}
                    </TableHeaderCol>

                    <TableHeaderCol sort={false}>{t("Action")}</TableHeaderCol>
                </TableRow>
                {!dataAvailable ? (
                    <TableDataNoRecords colSpan={5} />
                ) : (
                    roles.map((item, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCol sticky={true}>
                                    # {slStep + sl_base++}
                                </TableCol>
                                <TableCol>{item.name}</TableCol>
                                <TableCol>
                                    {formatedDate(item.created_at)}
                                </TableCol>
                                <TableCol>
                                    <ActionBtnGroupWrapper>
                                        {permissionCheck("role show") && (
                                            <Tooltip
                                                title={t("View")}
                                                position="top"
                                                trigger="mouseenter"
                                            >
                                                <Link
                                                    to={
                                                        reactRouteList
                                                            .loggedRoutes
                                                            .show_role.link +
                                                        "/" +
                                                        item.id
                                                    }
                                                    id={"view-button" + item.id}
                                                >
                                                    <Icon
                                                        icon={"ph:eye-light"}
                                                    />
                                                </Link>
                                            </Tooltip>
                                        )}

                                        {permissionCheck("role update") && (
                                            <Tooltip
                                                title={t("Edit")}
                                                position="top"
                                                trigger="mouseenter"
                                            >
                                                <Link
                                                    to={
                                                        reactRouteList
                                                            .loggedRoutes
                                                            .edit_role.link +
                                                        "/" +
                                                        item.id
                                                    }
                                                    id={"edit-button" + item.id}
                                                >
                                                    <Icon icon={"uil:edit"} />
                                                </Link>
                                            </Tooltip>
                                        )}

                                        {permissionCheck("role delete") && (
                                            <Tooltip
                                                title={t("Delete")}
                                                position="top"
                                                trigger="mouseenter"
                                            >
                                                <Link
                                                    onClick={() =>
                                                        deleteHandler({
                                                            id: item.id,
                                                            name: item.name,
                                                        })
                                                    }
                                                    id={
                                                        "delete-button" +
                                                        item.id
                                                    }
                                                >
                                                    <Icon
                                                        icon={
                                                            "fluent:delete-24-regular"
                                                        }
                                                    />
                                                </Link>
                                            </Tooltip>
                                        )}
                                    </ActionBtnGroupWrapper>
                                </TableCol>
                            </TableRow>
                        );
                    })
                )}
            </Table>
        </>
    );
}
