export default function Badge({children, variant}){
    let classNames = "payment-status";
    if(variant==="danger"){
        classNames = "payment-status danger";
    }
    else if(variant==='due'){
        classNames = "payment-status due";
    }
    else if(variant==='success'){
        classNames = "payment-status success";
    }
    return (<>
        
        <span className={classNames}>{children}</span>
    </>);
}