import { Icon } from "@iconify/react";
import { useState } from "react";
import SettingsForm from "../Forms/SettingsForm";
export default function SettingsCard({ title, id, inputs }) {
    const [cardOpen, setCardOpen] = useState(false);

    return (
        <>
            <div className="generalSettings">
                <div className="generalSettingsCardHead flex justify-between">
                    <h4 className="generalSettingsCardTitle">
                        {title}
                    </h4>
                    <button
                        type="button"
                        onClick={() => setCardOpen(!cardOpen)}
                    >
                        <Icon
                            className="permissionCardIcon text-xl dark:text-white"
                            icon={
                                !cardOpen ? "ph:plus-circle" : "ph:minus-circle"
                            }
                        />
                    </button>
                </div>
                <div
                    className={
                        "settingBox " + (cardOpen ? "py-5 h-auto" : "py-0 h-0")
                    }
                >
                    <SettingsForm id={id} inputs={inputs} />
                </div>
            </div>
        </>
    );
}
