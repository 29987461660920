export const guestRoutes = {
    login: {
        method: "POST",
        endpoint: "/login",
        headers: {
            "Content-Type": "multipart/form",
        },
    },
    loginOauth: {
        method: "POST",
        endpoint: "/login-oauth",
        headers: {
            "Content-Type": "multipart/form",
        },
    },
    register: {
        method: "POST",
        endpoint: "/register",
        headers: {
            "Content-Type": "multipart/form",
        },
    },
    forgotPassword: {
        method: "POST",
        endpoint: "/forgot-password",
        headers: {
            "Content-Type": "multipart/form",
        },
    },
    forgotPasswordOTP: {
        method: "POST",
        endpoint: "/validate-forgot-password-otp",
        headers: {
            "Content-Type": "multipart/form",
        },
    },
    currentGeneraSettings: {
        method: "GET",
        endpoint: "/general-settings",
        headers: {
            Accept: "application/json",
        },
    },


};
