export const aChart3 = {
    options:{
      chart: {
        type: "area",
        height: "48",
        width: "48",
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
        offsetX: 0,
        offsetY: 0,
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 2,
      },
      colors: ["#fd5693"],
      tooltip: {
        theme: "light",
      },
      grid: {
        show: false,
        padding: {
          left: 0,
          right: 0,
        },
      },
      yaxis: {
        show: false,
      },
      fill: {
        type: "solid",
        opacity: [0.1],
      },
      legend: {
        show: false,
      },
      xaxis: {
        low: 0,
        offsetX: 0,
        offsetY: 0,
        show: false,
        labels: {
          low: 0,
          offsetX: 0,
          show: false,
        },
        axisBorder: {
          low: 0,
          offsetX: 0,
          show: false,
        },
        categories: [1991, 1992, 1993, 1994, 1995],
      },
    }
    ,
    series: [
      {
        data: [200, 600, 200, 800, 950],
      },
    ],
}