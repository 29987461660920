import { createContext, useState } from "react";

export const RolePermissionContext = createContext({
    permissionArr: [],
    setPermissionArr: (arr) => {},
});

export default function RolePermissionContexttProvider({ children }) {
    const [permissionArr, setPermissionArr] = useState([]);

    function permissionArrayHandler(arr) {
        setPermissionArr(arr);
    }

    const value = {
        permissionArr: permissionArr,
        setPermissionArr: permissionArrayHandler,
    };

    return (
        <RolePermissionContext.Provider value={value}>
            {children}
        </RolePermissionContext.Provider>
    );
}
