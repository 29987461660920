import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import reactRouteList from "../../config/react-route-list-config";
export default function Breadcrumb({ list }) {
    let l = list.length;
    return (
        <ul className="flex flex-wrap items-center border-l-0 md:border-l border-l-slate-200">
            <li className="ml-2 mr-0">
                <Link
                    to={reactRouteList.home}
                    className="breadcrumbList flex items-center"
                >
                    <Icon
                        icon="ic:outline-home"
                        className="inline-block text-xl relative"
                    ></Icon>
                    <Icon
                        icon="material-symbols:arrow-forward-ios-rounded"
                        className="m-1 text-[17px] inline-block breadcrumbIcon"
                    ></Icon>
                </Link>
            </li>
            {list.map((item, index) => {
                let colorClass = "";
                if (index === list.length - 1) {
                    colorClass = "text-black";
                }
                return (
                    <li key={index} className={"inline-block"}>
                        <Link
                            to={item.link}
                            className={"breadcrumbList flex items-center "+colorClass}
                        >
                            {item.linkitem}
                            {index >= l - 1 ? (
                                ""
                            ) : (
                                <Icon
                                    icon="material-symbols:arrow-forward-ios-rounded"
                                    className="text-[17px] ml-3 inline-block breadcrumbIcon"
                                ></Icon>
                            )}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
}
