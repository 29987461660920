import { useTranslation } from "react-i18next";
import BarChart from "../../../components/Charts/BarChart";
import RadialChart from "../../../components/Charts/RadialChart";
import { barChartOne } from "../../../data/chartdata";
import { radialBarOne } from "../../../data/radialchart";

export default function ChartSection() {
    const [t] = useTranslation();
    return (
        <div className="md:flex gap-8 justify-between">
            <div className="md:w-8/12  bg-white dark:bg-slate-800 overflow-hidden p-6 rounded-md">
                <div><BarChart data={barChartOne}/></div>
            </div>
            <div className="mt-8 md:mt-0 md:w-4/12 bg-white dark:bg-slate-800 rounded-md">
                <h3 className="px-6 py-5 font-Outfit font-normal text-black dark:text-zinc-50 text-xl border-b border-b-slate-100">
                    {t('Overview')}
                </h3>
                <div className="overflow-hidden px-6 py-2 flex items-center justify-center">
                    <div><RadialChart data={radialBarOne}/></div>
                </div>
            </div>
        </div>
    );
}