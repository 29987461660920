import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import apiRouteList from "../../config/api-route-list-config";
import reactRouteList from "../../config/react-route-list-config";
import { RolePermissionContext } from "../../contexts/RolePermissionContext";
import ValidationError from "../Alerts/ValidationError";
import SubmitButton from "../Buttons/SubmitButton";
import CreateRolePermissionCard from "../Cards/CreateRolePermissionCard";

export default function CreateRoleForm({ id }) {
    const [permissionData, setPermissionData] = useState([]);
    const [roleName, setRoleName] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState({});
    const [t] = useTranslation();
    const roleNameInputRef = useRef(null);
    const navigate = useNavigate();
    const rolePermissionCtx = useContext(RolePermissionContext);
    function getInput(event) {
        setRoleName(event.target.value);
    }

    const createRoleHandler = async (e) => {
        setProcessing(true);
        //preparing form data
        e.preventDefault();

        //geting backend api
        const createRoleApi = apiRouteList.loggedRoutes.createRole;
        const data = {
            name: roleName,
            permissions: rolePermissionCtx.permissionArr,
        };
        try {
            await axios({
                method: createRoleApi.method,
                url: createRoleApi.endpoint,
                data: data,
                headers: createRoleApi.headers,
            })
                .then((response) => {
                    rolePermissionCtx.setPermissionArr(() => []);
                    navigate(reactRouteList.loggedRoutes.role_list);
                    swal({
                        title: t("Successful"),
                        text: t("Role Created Successfully!"),
                        icon: "success",
                        buttons: {
                            cancel: t("Ok"),
                        },
                    });
                })
                .catch((error) => {
                    setError(error.response.data.errors);
                    setProcessing(false);
                });
        } catch (error) {
            setProcessing(false);
        }
    };

    useEffect(() => {
        rolePermissionCtx.setPermissionArr([]);
        const showRoleHandler = async (e) => {
            setProcessing(true);

            //geting backend api
            const permissionsShowApi =
                apiRouteList.loggedRoutes.createRolePermissionFetch;

            try {
                await axios({
                    method: permissionsShowApi.method,
                    url: permissionsShowApi.endpoint,
                    headers: permissionsShowApi.headers,
                })
                    .then((response) => {
                        setProcessing(false);
                        let array = [];
                        let permissionModules = response.data.data;
                        for (const key in permissionModules) {
                            array.push(
                                <CreateRolePermissionCard
                                    key={key}
                                    title={key}
                                    permissionList={permissionModules[key]}
                                />
                            );
                        }
                        setPermissionData(array);
                    })
                    .catch((error) => {
                        setError(error.response.data.errors);
                        setProcessing(false);
                    });
            } catch (error) {
                setProcessing(false);
            }
        };

        showRoleHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <>
            <div className="rounded-md overflow-hidden">
                <div className="bg-white dark:bg-slate-800 px-5 py-7 border-b border-b-slate-100 dark:border-b-slate-900">
                    <h1 className="font-Outfit text-2xl text-black dark:text-white font-semibold">
                        {t("Create Role")}
                    </h1>
                </div>
                <div className="bg-white dark:bg-slate-800 px-5 py-7 ">
                    <form onSubmit={(e) => createRoleHandler(e)}>
                        <div className="input-group">
                            <label htmlFor="name" className="inputLabel">
                                {t("Role Name")}
                            </label>

                            <input
                                type="text"
                                defaultValue={roleName}
                                ref={roleNameInputRef}
                                id="name"
                                className="inputField2 w-full"
                                placeholder={t("Enter role name")}
                                required
                                onChange={(e) => getInput(e)}
                            />
                            <ValidationError value={error.name} />
                        </div>

                        <div className="input-group pt-2">
                            <label htmlFor="permissions" className="inputLabel">
                                {t("Select Permissions")}
                            </label>
                            <ValidationError value={error.permissions} />
                            <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-7">
                                {permissionData}
                            </div>
                        </div>

                        <SubmitButton processing={processing}>
                            {t("Save")}
                        </SubmitButton>
                    </form>
                </div>
            </div>
        </>
    );
}
