export const loggedRoutes = {
    //basic
    logout: {
        method: "POST",
        endpoint: "/logout",
        headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form",
        },
    },
    updateProfile: {
        method: "POST",
        endpoint: "/profile",
        headers: {
            "Content-Type": "multipart/form",
        },
    },
    resetPass: {
        method: "POST",
        endpoint: "/password-change",
        headers: {
            "Content-Type": "multipart/form",
        },
    },
    resendVerification: {
        method: "POST",
        endpoint: "/resend-verification",
        headers: {
            "Content-Type": "application/json",
        },
    },

    //list fetch
    users: {
        method: "GET",
        endpoint: "/users",
        headers: {
            Accept: "application/json",
        },
    },
    roles: {
        method: "GET",
        endpoint: "/roles",
        headers: {
            Accept: "application/json",
        },
    },
    permissions: {
        method: "GET",
        endpoint: "/permissions",
        headers: {
            Accept: "application/json",
        },
    },

    //create related
    createUser: {
        method: "POST",
        endpoint: "/users",
        headers: {
            "Content-Type": "multipart/form",
        },
    },
    createRole: {
        method: "POST",
        endpoint: "/roles",
        headers: {
            Accept: "application/json",
        },
    },
    createPermission: {
        method: "POST",
        endpoint: "/permissions",
        headers: {
            "Content-Type": "multipart/form",
        },
    },

    //fetch by id
    getUserByID: {
        method: "GET",
        endpoint: "/users/",
        headers: {
            Accept: "application/json",
        },
    },
    getRoleByID: {
        method: "GET",
        endpoint: "/roles/",
        headers: {
            Accept: "application/json",
        },
    },
    getPermissionByID: {
        method: "GET",
        endpoint: "/permissions/",
        headers: {
            Accept: "application/json",
        },
    },

    //update related
    updateUserByID: {
        method: "POST",
        endpoint: "/users/",
        headers: {
            "Content-Type": "multipart/form",
        },
    },
    updateRoleByID: {
        method: "PUT",
        endpoint: "/roles/",
        headers: {
            Accept: "application/json",
        },
    },
    updatePermissionByID: {
        method: "POST",
        endpoint: "/permissions/",
        headers: {
            "Content-Type": "multipart/form",
        },
    },

    //delete related
    deleteUser: {
        method: "DELETE",
        endpoint: "/users/",
        headers: {
            Accept: "application/json",
        },
    },
    deleteManyUser: {
        method: "DELETE",
        endpoint: "/users-delete-many",
        headers: {
            Accept: "application/json",
        },
    },
    deleteRole: {
        method: "DELETE",
        endpoint: "/roles/",
        headers: {
            Accept: "application/json",
        },
    },
    deletePermission: {
        method: "DELETE",
        endpoint: "/permissions/",
        headers: {
            Accept: "application/json",
        },
    },
    envShow: {
        method: "GET",
        endpoint: "/env",
        headers: {
            Accept: "application/json",
        },
    },
    envUpdate: {
        method: "POST",
        endpoint: "/env",
        headers: {
            "Content-Type": "multipart/form",
        },
    },

    currentUser: {
        method: "GET",
        endpoint: "/user",
        headers: {
            Accept: "application/json",
        },
    },

    generalSettingsUpdate: {
        method: "POST",
        endpoint: "/general-settings-images",
        headers: {
            "Content-Type": "multipart/form",
        },
    },
    databaseBackupIndex: {
        method: "GET",
        endpoint: "/database-backups",
        headers: {
            Accept: "application/json",
        },
    },
    databaseBackupDownload: {
        method: "GET",
        endpoint: "/database-backups-download/",
        headers: {
            Accept: "application/json",
        },
    },
    databaseBackupCreate: {
        method: "GET",
        endpoint: "/database-backups-create",
        headers: {
            Accept: "application/json",
        },
    },
    deleteDatabaseBackup: {
        method: "DELETE",
        endpoint: "/database-backups/",
        headers: {
            Accept: "application/json",
        },
    },
    createRolePermissionFetch: {
        method: "GET",
        endpoint: "/roles/create",
        headers: {
            Accept: "application/json",
        },
    },
};
