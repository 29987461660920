export default function TableRow({ children, header }) {
    return (
        <>
            { header?
                <tr className="bg-[#F8F8F8] border border-slate-100 dark:bg-slate-700 dark:border-slate-800 relative">
                    {children}
                </tr>:
                <tr className="border border-slate-100 dark:border-slate-900 relative">
                    {children}
                </tr>
            }
        </>
    );
}
