import en from "./translations/en.json";
import de from "./translations/de.json";
import fr from "./translations/fr.json";

export const langSupport = {
    fallbackLng: "en",
    interpolation: { escapeValue: false },
    lng: "en",
    resources: {
        en: en,
        de: de,
        fr: fr,
    },
};
