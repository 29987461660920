import LogoAndTitle from "../components/Logo/LogoAndTitle";
import CopyrightText from "../components/Texts/CopyrightText";
import GuestPageText from "../components/Texts/GuestPageText";
import ColumnLeft from "./Columns/ColumnLeft";
import ColumnRight from "./Columns/ColumnRight";
import GeneralContentContainer from "./Containers/GeneralContentContainer";

export default function GeneralLayout({ children }) {
    return (
        <>
            <GeneralContentContainer>
                <ColumnLeft>
                    <div className="px-7 py-9 xl:p-5">
                        <LogoAndTitle />
                        <GuestPageText />
                    </div>
                </ColumnLeft>
                <ColumnRight>
                    {children}
                    <CopyrightText />
                </ColumnRight>
            </GeneralContentContainer>
        </>
    );
}
