import Login from "../../../components/Forms/Login";
import GeneralLayout from "../../../Layouts/GeneralLayout";
import { changeBrowserTabTitle } from "../../../utils/Browser";

export default function LoginPage() {
    changeBrowserTabTitle("Login");
    return (
        <GeneralLayout>
            <Login />
        </GeneralLayout>
    );
}
