import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import apiRouteList from "../../config/api-route-list-config";
import { getTheme, getToken } from "../../utils/Storage";
import ValidationError from "../Alerts/ValidationError";
import SubmitButton from "../Buttons/SubmitButton";

export default function EditPermissionForm() {
    const { id } = useParams();
    const [permissionData, setPermissionData] = useState({});
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState({});
    const [t] = useTranslation();

    function getInput(event, input) {
        let dataTemp = permissionData;
        dataTemp[input] = event.target.value;
        setPermissionData(dataTemp);
    }

    const editPermissionHandler = async (e) => {
        setProcessing(true);
        //preparing form data
        e.preventDefault();
        const editPermissionFormData = new FormData();
        editPermissionFormData.append("name", permissionData.name);
        editPermissionFormData.append("module_name", permissionData.module);
        editPermissionFormData.append("_method", "PATCH");

        //geting backend api
        let editPermissionApi = apiRouteList.loggedRoutes.updatePermissionByID;
        editPermissionApi.headers.Authorization = "Bearer " + getToken();

        try {
            await axios({
                method: editPermissionApi.method,
                url: editPermissionApi.endpoint + id,
                data: editPermissionFormData,
                headers: editPermissionApi.headers,
            });

            setProcessing(false);

            toast.success(t("Successfully Updated!"), {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: getTheme() === "dark" ? "dark" : "light",
            });
        } catch (error) {
            setError(error.response.data.errors);
            setProcessing(false);
        }
    };

    useEffect(() => {
        const showPermissionHandler = async (e) => {
            setProcessing(true);

            //geting backend api
            let permissionShowApi = apiRouteList.loggedRoutes.getPermissionByID;

            try {
                await axios({
                    method: permissionShowApi.method,
                    url: permissionShowApi.endpoint + id,
                    headers: permissionShowApi.headers,
                })
                    .then(async (response) => {
                        setProcessing(false);
                        const data = response.data.data;
                        setPermissionData(data);
                    })
                    .catch((error) => {
                        setProcessing(false);
                    });
            } catch (error) {
                setProcessing(false);
            }
        };
        showPermissionHandler();
    }, [id]);

    return (
        <>
            {permissionData && (
                <form
                    onSubmit={(e) => editPermissionHandler(e)}
                    className="lg:w-full  bg-white dark:bg-slate-800 rounded-md p-5 pb-6 flex justify-center"
                >
                    <div className="lg:w-2/4">
                        <div className="grid-cols-2 gap-x-8 gap-y-4">
                            <div className="input-group">
                                <label htmlFor="name" className="inputLabel">
                                    {t("Module Name")}
                                </label>
                                <input
                                    defaultValue={permissionData.module_name}
                                    onChange={(e) => getInput(e, "module")}
                                    name="module_name"
                                    type="text"
                                    id="module_name"
                                    className="inputField2 w-full"
                                    placeholder={t("Enter module name")}
                                    required={true}
                                />
                                <ValidationError value={error.module_name} />
                            </div>

                            <div className="input-group">
                                <label htmlFor="name" className="inputLabel">
                                    {t("Name")}
                                </label>
                                <input
                                    defaultValue={
                                        typeof permissionData.name === "string"
                                            ? permissionData.name.split(" ")[1]
                                            : ""
                                    }
                                    onChange={(e) => getInput(e, "name")}
                                    name="name"
                                    type="text"
                                    id="name"
                                    className="inputField2 w-full"
                                    placeholder={t("Enter permission name")}
                                    required={true}
                                />
                                <ValidationError value={error.name} />
                            </div>
                        </div>
                        <SubmitButton processing={processing}>
                            {t("Save Changes")}
                        </SubmitButton>
                    </div>
                </form>
            )}
        </>
    );
}
