import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";

export default function DataPreloader() {
    const [t] = useTranslation();

    return (
        <span className="flex justify-center items-center">
            <span className="flex items-center text-black dark:text-white h-60">
                <Spinner />
                <span className="pl-1">{t("Loading data")}....</span>
            </span>
        </span>
    );
}
