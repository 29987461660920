import Table from "../../../components/Tables/Table";
import TableCol from "../../../components/Tables/TableCol";
import TableRow from "../../../components/Tables/TableRow";
import ActionBtnGroupWrapper from "../../../Layouts/Containers/ActionBtnGroupWrapper";
import { useEffect, useState } from "react";
import { loggedRoutes } from "../../../config/apiRouteLists/logged-route-lists";
import axios from "axios";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import apiRouteList from "../../../config/api-route-list-config";
import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tippy";
import TableHeaderCol from "../../../components/Tables/TableHeaderCol";
import { toast } from "react-toastify";
import { getTheme } from "../../../utils/Storage";
import ButtonBlack from "../../../components/Buttons/ButtonBlack";
import BadgeMini from "../../../components/Badges/BadgeMini";
import { downloadFile } from "../../../utils/Browser";
import { permissionCheck } from "../../../utils/permission";
export default function DBBackupListTables() {
    const [files, setFileList] = useState([]);
    const [databaseBackupList, setDatabaseBackupList] = useState([]);
    let sl_files_base = 1;
    let sl_dbbackup_base = 1;
    const [t] = useTranslation();
    const [refresh, setRefresh] = useState(false);
    const [processing, setProcessing] = useState(false);

    async function fetchFileList() {
        try {
            const dbApi = loggedRoutes.databaseBackupIndex;
            await axios({
                method: dbApi.method,
                url: dbApi.endpoint,
                headers: dbApi.headers,
            })
                .then((response) => {
                    const data = response.data;
                    console.log(data);
                    setFileList(data.data.files);
                    setDatabaseBackupList(data.data.databaseBackupList);
                })
                .catch((error) => {});
        } catch (error) {
            toast.error(t("Error!"), {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: getTheme() === "dark" ? "dark" : "light",
            });
        }
    }

    useEffect(() => {
        fetchFileList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    async function deleteHandler(id) {
        const willDelete = await swal({
            title: t("Are you sure?"),
            text: t("Are you sure that you want to delete this file?"),
            icon: "warning",
            dangerMode: true,
            buttons: {
                cancel: t("No"),
                yes: t("Yes"),
            },
        });

        if (willDelete === "yes") {
            try {
                const deleteApi =
                    apiRouteList.loggedRoutes.deleteDatabaseBackup;

                await axios({
                    method: deleteApi.method,
                    url: deleteApi.endpoint + id,
                    headers: deleteApi.headers,
                })
                    .then((response) => {
                        setRefresh(() => !refresh);
                        swal(
                            t("Deleted!"),
                            t("File has been deleted!"),
                            "success"
                        );
                    })
                    .catch((error) => {
                        swal(
                            t("Failed!"),
                            t("Something went wrong! Try again!"),
                            "error"
                        );
                    });
            } catch (error) {
                toast.error("Error!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: getTheme() === "dark" ? "dark" : "light",
                });
            }
        }
    }

    async function downloadFileHandler(file_name) {
        const willDelete = await swal({
            title: t("Downlaod File?"),
            text:
                t("Are you sure that you want to download this file named") +
                " " +
                file_name +
                "?",
            dangerMode: true,
            buttons: {
                cancel: t("No"),
                yes: t("Download"),
            },
        });

        if (willDelete === "yes") {
            try {
                const fileDownloadApi =
                    apiRouteList.loggedRoutes.databaseBackupDownload;
                axios({
                    method: fileDownloadApi.method,
                    url: fileDownloadApi.endpoint + file_name,
                    responseType: "blob",
                    headers: fileDownloadApi.headers,
                })
                    .then((response) => {
                        downloadFile(response.data, file_name);
                    })
                    .catch((error) => {
                        swal(
                            t("Failed!"),
                            t("Something went wrong! Try again!"),
                            "error"
                        );
                    });
            } catch (error) {
                toast.error("Error!", {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: getTheme() === "dark" ? "dark" : "light",
                });
            }
        }
    }

    async function createBackup() {
        setProcessing(true);
        try {
            const dbApi = loggedRoutes.databaseBackupCreate;
            await axios({
                method: dbApi.method,
                url: dbApi.endpoint,
                headers: dbApi.headers,
            })
                .then((response) => {
                    toast.success(t("Successfully created!"), {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: getTheme() === "dark" ? "dark" : "light",
                    });
                    setRefresh(() => !refresh);
                    setProcessing(false);
                })
                .catch((error) => {
                    setProcessing(false);
                });
        } catch (error) {
            toast.error("Error!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: getTheme() === "dark" ? "dark" : "light",
            });
            setProcessing(false);
        }
    }

    const buttons = permissionCheck("database_backup create") && (
        <ButtonBlack
            processing={processing}
            onClick={() => createBackup()}
            icon={"iconoir:database-backup"}
        >
            {t("Create Backup")}
        </ButtonBlack>
    );
    return (
        <>
            <Table
                tableInfo={{
                    title: t("Backup List"),
                    total: files.length,
                    checkboxInputArray: [],
                }}
                tableOptions={{
                    title: true,
                }}
            >
                <TableRow header={true}>
                    <TableHeaderCol sort={false} sticky={true}>
                        {t("Sl No.")}
                    </TableHeaderCol>
                    <TableHeaderCol sort={false}>{t("Name")}</TableHeaderCol>
                    <TableHeaderCol sort={false}>{t("Disk")}</TableHeaderCol>
                    <TableHeaderCol sort={false}>
                        {t("Reachable")}
                    </TableHeaderCol>
                    <TableHeaderCol sort={false}>{t("Health")}</TableHeaderCol>
                    <TableHeaderCol sort={false}>
                        {t("Amount of Backups")}
                    </TableHeaderCol>

                    <TableHeaderCol sort={false}>
                        {t("Newest backup")}
                    </TableHeaderCol>

                    <TableHeaderCol sort={false}>
                        {t("Used Storage")}
                    </TableHeaderCol>
                </TableRow>
                {databaseBackupList.map((item, index) => {
                    return (
                        <TableRow key={index}>
                            <TableCol sticky={true}>
                                # {sl_dbbackup_base++}
                            </TableCol>
                            <TableCol>{item.name}</TableCol>
                            <TableCol>{item.disk}</TableCol>
                            <TableCol>
                                <BadgeMini
                                    variant={
                                        item.reachable ? "success" : "danger"
                                    }
                                >
                                    {item.reachable ? t("Yes") : t("No")}
                                </BadgeMini>
                            </TableCol>

                            <TableCol>
                                <BadgeMini
                                    variant={
                                        item.healthy ? "success" : "danger"
                                    }
                                >
                                    {item.healthy ? t("Good") : t("Bad")}
                                </BadgeMini>
                            </TableCol>

                            <TableCol>{item.amount}</TableCol>

                            <TableCol>{item.newest}</TableCol>
                            <TableCol>{item.usedStorage}</TableCol>
                        </TableRow>
                    );
                })}
            </Table>
            <br />
            <Table
                tableInfo={{
                    title: t("File List"),
                    total: files.length,
                    checkboxInputArray: [],
                }}
                tableOptions={{
                    title: true,
                }}
                buttons={buttons}
            >
                <TableRow header={true}>
                    <TableHeaderCol sort={false} sticky={true}>
                        {t("Sl No.")}
                    </TableHeaderCol>
                    <TableHeaderCol sort={false}>
                        {t("File name")}
                    </TableHeaderCol>

                    <TableHeaderCol sort={false}>{t("Path")}</TableHeaderCol>

                    <TableHeaderCol sort={false}>{t("Date")}</TableHeaderCol>

                    <TableHeaderCol sort={false}>{t("Size")}</TableHeaderCol>

                    <TableHeaderCol sort={false}>{t("Action")}</TableHeaderCol>
                </TableRow>
                {files.map((item, index) => {
                    return (
                        <TableRow key={index}>
                            <TableCol sticky={true}>
                                # {sl_files_base++}
                            </TableCol>
                            <TableCol>{item.file_name}</TableCol>
                            <TableCol>{item.path}</TableCol>
                            <TableCol>{item.date}</TableCol>
                            <TableCol>{item.size}</TableCol>
                            <TableCol>
                                <ActionBtnGroupWrapper>
                                    {permissionCheck(
                                        "database_backup download"
                                    ) && (
                                        <Tooltip
                                            title={t("Download")}
                                            position="top"
                                            trigger="mouseenter"
                                        >
                                            <Link
                                                onClick={() =>
                                                    downloadFileHandler(
                                                        item.file_name
                                                    )
                                                }
                                            >
                                                <Icon
                                                    icon={
                                                        "ic:baseline-download"
                                                    }
                                                />
                                            </Link>
                                        </Tooltip>
                                    )}
                                    {permissionCheck(
                                        "database_backup delete"
                                    ) && (
                                        <Tooltip
                                            title={t("Delete")}
                                            position="top"
                                            trigger="mouseenter"
                                        >
                                            <Link
                                                onClick={() =>
                                                    deleteHandler(item.index)
                                                }
                                            >
                                                <Icon
                                                    icon={
                                                        "fluent:delete-24-regular"
                                                    }
                                                />
                                            </Link>
                                        </Tooltip>
                                    )}
                                </ActionBtnGroupWrapper>
                            </TableCol>
                        </TableRow>
                    );
                })}
            </Table>
        </>
    );
}
