import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import { langSupport } from "./lang";
import axios from "axios";
import { forgetLogged, getToken, isLogged, isVerified } from "./utils/Storage";
import "react-tippy/dist/tippy.css";
import { BrowserRouter } from "react-router-dom";

i18next.init(langSupport);

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.response.status === 401) {
            forgetLogged();
            window.location.href = "/";
        }
        return Promise.reject(error);
    }
);

axios.interceptors.request.use(function (config) {
    if (isLogged() && isVerified()) {
        if (getToken() === null || getToken() === undefined) {
            forgetLogged();
            if (window.location.href !== "/") {
                window.location.href = "/";
            }
            return;
        }
        config.headers.Authorization = "Bearer " + getToken();
    }
    if (isLogged() && getToken() && !isVerified()) {
        config.headers.Authorization = "Bearer " + getToken();
    }
    return config;
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <I18nextProvider i18n={i18next}>
                <App />
            </I18nextProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
