import SideBar from "../components/Sidebar/SideBar";
import TopBar from "../components/Topbar/Topbar";
import DashboardContentContainer from "./Containers/DashboardContentContainer";

import TopbarArea from "./Containers/TopbarArea";
import { motion } from "framer-motion";


export default function DashboardLayout({ children }) {
    return (
        <DashboardContentContainer>
            <SideBar />
            <TopbarArea>
                <TopBar />
                <div className="overflow-hidden">
                    <motion.div
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: [0,.15,1], scale:1 }}
                        transition={{ duration: .7, ease: "easeIn" }}
                    >
                        {children}
                    </motion.div>
                </div>

            </TopbarArea>
        </DashboardContentContainer>
    );
}
