export const radialBarOne = {
    series: [88, 77, 66, 55],

    options: {
        chart: {
            height: 350,
            type: "radialBar",
        },
        colors: ["#4669FA", "#0CE7FA", "#FA916B", "#7127FA"],
        labels: ["Books", "Pens", "Pencils", "Box"],
        plotOptions: {
            radialBar: {
                dataLabels: {
                    name: {
                        fontSize: "22px",
                        fontFamily: "OutFit",
                    },
                    value: {
                        fontSize: "16px",
                        fontFamily: "OutFit",
                    },
                    total: {
                        show: true,
                        label: "Total",
                        formatter: function (w) {
                            // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                            return 249;
                        },
                    },
                },
            },
        },
    },
};
