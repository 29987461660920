export function changeBrowserTabTitle(title) {
    document.querySelector("title").innerText = "Dashkit: " + title;
}

export function changeBrowserFavicon(link) {
    document.querySelector("link[rel~='icon']").href = link;
}

export function downloadFile(data, file_name) {
    const href = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", file_name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
}
