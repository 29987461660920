import DashboardLayout from "../../../../Layouts/DashboardLayout";
import ContentBodyWrapper from "../../../../Layouts/Containers/ContentBodyWrapper";
import ContentBodyHeader from "../../../../Layouts/Containers/ContentBodyHeader";
import UserTable from "./UserTable";
import reactRouteList from "../../../../config/react-route-list-config";
import { useTranslation } from "react-i18next";
import { permissionCheck } from "../../../../utils/permission";
import { changeBrowserTabTitle } from "../../../../utils/Browser";
import ButtonBlack from "../../../../components/Buttons/ButtonBlack";
export default function UserListPage() {
    const [t] = useTranslation();

    changeBrowserTabTitle("Users");

    const buttons = (
        <ButtonBlack
            to={reactRouteList.loggedRoutes.create_users}
            icon={"material-symbols:add"}
        >
            {t("New")}
        </ButtonBlack>
    );

    return (
        <>
            <DashboardLayout>
                <ContentBodyWrapper showFooter={true}>
                    <ContentBodyHeader
                        info={{
                            title: t("Users"),
                        }}
                        breadcrumb={[
                            {
                                linkitem: t("Users"),
                                link: reactRouteList.loggedRoutes.user_list,
                            }
                        ]}
                        buttons={permissionCheck("user create") && buttons}
                    />
                    <UserTable />
                </ContentBodyWrapper>
            </DashboardLayout>
        </>
    );
}
