import Spinner from "../Preloader/Spinner";

export default function SubmitButtonBlock({onClick,children, processing}){
    return (<>
            <button type="submit" onClick={onClick}
                className="w-full bg-black hover:bg-gray-800 transition-all duration-500 text-white py-3 px-3 font-Outfit text-base font-medium rounded-md mt-8">
                    <span className="flex items-center justify-center">
                        <span className="flex items-center">
                            {processing && <Spinner bg={"dark"} />}
                            <span className="pl-1">
                                {children}
                            </span>
                        </span>
                    </span>
            </button>
    </>);
}