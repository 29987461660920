import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import reactRouteList from "../../config/react-route-list-config";
import NoBorderNoStyleButton from "../Buttons/NoBorderNoStyleButton";

export default function CopyrightText(){
    const navigate = useNavigate();
    const [t] = useTranslation();
    return (<>
        <div className="relative mb-0 mt-8 block  bottom-0 lg:bottom-5 left-1/2 -translate-x-1/2 w-full text-center">
          <p className="font-Outfit text-sm font-light text-textColor">
            {t('Copyright')} {new Date().getFullYear()}, <NoBorderNoStyleButton onClick={()=>navigate(reactRouteList.home)}>Dashcode</NoBorderNoStyleButton> {t("All Rights Reserved.")}
          </p>
        </div>
    </>);
}