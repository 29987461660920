import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import DataPreloader from "../../../../components/Preloader/DataPreloader";
import apiRouteList from "../../../../config/api-route-list-config";
import { dateToYears } from "../../../../utils/Date";

export default function ShowUser() {
    let { id } = useParams();
    const [userData, setUserData] = useState({});
    const [processing, setProcessing] = useState(false);
    const [t] = useTranslation();

    useEffect(() => {
        const showUserHandler = async (e) => {
            setProcessing(true);

            //geting backend api
            let userShowApi = apiRouteList.loggedRoutes.getUserByID;

            try {
                await axios({
                    method: userShowApi.method,
                    url: userShowApi.endpoint + id,
                    headers: userShowApi.headers,
                })
                    .then((response) => {
                        setProcessing(false);
                        const data = response.data;
                        setUserData(data.data);
                    })
                    .catch((error) => {
                        setProcessing(false);
                    });
            } catch (error) {
                setProcessing(false);
            }
        };
        showUserHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <>
            <div className="bg-white dark:bg-slate-800 rounded-md p-5 pb-6">
                {processing ? (
                    <div className="grid sm:grid-cols-1 gap-x-8 gap-y-4">
                        <div className="input-group">
                            <p className="inputField2 w-full">
                                <DataPreloader />
                            </p>
                        </div>
                    </div>
                ) : (
                    <div className="grid sm:grid-cols-2 gap-x-8 gap-y-4">
                        <div className="input-group">
                            <label className="inputLabel">{t("Name")}</label>
                            <p className="inputField2 w-full">
                                {userData.name}{" "}
                            </p>
                        </div>

                        <div className="input-group">
                            <label className="inputLabel">{t("Email")}</label>
                            <p className="inputField2 w-full">
                                {userData.email}{" "}
                            </p>
                        </div>

                        <div className="input-group">
                            <label className="inputLabel">{t("Role")}</label>
                            <p className="inputField2 w-full capitalize">
                                {userData.role || "No role"}{" "}
                            </p>
                        </div>

                        <div className="input-group">
                            <label className="inputLabel">{t("Created")}</label>
                            <p className="inputField2 w-full">
                                {dateToYears(userData.created_at)}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}
