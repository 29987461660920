import { useTranslation } from "react-i18next";
import EcommerceCard from "../../../components/Cards/EcommerceCard";
import EcommerceCardBG from "../../../components/Cards/EcommerceCardBG";
import { echart1Data } from "../../../data/echart1data";
import { echart2Data } from "../../../data/echart2data";
import { echart3Data } from "../../../data/echart3data";

export default function TopCardGroupSection() {
  const [t] = useTranslation();
    return (<>
          <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-7">
            <EcommerceCardBG/>
            <EcommerceCard chartData={echart1Data} icon={"ph:shopping-cart-simple-bold"} title={t("Total revenue")} data={"$1494"}/>
            <EcommerceCard chartData={echart2Data}  icon={"teenyicons:box-outline"} title={t("Products sold")} data={"944"}/>
            <EcommerceCard chartData={echart3Data}  icon={"carbon:growth"} title={t("Growth")} data={"+11%"}/>
          </div>
    </>);
}