import { Icon } from "@iconify/react";
import axios from "axios";
import { useEffect, useState } from "react";
import apiRouteList from "../../config/api-route-list-config";
import { getTheme } from "../../utils/Storage";
import { toast } from "react-toastify";
import ValidationError from "../Alerts/ValidationError";
import { useTranslation } from "react-i18next";
import Spinner from "../Preloader/Spinner";
import SubmitButton from "../Buttons/SubmitButton";

export default function EditUserForm({ id }) {
    const [userData, setUserData] = useState({});
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState({});
    const [userRole, setUserRole] = useState(null);
    const [rolesData, setRolesData] = useState([]);
    const [processingRole, setProcessingRole] = useState(false);
    const [t] = useTranslation();
    function getInput(event, input) {
        let userDataTemp = userData;
        userDataTemp[input] = event.target.value;
        setUserData(userDataTemp);
        if (input === "role") {
            setUserRole(event.target.value);
        }
    }

    const editUserHandler = async (e) => {
        setProcessing(true);
        //preparing form data
        e.preventDefault();
        const userEditFormData = new FormData();
        userEditFormData.append("name", userData.name);
        userEditFormData.append("email", userData.email);
        if (userData.password !== null) {
            userEditFormData.append("password", userData.password);
        }
        userEditFormData.append("role", userRole);
        userEditFormData.append("_method", "PUT");

        //geting backend api
        let editUserApi = apiRouteList.loggedRoutes.updateUserByID;
        try {
            await axios({
                method: editUserApi.method,
                url: editUserApi.endpoint + id,
                data: userEditFormData,
                headers: editUserApi.headers,
            })
                .then((response) => {
                    setProcessing(false);
                    setError({});
                    toast.success(t("Successfully updated user."), {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: getTheme() === "dark" ? "dark" : "light",
                    });
                })
                .catch((error) => {
                    setError(error.response.data.errors);
                    setProcessing(false);
                });
        } catch (error) {
            setProcessing(false);
        }
    };

    useEffect(() => {
        const showUserAndRoleHandler = async (e) => {
            setProcessing(true);

            //geting backend api
            let userShowApi = apiRouteList.loggedRoutes.getUserByID;

            try {
                await axios({
                    method: userShowApi.method,
                    url: userShowApi.endpoint + id,
                    headers: userShowApi.headers,
                })
                    .then(async (response) => {
                        setProcessing(false);
                        const userFetchData = response.data.data;
                        setUserData(userFetchData);

                        //roles fetching================================
                        setProcessingRole(true);
                        let fetchRolesApi = apiRouteList.loggedRoutes.roles;
                        try {
                            await axios({
                                method: fetchRolesApi.method,
                                url: fetchRolesApi.endpoint,
                                headers: fetchRolesApi.headers,
                            })
                                .then((response) => {
                                    const roles_data = response.data.data;

                                    let x = roles_data.find(
                                        (item) =>
                                            item.name === userFetchData.role
                                    );
                                    if (x === undefined || x === null) {
                                        setUserRole(-1);
                                    } else {
                                        setUserRole(x.id);
                                    }
                                    setProcessingRole(false);
                                    setRolesData(roles_data);
                                })
                                .catch((error) => {
                                    setProcessingRole(false);
                                });
                        } catch (error) {
                            setProcessingRole(false);
                        }
                    })
                    .catch((error) => {
                        setError(error.response.data.errors);
                        setProcessing(false);
                    });
            } catch (error) {
                setProcessing(false);
            }
        };

        showUserAndRoleHandler();
    }, [id]);

    return (
        <>
            <form
                onSubmit={(e) => editUserHandler(e)}
                className="bg-white dark:bg-slate-800 rounded-md p-5 pb-6"
            >
                <div className="grid sm:grid-cols-2 gap-x-8 gap-y-4">
                    <div className="input-group">
                        <label htmlFor="name" className="inputLabel">
                            {t("Name")}
                        </label>
                        <input
                            defaultValue={userData.name}
                            onChange={(e) => getInput(e, "name")}
                            name="name"
                            type="text"
                            id="name"
                            className="inputField2 w-full"
                            placeholder={t("Enter your name")}
                            required={true}
                        />
                        <ValidationError value={error.name} />
                    </div>

                    <div className="input-group">
                        <label htmlFor="email" className="inputLabel">
                            {t("Email")}
                        </label>
                        <input
                            defaultValue={userData.email}
                            onChange={(e) => getInput(e, "email")}
                            name="email"
                            type="email"
                            id="email"
                            className="inputField2 w-full"
                            placeholder={t("Enter your email")}
                            required={true}
                        />
                        <ValidationError value={error.email} />
                    </div>

                    <div className="input-group">
                        <label htmlFor="password" className="inputLabel">
                            {t("Password")}
                        </label>
                        <input
                            defaultValue={null}
                            onChange={(e) => getInput(e, "password")}
                            name="password"
                            type="password"
                            id="password"
                            className="inputField2 w-full"
                            placeholder={t("Enter your password")}
                        />
                        <ValidationError value={error.password} />
                    </div>

                    <div className="input-group w-full sm:w-auto">
                        <label className="inputLabel">{t("Role")} </label>
                        {processingRole ? (
                            <>
                                <Spinner />
                                <select
                                    disabled={true}
                                    defaultValue={null}
                                    name="role"
                                    className="dropdownTableSelect h-12 px-4 w-full font-Outfit text-textColor font-medium "
                                >
                                    t
                                </select>
                            </>
                        ) : (
                            <select
                                defaultValue={userRole}
                                onChange={(e) => getInput(e, "role")}
                                name="role"
                                className="dropdownTableSelect h-12 px-4 w-full font-Outfit text-textColor font-medium "
                            >
                                <option value={-1}>{t("Select role")}</option>
                                {rolesData.map((item, index) => {
                                    return (
                                        <option key={index} value={item.id}>
                                            {item.name}
                                        </option>
                                    );
                                })}
                            </select>
                        )}
                        <Icon
                            className={`absolute right-3 ${
                                error.role ? "bottom-9" : "bottom-3"
                            } text-xl dark:text-white`}
                            icon="material-symbols:keyboard-arrow-down-rounded"
                        ></Icon>
                        <ValidationError value={error.role} />
                    </div>
                </div>
                <SubmitButton processing={processing}>
                    {t("Save Changes")}
                </SubmitButton>
            </form>
        </>
    );
}
