import { useTranslation } from "react-i18next";
import CenteredLayout from "../../../Layouts/CenteredLayout";
import { changeBrowserTabTitle } from "../../../utils/Browser";
import errorImage403 from "../../../assets/images/403.svg";
import ButtonBlack from "../../../components/Buttons/ButtonBlack";
import reactRouteList from "../../../config/react-route-list-config";
export default function UnAuthorizedPage() {
    changeBrowserTabTitle("Access Denied! Unauthorized Page");
    const [t] = useTranslation();
    return (
        <CenteredLayout>
            <div className="lg:w-3/12 md:w-6/12 sm:w-full">
                {" "}
                <img
                    src={errorImage403}
                    alt="403 error"
                    className="w-full mb-6"
                />
                <p className="text-center text-3xl m-2 block">
                    <b>{t("Access Denied")}</b>
                </p>
                <p className="text-center mt-4 mb-7">
                    {t("You don't have permission to view this page.")}
                </p>
                <p className="text-center mt-4 mb-7">
                    <ButtonBlack to={reactRouteList.home}>
                        {t("Go To Homepage")}
                    </ButtonBlack>
                </p>
            </div>
        </CenteredLayout>
    );
}
