import { Icon } from "@iconify/react";

export default function Spinner({bg="white"}) {
    return (
        <Icon
            className={`h-4 w-4 animate-spin ${ bg==="dark"? "text-white": "text-black dark:text-white"} inline-block align-middle`}
            icon="fluent:spinner-ios-20-filled"
        ></Icon>
    );
}
