import reactRouteList from "../config/react-route-list-config"

export const languageList = [
    {
        id: 1,
        title: "EN",
        fullform: "English",
        code: "en",
        icon: "emojione:flag-for-united-states"
    },
    {
        id: 2,
        title: "DE",
        fullform: "German",
        code: "de",
        icon: "emojione:flag-for-germany"
    },
    {
        id: 3,
        title: "FR",
        fullform: "French",
        code: "fr",
        icon: "emojione:flag-for-france"
    }
]

export const topBarDropDownOptions = [
    {
        id: 1,
        title: "Profile",
        icon: "carbon:user-avatar",
        link: reactRouteList.loggedRoutes.profile
    },
    {
        id: 2,
        title: "Settings",
        icon: "carbon:settings",
        link: reactRouteList.loggedRoutes.settings
    }

]