import { Icon } from "@iconify/react";
import { useState } from "react";

export default function TableHeaderCol({
    children,
    sticky,
    sort = true,
    setSortParams,
    sortParamName,
}) {
    const [arrowSort, setArrowSort] = useState(true);

    let str = "table-head ";
    if (sticky) {
        str += " sticky left-0 ";
    }

    return (
        <th className={str}>
            <div
                className="flex items-center cursor-pointer select-none"
                onClick={() => {
                    if(sort){
                        setSortParams((prev) => ({
                            ...prev,
                            [sortParamName]: !arrowSort,
                        }));
                        setArrowSort(!arrowSort);
                    }
                }}
            >
                <span>{children}</span>
                <span>
                    {sort &&
                        (arrowSort ? (
                            <Icon icon="fluent:arrow-sort-down-16-filled" />
                        ) : (
                            <Icon icon="fluent:arrow-sort-up-16-filled" />
                        ))}
                </span>
            </div>
        </th>
    );
}
