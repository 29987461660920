import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";
import cust1Img from "../../../assets/images/cus-1.png";
import cust2Img from "../../../assets/images/cus-2.png";
import cust3Img from "../../../assets/images/cus-3.png";
import UserTable from "./UserTable";

export default function TableAndChartSection() {
    const [t] = useTranslation();
    return (
        <div className="md:flex justify-between gap-8">
            <div className="w-full xl:w-6/12 bg-white dark:bg-slate-800 rounded-md">
                <div className="px-6 py-5 border-b border-b-slate-100 flex justify-between items-center">
                    <h3 className="font-Outfit font-normal text-black dark:text-zinc-50 text-xl">
                        {t("Best Customer")}
                    </h3>
                </div>
                <div className="grid md:grid-cols-2 lg:grid-cols-3 py-5 px-5 gap-7">
                    <div className="customer-card bg-sky-50 ">
                        <div className="customer-card-img">
                            <img src={cust1Img} alt="customer" />
                            <div className="customer-position">
                                <span>2</span>
                            </div>
                        </div>
                        <h3>Danniel Smith</h3>
                        <p>50.5</p>
                        <div className="progressBar">
                            <div className="progressData">
                                <span>{t("Progress")}</span> <span>50%</span>
                            </div>
                            <div
                                className="progressBarArea bg-gray-200 rounded h-2"
                                role="progressbar"
                            >
                                <div
                                    style={{
                                        width: "50%",
                                        transition: "width 2s",
                                    }}
                                    className=" bg-sky-400"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="customer-card bg-orange-50 ">
                        <div className="customer-card-img">
                            <Icon
                                className="MVPCustomer"
                                icon="ph:crown-simple-fill"
                            ></Icon>
                            <img src={cust2Img} alt="customer" />
                            <div className="customer-position">
                                <span>1</span>
                            </div>
                        </div>
                        <h3>Danniel Smith</h3>
                        <p>50.5</p>
                        <div className="progressBar">
                            <div className="progressData">
                                <span>{t("Progress")}</span> <span>90%</span>
                            </div>
                            <div
                                className="progressBarArea bg-gray-200 rounded h-2"
                                role="progressbar"
                            >
                                <div
                                    style={{
                                        width: "90%",
                                        transition: "width 2s",
                                    }}
                                    className="bg-orange-400"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="customer-card bg-green-50 ">
                        <div className="customer-card-img">
                            <img src={cust3Img} alt="customer" />
                            <div className="customer-position">
                                <span>3</span>
                            </div>
                        </div>
                        <h3>Danniel Smith</h3>
                        <p>50.5</p>
                        <div className="progressBar">
                            <div className="progressData">
                                <span>{t("Progress")}</span> <span>50%</span>
                            </div>
                            <div
                                className="progressBarArea bg-gray-200 rounded h-2"
                                role="progressbar"
                            >
                                <div
                                    style={{
                                        width: "50%",
                                        transition: "width 2s",
                                    }}
                                    className="bg-green-400"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="customer-card bg-sky-50 ">
                        <div className="customer-card-img">
                            <img src={cust1Img} alt="customer" />
                            <div className="customer-position">
                                <span>4</span>
                            </div>
                        </div>
                        <h3>Danniel Smith</h3>
                        <p>50.5</p>
                        <div className="progressBar">
                            <div className="progressData">
                                <span>{t("Progress")}</span> <span>55%</span>
                            </div>
                            <div
                                className="progressBarArea bg-gray-200 rounded h-2"
                                role="progressbar"
                            >
                                <div
                                    style={{
                                        width: "55%",
                                        transition: "width 2s",
                                    }}
                                    className="bg-sky-400"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="customer-card bg-orange-50 ">
                        <div className="customer-card-img">
                            <img src={cust2Img} alt="customer" />
                            <div className="customer-position">
                                <span>5</span>
                            </div>
                        </div>
                        <h3>Danniel Smith</h3>
                        <p>50.5</p>
                        <div className="progressBar">
                            <div className="progressData">
                                <span>{t("Progress")}</span> <span>50%</span>
                            </div>
                            <div
                                className="progressBarArea bg-gray-200 rounded h-2"
                                role="progressbar"
                            >
                                <div
                                    style={{
                                        width: "50%",
                                        transition: "width 2s",
                                    }}
                                    className="bg-orange-400"
                                ></div>
                            </div>
                        </div>
                    </div>
                    <div className="customer-card bg-green-50 ">
                        <div className="customer-card-img">
                            <img src={cust3Img} alt="customer" />
                            <div className="customer-position">
                                <span>6</span>
                            </div>
                        </div>
                        <h3>Danniel Smith</h3>
                        <p>50.5</p>
                        <div className="progressBar">
                            <div className="progressData">
                                <span>{t("Progress")}</span> <span>70%</span>
                            </div>
                            <div
                                className="progressBarArea bg-gray-200 rounded h-2"
                                role="progressbar"
                            >
                                <div
                                    style={{
                                        width: "70%",
                                        transition: "width 2s",
                                    }}
                                    className="bg-green-400"
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full mt-8 xl:mt-0 xl:w-6/12 bg-white dark:bg-slate-800 rounded-md">
                <div className="px-6 py-5 border-b border-b-slate-100 flex justify-between items-center">
                    <h3 className="font-Outfit font-normal text-black dark:text-zinc-50 text-xl">
                        {t("All users")}
                    </h3>
                </div>
                <div className="pb-8">
                    <div className="overflow-auto">
                        <UserTable />
                    </div>
                </div>
            </div>
        </div>
    );
}
