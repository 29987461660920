import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Icon } from "@iconify/react";
import { MenuContext } from "../../contexts/MenuContext";

const AccordionNavMenu = ({ menus }) => {
    const menuCtx = useContext(MenuContext);
    const location = useLocation();
    const toggleSubmenu = (i) => {
        if (menuCtx.sidebarActiveSubMenu.i === i) {
            menuCtx.setSidebarActiveSubMenu((prev) => ({ ...prev, i: null }));
        } else {
            menuCtx.setSidebarActiveSubMenu((prev) => ({ ...prev, i: i }));
        }
    };

    const toggleSubSubmenu = (j) => {
        if (menuCtx.sidebarActiveSubMenu.j === j) {
            menuCtx.setSidebarActiveSubMenu((prev) => ({ ...prev, j: null }));
        } else {
            menuCtx.setSidebarActiveSubMenu((prev) => ({ ...prev, j: j }));
        }
    };

    const toggleSingleOption = (i) => {
        menuCtx.setSidebarActiveSubMenu((prev) => ({ ...prev, i: i }));
    };

    function checkActive(item) {
        let result = false;
        result = item.link === location.pathname;
        if (!result) {
            let locationSplitArray = location.pathname.split("/");
            let childLinkSplitArray = item.link.split("/");

            result = childLinkSplitArray[1] === locationSplitArray[1];
        }
        return result;
    }

    return (
        <ul className="text-sm font-medium">
            {menus.map((item, i) => (
                <li key={i} className="relative overflow-hidden">
                    {/*    !!sub menu parent   */}
                    {item.child ? (
                        <>
                            <span
                                className={
                                    "select-none nav-item " +
                                    (menuCtx.sidebarActiveSubMenu.i === i
                                        ? "nav-item-selected"
                                        : "nav-item-default")
                                }
                                onClick={() => toggleSubmenu(i)}
                            >
                                <span className="inline-block">
                                    <Icon
                                        icon={item.icon}
                                        className="text-xl mt-1 transition-all duration-500"
                                    ></Icon>
                                </span>
                                <span className={"nav-item-text"}>
                                    {item.title}
                                </span>
                                <span
                                    className={
                                        "nav-icon " +
                                        (menuCtx.sidebarActiveSubMenu.i === i
                                            ? "nav-icon-selected"
                                            : "nav-icon-default")
                                    }
                                >
                                    <Icon
                                        icon="ep:arrow-down-bold"
                                        className={
                                            "text-sm transition-all duration-500 " +
                                            (menuCtx.sidebarActiveSubMenu.i ===
                                            i
                                                ? "text-white rotate-180"
                                                : "text-textColor dark:text-white")
                                        }
                                    ></Icon>
                                </span>
                            </span>
                            <ul
                                className={`sub-menu  ${
                                    menuCtx.sidebarActiveSubMenu.i === i
                                        ? " opacity-100 visible  max-h-[3000px]"
                                        : " opacity-0 invisible max-h-0"
                                } transition-all duration-300`}
                            >
                                {item.child?.map((subItem, j) => (
                                    <div key={j} className="block">
                                        {subItem.child ? (
                                            <>
                                                <span
                                                    className={
                                                        "cursor-pointer select-none py-2 pr-4 text-slate-600 dark:text-slate-300 text-sm flex space-x-1 items-center transition-all duration-150"
                                                    }
                                                    onClick={() =>
                                                        toggleSubSubmenu(j)
                                                    }
                                                >
                                                    <span className="pr-1">
                                                        <Icon icon="material-symbols:circle-outline" />
                                                    </span>
                                                    <span className="flex-1">
                                                        {subItem.childtitle}
                                                    </span>
                                                    <span
                                                        className={
                                                            "nav-icon " +
                                                            (menuCtx
                                                                .sidebarActiveSubMenu
                                                                .j === j
                                                                ? "nav-icon-selected"
                                                                : "nav-icon-default")
                                                        }
                                                    >
                                                        <Icon
                                                            icon="ep:arrow-down-bold"
                                                            className={
                                                                "text-sm transition-all duration-500 " +
                                                                (menuCtx
                                                                    .sidebarActiveSubMenu
                                                                    .j === j
                                                                    ? "text-white rotate-180"
                                                                    : "text-textColor dark:text-white")
                                                            }
                                                        ></Icon>
                                                    </span>
                                                </span>
                                                <ul
                                                    className={`nested-sub-menu  ${
                                                        menuCtx
                                                            .sidebarActiveSubMenu
                                                            .j === j
                                                            ? " opacity-100 visible  max-h-[3000px]"
                                                            : " opacity-0 invisible max-h-0"
                                                    } transition-all duration-300`}
                                                >
                                                    {subItem.child?.map(
                                                        (subSubItem, k) => (
                                                            <Link
                                                                key={k}
                                                                to={
                                                                    subSubItem.link
                                                                }
                                                            >
                                                                <span
                                                                    className={`py-2 ${
                                                                        checkActive(
                                                                            subSubItem
                                                                        )
                                                                            ? " text-black font-bold dark:text-white"
                                                                            : "text-slate-600 dark:text-slate-300"
                                                                    } text-sm flex space-x-1 items-center transition-all duration-150`}
                                                                >
                                                                    <span className="flex items-center select-none">
                                                                        <span className="pr-2">
                                                                            <Icon icon="material-symbols:crop-square-outline" />
                                                                        </span>
                                                                        {
                                                                            subSubItem.childtitle
                                                                        }
                                                                    </span>
                                                                </span>
                                                            </Link>
                                                        )
                                                    )}
                                                </ul>
                                            </>
                                        ) : (
                                            <Link to={subItem.link}>
                                                <span
                                                    className={`py-2 ${
                                                        checkActive(subItem)
                                                            ? " text-black font-bold dark:text-white"
                                                            : "text-slate-600 dark:text-slate-300"
                                                    } text-sm flex space-x-1 items-center transition-all duration-150`}
                                                >
                                                    <span className="flex items-center select-none">
                                                        <span className="pr-2">
                                                            <Icon icon="material-symbols:circle-outline" />
                                                        </span>
                                                        {subItem.childtitle}
                                                    </span>
                                                </span>
                                            </Link>
                                        )}
                                    </div>
                                ))}
                            </ul>{" "}
                        </>
                    ) : (
                        <Link
                            className={
                                "nav-item " +
                                (menuCtx.sidebarActiveSubMenu.i === i
                                    ? "nav-item-selected"
                                    : "nav-item-default")
                            }
                            onClick={() => toggleSingleOption(i)}
                            to={item.link}
                        >
                            <span className="inline-block">
                                <Icon
                                    icon={item.icon}
                                    className="text-xl mt-1 transition-all duration-500"
                                ></Icon>
                            </span>
                            <span className={"nav-item-text select-none"}>
                                {item.title}
                            </span>
                        </Link>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default AccordionNavMenu;
