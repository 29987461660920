import MainContentFooter from "../../components/Footer/MainContentFooter";

export default function ContentBodyWrapper({children, showFooter}){
    return (<>
            <section className="p-4 md:p-8  flex flex-col justify-between h-[calc(100vh-80px)] overflow-y-scroll">
                <div>
                    {children}
                </div>
                { showFooter && <MainContentFooter/> }
            </section>
    </>);
}