import { createContext, useState } from "react";

export const AuthContext = createContext({
    login_trigger: false,
    setLogin_trigger: () => {},
});

export default function AuthContextProvider({ children }) {
    const [login, setLogin] = useState(false);

    function setLogin_trigger() {
        setLogin(!login);
    }

    const value = {
        login_trigger: login,
        setLogin_trigger: setLogin_trigger,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
}
