import { useContext, useState } from "react";
import reactRouteList from "../../config/react-route-list-config";
import SubmitButtonBlock from "../Buttons/SubmitButtonBlock";
import { useNavigate } from "react-router-dom";
import NoBorderButtonSmall from "../Buttons/NoBorderButtonSmall";
import { Icon } from "@iconify/react";
import { AuthContext } from "../../contexts/AuthContext";
import { storeToken, setLoggedVerified, getTheme } from "../../utils/Storage";
import apiRouteList from "../../config/api-route-list-config";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ValidationError from "../Alerts/ValidationError";
import { toast } from "react-toastify";

export default function LoginForm() {
    const [userData, setUserData] = useState({});
    const loginCtx = useContext(AuthContext);
    const [showPass, setShowPass] = useState(false);
    const [error, setError] = useState({});
    const routes = reactRouteList.guestRoutes;
    const [keepMeLogged, setKeepMeLogged] = useState(false);
    const [processing, setProcessing] = useState(false);

    const [t] = useTranslation();

    const navigate = useNavigate();

    function getInput(event, input) {
        setUserData((prevState) => ({
            ...prevState,
            [input]: event.target.value,
        }));
    }
    const handleKeepMeLogged = (event) => {
        setKeepMeLogged((current) => !current);
    };

    const LoginHandler = async (e) => {
        setProcessing(true);
        //preparing form data
        e.preventDefault();
        const loginFormData = new FormData();
        loginFormData.append("email", userData.email);
        loginFormData.append("password", userData.password);

        //geting backend api
        const loginApi = apiRouteList.guestRoutes.login;

        try {
            await axios({
                method: loginApi.method,
                url: loginApi.endpoint,
                data: loginFormData,
                headers: loginApi.headers,
            })
                .then(function (response) {
                    setProcessing(false);
                    const data = response.data;
                    storeToken(data.data.token, keepMeLogged);
                    if (data.data.is_email_verified === true) {
                        function loginSwitchHandler() {
                            loginCtx.setLogin_trigger();
                            navigate(reactRouteList.loggedRoutes.dashboard);
                            toast.success(data.message, {
                                position: "top-right",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: getTheme() === "dark" ? "dark" : "light",
                            });
                        }

                        let myPromise = new Promise(function (
                            myResolve,
                            myReject
                        ) {
                            try {
                                setLoggedVerified(data);
                                myResolve();
                            } catch {
                                myReject();
                            }
                        });

                        myPromise.then(
                            function (value) {
                                loginSwitchHandler(value);
                            },
                            function (error) {
                                loginSwitchHandler(error);
                            }
                        );
                    } else {
                        navigate(reactRouteList.guestRoutes.emailVerify);
                    }
                })
                .catch(function (error) {
                    setError(error.response.data.errors);
                    setProcessing(false);
                });
        } catch (error) {
            setProcessing(false);
        }
    };

    return (
        <>
            <form onSubmit={(e) => LoginHandler(e)} className="mt-8 md:mt-12">
                <div className="mt-3">
                    <label
                        htmlFor="email"
                        className="font-Outfit font-medium text-sm text-textColor mb-2 inline-block"
                    >
                        {t("Email")}
                    </label>
                    <input
                        onChange={(e) => getInput(e, "email")}
                        type="email"
                        id="email"
                        className="w-full border border-gray-300 bg-[#FBFBFB] py-[10px] px-4 outline-none focus:outline-none focus:ring-0 focus:border-[#000000] shadow-none rounded-md font-Outfit text-base text-black"
                        placeholder={t("Enter your email")}
                        required
                        autoFocus
                    />
                    <ValidationError value={error.email} />
                </div>

                <div className="mt-4 relative">
                    <label
                        htmlFor="password"
                        className="font-Outfit font-medium text-sm text-textColor mb-2 inline-block"
                    >
                        {t("Password")}{" "}
                    </label>
                    <input
                        type={showPass ? "text" : "password"}
                        onChange={(e) => getInput(e, "password")}
                        id="password"
                        className="w-full pr-10 border border-gray-300 bg-[#FBFBFB] py-[10px] px-4 outline-none focus:outline-none focus:ring-0 focus:border-[#000000] shadow-none rounded-md font-Outfit text-base text-black"
                        placeholder={t("Enter your password")}
                        required
                        autoComplete="current-password"
                    />

                    <button
                        type="button"
                        className={`absolute right-3 top-[44px] z-20`}
                        onClick={() => {
                            setShowPass(!showPass);
                        }}
                    >
                        {!showPass && (
                            <Icon
                                className="text-textColor text-xl"
                                icon="akar-icons:eye"
                            ></Icon>
                        )}
                        {showPass && (
                            <Icon
                                className="text-textColor text-xl"
                                icon="ant-design:eye-invisible-outlined"
                            ></Icon>
                        )}
                    </button>
                    <ValidationError value={error.password} />
                </div>
                <div className="flex items-center justify-between mt-4">
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            onChange={handleKeepMeLogged}
                            name="remember"
                            id="remember_me"
                            className="w-3 h-3 mr-2 rounded-sm bg-white border-0 text-black checked:bg-gray-900 checked:ring-1 ring-offset-2 !ring-black !ring-1"
                        />
                        <label
                            htmlFor="remember_me"
                            className="font-Outfit font-light text-textColor text-sm"
                        >
                            {t("Keep me logged in")}{" "}
                        </label>
                    </div>
                    <NoBorderButtonSmall
                        onClick={() => navigate(routes.forgotPassword)}
                    >
                        {t("Forgot your password?")}{" "}
                    </NoBorderButtonSmall>
                </div>
                <SubmitButtonBlock processing={processing}>
                    {t("Sign in")}
                </SubmitButtonBlock>
            </form>
        </>
    );
}
