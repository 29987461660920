// line Chart
export const lineChart = {
    series: [
      {
        data: [15, 30, 15, 30, 20, 35],
      },
    ],
   options: {
    chart: {
        toolbar: {
          show: false,
        },
        offsetX: 0,
        offsetY: 0,
    
        sparkline: {
          enabled: true,
        },
      },
      stroke: {
        width: [2],
        curve: "straight",
        dashArray: [0, 8, 5],
      },
      dataLabels: {
        enabled: false,
      },
    
      markers: {
        size: 4,
        colors: "#fff",
        strokeColors: "#4669FA",
        strokeWidth: 2,
        shape: "circle",
        radius: 2,
        hover: {
          sizeOffset: 1,
        },
      },
    
      yaxis: {
        show: false,
      },
      xaxis: {
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      grid: {
        show: true,
        borderColor: "#E2E8F0",
        strokeDashArray: 5,
        position: "back",
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      colors: ["#4669FA"],
   }
  };