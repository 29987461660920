import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { MenuContext } from "../contexts/MenuContext";
import { getUserData } from "../utils/Storage";

export default function usePermission(permission) {
    const loginCtx = useContext(AuthContext);
    const menuCtx = useContext(MenuContext);
    const [grant, setGrant] = useState(true);
    useEffect(() => {
        try {
            const permissions = getUserData().permissions;

            if (permissions.includes(permission)) {
                setGrant(true);
            } else {
                setGrant(false);
            }
        } catch (error) {
            setGrant(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loginCtx.login_trigger, menuCtx.sidebarMenuItemsChanged]);
    return grant;
}
