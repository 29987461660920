import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import noimageImg from "../../assets/images/no-image.png";
import apiRouteList from "../../config/api-route-list-config";
import { SettingsContext } from "../../contexts/SettingsContext";
import { changeBrowserFavicon } from "../../utils/Browser";
import { getTheme } from "../../utils/Storage";
import ValidationError from "../Alerts/ValidationError";
import SubmitButton from "../Buttons/SubmitButton";
export default function SettingPictureUploader() {
    const [logo, setLogo] = useState(null);
    const [previewLogo, setPreviewLogo] = useState(undefined);

    const [darkLogo, setDarkLogo] = useState(null);
    const [previewDarkLogo, setPreviewDarkLogo] = useState(undefined);

    const [favicon, setFavicon] = useState(null);
    const [previewFavicon, setPreviewFavicon] = useState(undefined);

    const [guestLogo, setGuestLogo] = useState(null);
    const [previewGuestLogo, setPreviewGuestLogo] = useState(undefined);

    const [guestBackground, setGuestBackground] = useState(null);
    const [previewGuestBackground, setPreviewGuestBackground] =
        useState(undefined);

    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState({});

    const settingsCtx = useContext(SettingsContext);

    const [t] = useTranslation();

    function getLogo(event) {
        setLogo(event.target.files[0]);
    }
    function getDarkLogo(event) {
        setDarkLogo(event.target.files[0]);
    }
    function getFavicon(event) {
        setFavicon(event.target.files[0]);
    }

    function getGuestLogo(event) {
        setGuestLogo(event.target.files[0]);
    }

    function getGuestBackground(event) {
        setGuestBackground(event.target.files[0]);
    }

    useEffect(() => {
        if (!logo) {
            setPreviewLogo(undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(logo);
        setPreviewLogo(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [logo]);

    useEffect(() => {
        if (!darkLogo) {
            setPreviewDarkLogo(undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(darkLogo);
        setPreviewDarkLogo(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [darkLogo]);

    useEffect(() => {
        if (!favicon) {
            setPreviewFavicon(undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(favicon);
        setPreviewFavicon(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [favicon]);

    useEffect(() => {
        if (!guestLogo) {
            setPreviewGuestLogo(undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(guestLogo);
        setPreviewGuestLogo(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [guestLogo]);

    useEffect(() => {
        if (!guestBackground) {
            setPreviewGuestBackground(undefined);
            return;
        }
        const objectUrl = URL.createObjectURL(guestBackground);
        setPreviewGuestBackground(objectUrl);
        return () => URL.revokeObjectURL(objectUrl);
    }, [guestBackground]);

    const settingsPictureUploadHandler = async (e) => {
        //preparing form data
        e.preventDefault();
        setProcessing(true);
        const settingImageFormData = new FormData();
        if (logo !== null) {
            settingImageFormData.append("logo", logo);
        }
        if (darkLogo !== null) {
            settingImageFormData.append("dark_logo", darkLogo);
        }
        if (favicon !== null) {
            settingImageFormData.append("favicon", favicon);
        }
        if (guestLogo !== null) {
            settingImageFormData.append("guest_logo", guestLogo);
        }
        if (guestBackground !== null) {
            settingImageFormData.append("guest_background", guestBackground);
        }
        settingImageFormData.append("_method", "PUT");

        //geting backend api
        const settingImageUploadApi =
            apiRouteList.loggedRoutes.generalSettingsUpdate;

        try {
            await axios({
                method: settingImageUploadApi.method,
                url: settingImageUploadApi.endpoint,
                data: settingImageFormData,
                headers: settingImageUploadApi.headers,
            })
                .then(function (response) {
                    setProcessing(false);
                    setError({});
                    toast.success(response.data.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: getTheme() === "dark" ? "dark" : "light",
                    });

                    try {
                        const settingsApi =
                            apiRouteList.guestRoutes.currentGeneraSettings;

                        axios({
                            method: settingsApi.method,
                            url: settingsApi.endpoint,
                            headers: settingsApi.headers,
                        })
                            .then((response) => {
                                const data = response.data.data;
                                localStorage.setItem(
                                    "generalSettings",
                                    JSON.stringify(data)
                                );
                                changeBrowserFavicon(data.favicon);
                                settingsCtx.setGeneralSettings(data);
                            })
                            .catch((error) => {
                                localStorage.setItem(
                                    "generalSettings",
                                    JSON.stringify({})
                                );
                                settingsCtx.setGeneralSettings({});
                            });
                    } catch (error) {
                        localStorage.setItem(
                            "generalSettings",
                            JSON.stringify({})
                        );
                        settingsCtx.setGeneralSettings({});
                    }
                })
                .catch(function (error) {
                    setError(error.response.data.errors);
                    setProcessing(false);
                });
        } catch (error) {
            setProcessing(false);
        }
    };

    return (
        <form
            onSubmit={(e) => settingsPictureUploadHandler(e)}
            className="bg-white dark:bg-slate-800 px-7 py-7 "
        >
            <div className="grid gap-7 sm:grid-cols-2 lg:grid-cols-3 ">
                <div className="imageUploadCard">
                    <div className="imageUploadCardHeader">
                        <h3>{t("Logo")}</h3>
                    </div>
                    <div className="cardBody p-4 text-center space-y-5">
                        <img
                            id="logoPreview"
                            className="mx-auto h-36 w-36 rounded-md"
                            src={
                                previewLogo
                                    ? previewLogo
                                    : settingsCtx.generalSettings.logo
                                    ? settingsCtx.generalSettings.logo
                                    : noimageImg
                            }
                            alt="logo"
                        />
                        <div className="relative">
                            <input
                                type="file"
                                accept="image/*"
                                name="logo"
                                className="defaultButton absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) => getLogo(e)}
                            />
                            <label className="defaultButton">
                                {t("Select")}
                            </label>
                            <ValidationError value={error.logo} />
                        </div>
                    </div>
                </div>
                <div className="imageUploadCard">
                    <div className="imageUploadCardHeader">
                        <h3>{t("Favicon")}</h3>
                    </div>
                    <div className="cardBody p-4 text-center space-y-5">
                        <div className="h-36 w-36 mx-auto rounded-md flex items-center justify-center">
                            <img
                                id="faviconPreview"
                                className="mx-auto h-20 w-20 rounded-md"
                                src={
                                    previewFavicon
                                        ? previewFavicon
                                        : settingsCtx.generalSettings.favicon
                                        ? settingsCtx.generalSettings.favicon
                                        : noimageImg
                                }
                                alt="favicon"
                            />
                        </div>

                        <div className="relative">
                            <input
                                type="file"
                                accept="image/*"
                                name="favicon"
                                className="defaultButton absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) => getFavicon(e)}
                            />
                            <label className="defaultButton">
                                {t("Select")}
                            </label>
                            <ValidationError value={error.favicon} />
                        </div>
                    </div>
                </div>
                <div className="imageUploadCard">
                    <div className="imageUploadCardHeader">
                        <h3>{t("Dark Logo")}</h3>
                    </div>
                    <div className="cardBody p-4 text-center space-y-5">
                        <img
                            id="darkLogoPreview"
                            className="mx-auto h-36 w-36 rounded-md"
                            src={
                                previewDarkLogo
                                    ? previewDarkLogo
                                    : settingsCtx.generalSettings.dark_logo
                                    ? settingsCtx.generalSettings.dark_logo
                                    : noimageImg
                            }
                            alt="dark_logo"
                        />
                        <div className="relative">
                            <input
                                type="file"
                                accept="image/*"
                                name="dark_logo"
                                className="defaultButton absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) => getDarkLogo(e)}
                            />
                            <label className="defaultButton">
                                {t("Select")}
                            </label>
                            <ValidationError value={error.dark_logo} />
                        </div>
                    </div>
                </div>

                <div className="imageUploadCard">
                    <div className="imageUploadCardHeader">
                        <h3>{t("Guest Logo")}</h3>
                    </div>
                    <div className="cardBody p-4 text-center space-y-5">
                        <img
                            id="GuestLogoPreview"
                            className="mx-auto h-36 w-36 rounded-md"
                            src={
                                previewGuestLogo
                                    ? previewGuestLogo
                                    : settingsCtx.generalSettings.guest_logo
                                    ? settingsCtx.generalSettings.guest_logo
                                    : noimageImg
                            }
                            alt="guest_logo"
                        />
                        <div className="relative">
                            <input
                                type="file"
                                accept="image/*"
                                name="guest_logo"
                                className="defaultButton absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) => getGuestLogo(e)}
                            />
                            <label className="defaultButton">
                                {t("Select")}
                            </label>
                            <ValidationError value={error.guest_logo} />
                        </div>
                    </div>
                </div>

                <div className="imageUploadCard">
                    <div className="imageUploadCardHeader">
                        <h3>{t("Guest Background")}</h3>
                    </div>
                    <div className="cardBody p-4 text-center space-y-5">
                        <img
                            id="guestBackgroundPreview"
                            className="mx-auto w-36 h-36 rounded-md"
                            src={
                                previewGuestBackground
                                    ? previewGuestBackground
                                    : settingsCtx.generalSettings
                                          .guest_background
                                    ? settingsCtx.generalSettings
                                          .guest_background
                                    : noimageImg
                            }
                            alt="guest_background"
                        />
                        <div className="relative">
                            <input
                                type="file"
                                accept="image/*"
                                name="guest_background"
                                className="defaultButton absolute top-0 left-0 w-full h-full opacity-0 cursor-pointer"
                                onChange={(e) => getGuestBackground(e)}
                            />
                            <label className="defaultButton">
                                {t("Select")}
                            </label>
                            <ValidationError value={error.guest_background} />
                        </div>
                    </div>
                </div>
            </div>
            <SubmitButton processing={processing}>{t("Save")}</SubmitButton>
        </form>
    );
}
