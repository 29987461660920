import { Routes, Route } from "react-router-dom";
import reactRouteList from "../config/react-route-list-config";
import EmailVerifyPage from "../pages/auth/emailverifypage/EmailVerifyPage";
import ForgotPasswordPage from "../pages/auth/forgotpasswordpage/ForgotPasswordPage";
import LoginPage from "../pages/auth/loginpage/LoginPage";
import RegisterPage from "../pages/auth/registerpage/RegisterPage";
import NotfoundPage from "../pages/error/NotFoundPage/NotfoundPage";

export default function GuestRoutes() {
  const routes = reactRouteList.guestRoutes;
  return (<>
    <Routes>
      <Route path={routes.login} element={<LoginPage />} />
      <Route path={routes.register} element={<RegisterPage />} />
      <Route path={routes.forgotPassword} element={<ForgotPasswordPage />} />
      <Route path={routes.emailVerify} element={<EmailVerifyPage/>}/>
      <Route path={routes.notfound} element={<NotfoundPage />} />
    </Routes>
  </>);
}