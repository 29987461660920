import DashboardLayout from "../../../Layouts/DashboardLayout";
import ContentBodyWrapper from "../../../Layouts/Containers/ContentBodyWrapper";
import { changeBrowserTabTitle } from "../../../utils/Browser";
import { useTranslation } from "react-i18next";
export default function SamplePage() {
    changeBrowserTabTitle("Sample Page");
    const [t] = useTranslation();
    return (
        <>
            <DashboardLayout>
                <ContentBodyWrapper showFooter={true}>
                    <span className="text-black dark:text-white">
                        {t("This is a sample page.")}
                    </span>
                </ContentBodyWrapper>
            </DashboardLayout>
        </>
    );
}
