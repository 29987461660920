import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";

export default function TableDataPreloader({ columnSpan }) {
    const [t] = useTranslation();

    return (
        <td
            className="p-4 h-72 text-black dark:text-white transition-all"
            colSpan={columnSpan}
        >
            <span className="flex justify-center items-center">
                <span className="flex items-center">
                    <Spinner />
                    <span className="pl-1">{t("Loading Table data")}....</span>
                </span>
            </span>
        </td>
    );
}
