import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import SettingsContextProvider from "./contexts/SettingsContext";
import MenuContextProvider from "./contexts/MenuContext";
import Auth from "./auth/Auth";
import AuthContextProvider from "./contexts/AuthContext";
function App() {
    return (
        <MenuContextProvider>
            <SettingsContextProvider>
                <AuthContextProvider>
                    <Auth />
                </AuthContextProvider>
                <ToastContainer />
            </SettingsContextProvider>
        </MenuContextProvider>
    );
}

export default App;
