import { useContext, useState } from "react";
import SubmitButtonBlock from "../Buttons/SubmitButtonBlock";
import { Icon } from "@iconify/react";
import apiRouteList from "../../config/api-route-list-config";
import axios from "axios";
import { storeToken, setLoggedVerified, setLogged } from "../../utils/Storage";
import reactRouteList from "../../config/react-route-list-config";
import { AuthContext } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ValidationError from "../Alerts/ValidationError";

export default function RegistrationForm() {
    const [showPass, setShowPass] = useState(false);
    const [showConfPass, setShowConfPass] = useState(false);
    const [userData, setUserData] = useState({});
    const [error, setError] = useState({});
    const [processing, setProcessing] = useState(false);
    const loginCtx = useContext(AuthContext);
    const navigate = useNavigate();
    const [t] = useTranslation();

    function getInput(event, input) {
        setUserData((prevState) => ({
            ...prevState,
            [input]: event.target.value,
        }));
    }

    const RegHandler = async (e) => {
        //preparing form data
        setProcessing(true);
        e.preventDefault();
        const regFormData = new FormData();
        regFormData.append("name", userData.name);
        regFormData.append("email", userData.email);
        regFormData.append("password", userData.password);
        regFormData.append("password_confirmation", userData.confirm_password);

        //geting backend api
        const regApi = apiRouteList.guestRoutes.register;
        try {
            await axios({
                method: regApi.method,
                url: regApi.endpoint,
                data: regFormData,
                headers: regApi.headers,
            })
                .then(function (response) {
                    setProcessing(false);
                    const data = response.data.data;
                    storeToken(data.token);
                    setUserData(data);

                    if (data.is_email_verified === true) {
                        function loginSwitchHandler() {
                            loginCtx.setLogin_trigger();
                            navigate(reactRouteList.loggedRoutes.dashboard);
                        }

                        let myPromise = new Promise(function (
                            myResolve,
                            myReject
                        ) {
                            try {
                                setLoggedVerified(data);
                                myResolve();
                            } catch {
                                myReject();
                            }
                        });

                        myPromise.then(
                            function (value) {
                                loginSwitchHandler(value);
                            },
                            function (error) {
                                loginSwitchHandler(error);
                            }
                        );
                    } else {
                        navigate(reactRouteList.guestRoutes.emailVerify);
                        setLogged(data);
                    }
                })
                .catch(function (error) {
                    setError(error.response.data.errors);
                    setProcessing(false);
                });
        } catch (error) {
            setProcessing(false);
        }
    };

    return (
        <>
            <form onSubmit={(e) => RegHandler(e)} className="mt-8">
                <div className="mt-3">
                    <label
                        htmlFor="name"
                        className="font-Outfit font-medium text-sm text-textColor mb-2 inline-block"
                    >
                        {t("Name")}
                    </label>
                    <input
                        onChange={(e) => getInput(e, "name")}
                        type="text"
                        name="name"
                        id="name"
                        className="w-full border border-gray-300 bg-[#FBFBFB] py-[10px] px-4 outline-none focus:outline-none focus:ring-0 focus:border-[#000000] shadow-none rounded-md font-Outfit text-base text-black"
                        placeholder={t("Enter your name")}
                        required
                        autoFocus
                    />
                    <ValidationError value={error.name} />
                </div>

                <div className="mt-3">
                    <label
                        htmlFor="email"
                        className="font-Outfit font-medium text-sm text-textColor mb-2 inline-block"
                    >
                        {t("Email")}
                    </label>
                    <input
                        onChange={(e) => getInput(e, "email")}
                        type="email"
                        name="email"
                        id="email"
                        className="w-full border border-gray-300 bg-[#FBFBFB] py-[10px] px-4 outline-none focus:outline-none focus:ring-0 focus:border-[#000000] shadow-none rounded-md font-Outfit text-base text-black"
                        placeholder={t("Enter your email")}
                        required
                        autoFocus
                    />
                    <ValidationError value={error.email} />
                </div>

                <div className="mt-4 relative">
                    <label
                        htmlFor="password"
                        className="font-Outfit font-medium text-sm text-textColor mb-2 inline-block"
                    >
                        {t("Password")}
                    </label>
                    <input
                        type={showPass ? "text" : "password"}
                        onChange={(e) => getInput(e, "password")}
                        name="password"
                        id="password"
                        className="pr-10 w-full border border-gray-300 bg-[#FBFBFB] py-[10px] px-4 outline-none focus:outline-none focus:ring-0 focus:border-[#000000] shadow-none rounded-md font-Outfit text-base text-black"
                        placeholder={t("Enter your password")}
                        required
                        autoComplete="current-password"
                    />

                    <button
                        type="button"
                        className={`absolute right-3 top-[44px] z-20`}
                        onClick={() => {
                            setShowPass(!showPass);
                        }}
                    >
                        {!showPass && (
                            <Icon
                                className="text-textColor text-xl"
                                icon="akar-icons:eye"
                            ></Icon>
                        )}
                        {showPass && (
                            <Icon
                                className="text-textColor text-xl"
                                icon="ant-design:eye-invisible-outlined"
                            ></Icon>
                        )}
                    </button>
                    <ValidationError value={error.password} />
                </div>

                <div className="mt-4 relative">
                    <label
                        htmlFor="password_confirmation"
                        className="font-Outfit font-medium text-sm text-textColor mb-2 inline-block"
                    >
                        {t("Confirm Password")}
                    </label>
                    <input
                        onChange={(e) => getInput(e, "confirm_password")}
                        type={showConfPass ? "text" : "password"}
                        name="password_confirmation"
                        id="password_confirmation"
                        className="w-full pr-10 border border-gray-300 bg-[#FBFBFB] py-[10px] px-4 outline-none focus:outline-none focus:ring-0 focus:border-[#000000] shadow-none rounded-md font-Outfit text-base text-black"
                        placeholder={t("Confirm your password")}
                        required
                        autoComplete="confirm-password"
                    />
                    <button
                        type="button"
                        className={`absolute right-3 top-[44px] z-20`}
                        onClick={() => {
                            setShowConfPass(!showConfPass);
                        }}
                    >
                        {!showConfPass && (
                            <Icon
                                className="text-textColor text-xl"
                                icon="akar-icons:eye"
                            ></Icon>
                        )}
                        {showConfPass && (
                            <Icon
                                className="text-textColor text-xl"
                                icon="ant-design:eye-invisible-outlined"
                            ></Icon>
                        )}
                    </button>
                    <ValidationError value={error.password_confirmation} />
                </div>

                <div className="flex items-center justify-between mt-4">
                    <div className="">
                        <input
                            type="checkbox"
                            required={true}
                            name="terms-condiotion"
                            id="checkbox"
                            className="mr-2 w-3 h-3 rounded-sm bg-white border-0 text-black checked:bg-gray-900 checked:ring-1 ring-offset-2 !ring-black !ring-1"
                        />
                        <label
                            htmlFor="checkbox"
                            className="font-Outfit font-light text-textColor text-sm"
                        >
                            {t(
                                "You accept our Terms and Conditions and Privacy Policy"
                            )}
                        </label>
                    </div>
                </div>
                <SubmitButtonBlock processing={processing}>
                    {t("Create an Account")}
                </SubmitButtonBlock>
            </form>
        </>
    );
}
