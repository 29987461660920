import { Icon } from "@iconify/react";
import React, { useContext, useState } from "react";
import { RolePermissionContext } from "../../contexts/RolePermissionContext";
export const CreateRolePermissionCard = ({ title, permissionList }) => {
    const [cardOpen, setCardOpen] = useState(true);
    const rolePermissionCtx = useContext(RolePermissionContext);

    function getPermissions(id) {
        let rolePermissionArrTemp = rolePermissionCtx.permissionArr;

        if (rolePermissionArrTemp.includes(id)) {
            var filtered = rolePermissionArrTemp.filter((value) => {
                return value !== id;
            });
            rolePermissionCtx.setPermissionArr(filtered);
        } else {
            rolePermissionArrTemp.push(id);
            rolePermissionCtx.setPermissionArr(rolePermissionArrTemp);
        }
    }

    return (
        <>
            <div className=" rolePermission">
                <div className="permissionCardHead flex justify-between">
                    <h4 className="permissionCardTitle capitalize">{title}</h4>
                    <button
                        type="button"
                        onClick={() => setCardOpen(!cardOpen)}
                    >
                        <Icon
                            className="permissionCardIcon text-xl"
                            icon={
                                !cardOpen ? "ph:plus-circle" : "ph:minus-circle"
                            }
                        />
                    </button>
                </div>
                <ul className={cardOpen ? "py-5 h-auto" : "py-0 h-0"}>
                    {permissionList.length !== 1 ? (
                        permissionList.map((item, index) => {
                            return (
                                <li key={index} className="permissionCardList">
                                    <div className="flex items-center justify-between gap-x-3">
                                        <label
                                            htmlFor="checkbox1"
                                            className="inputText"
                                        >
                                            {item.name}
                                        </label>
                                        <input
                                            onChange={() => {
                                                getPermissions(item.id);
                                            }}
                                            type="checkbox"
                                            id="checkbox1"
                                            className={"checkbox-style"}
                                        />
                                    </div>
                                </li>
                            );
                        })
                    ) : (
                        <li className="permissionCardList singlePermissionCardList">
                            <div className="flex items-center justify-between gap-x-3">
                                <label
                                    htmlFor="checkbox1"
                                    className="inputText"
                                >
                                    {permissionList[0].name}
                                </label>
                                <input
                                    onChange={() => {
                                        getPermissions(permissionList[0].id);
                                    }}
                                    type="checkbox"
                                    id="checkbox1"
                                    className={"checkbox-style"}
                                />
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
};

export default CreateRolePermissionCard;
