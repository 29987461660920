import { getUserData } from "./Storage";

export function permissionCheck(permission){
    const permissions = getUserData().permissions;
    if (permissions.includes(permission)) {
        return true;
    } else {
        return false;
    }
}

