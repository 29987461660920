export default function TableColMini({
    children,
    sticky,
    header,
}) {

    let str = header ? "table-head " : "table-cell ";
    if (sticky) {
        str += " sticky left-0 ";
    }


    return (
        <th className={str}>
            <div
                className="flex items-center cursor-pointer select-none"
            >
                <span>{children}</span>
            </div>
        </th>
    );
}
