import { Icon } from "@iconify/react";
import Echart from "../Charts/Echart";

export default function EcommerceCard({ chartData, icon, data, title }) {
    return (
        <div className="bg-white  dark:bg-slate-700 rounded-md px-5 py-4 ">
            <div className="pl-14 relative">
                <div className="w-10 h-10 rounded-full bg-sky-100 text-sky-800 text-base flex items-center justify-center absolute left-0 top-2">
                    <Icon icon={icon} />
                </div>
                <h4 className="font-Outfit font-normal text-sm text-textColor dark:text-zinc-200 pb-1">
                    {title}
                </h4>
                <p className="font-Outfit text-xl text-black dark:text-zinc-50 font-medium">
                    {data}
                </p>
            </div>
            <div className="ml-auto w-24">
                <Echart data={chartData} />
            </div>
        </div>
    );
}
