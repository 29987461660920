import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/AuthContext";
import { forgetLogged, getToken } from "../../utils/Storage";

export default function NoTokenLogout() {
    const navigate = useNavigate();
    const loginCtx = useContext(AuthContext);
    useEffect(() => {
        if (getToken() === null || getToken() === undefined) {
            navigate("/");
            forgetLogged();
            loginCtx.setLogin_trigger();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
