import DashboardLayout from "../../../../Layouts/DashboardLayout";
import ContentBodyWrapper from "../../../../Layouts/Containers/ContentBodyWrapper";
import ContentBodyHeader from "../../../../Layouts/Containers/ContentBodyHeader";
import reactRouteList from "../../../../config/react-route-list-config";
import { useParams } from "react-router-dom";
import EditPermissionForm from "../../../../components/Forms/EditPermissionForm";
import { useTranslation } from "react-i18next";
import { changeBrowserTabTitle } from "../../../../utils/Browser";
import ButtonBlack from "../../../../components/Buttons/ButtonBlack";

export default function PermissionEditPage() {
    let { id } = useParams();
    changeBrowserTabTitle("Edit Permission");

    const breadCrumbLink =
        reactRouteList.loggedRoutes.edit_permission.link + "/" + id;
    const breadCrumbItem = id;
    const [t] = useTranslation();

    const buttons = (
        <ButtonBlack
            to={reactRouteList.loggedRoutes.permission_list}
            icon={"material-symbols:arrow-back"}
        >
            {t("Back")}
        </ButtonBlack>
    );

    return (
        <>
            <DashboardLayout>
                <ContentBodyWrapper showFooter={true}>
                    <ContentBodyHeader
                        info={{
                            title: t("Edit Permission"),
                        }}
                        breadcrumb={[
                            {
                                linkitem: t("Permissions"),
                                link: reactRouteList.loggedRoutes
                                    .permission_list,
                            },
                            {
                                linkitem: t("Edit"),
                                link: breadCrumbLink,
                            },
                            { linkitem: breadCrumbItem, link: breadCrumbLink },
                        ]}
                        buttons={buttons}
                    />
                    <EditPermissionForm />
                </ContentBodyWrapper>
            </DashboardLayout>
        </>
    );
}
