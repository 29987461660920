import { MenuContext } from "../../contexts/MenuContext";
import { getTheme } from "../../utils/Storage";
import { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import ChartPreloader from "../Preloader/ChartPreloader";

export default function RadialChart({ data }) {
    const menuCtx = useContext(MenuContext);
    const [loading, setLoading] = useState(true);

    const [chartData, setChartData] = useState(data);
    useEffect(() => {
        if (getTheme() === "dark") {
            let dataTemp = chartData;
            dataTemp.options.chart.background = "transparent";
            dataTemp.options.theme = {
                mode: "dark",
            };
            setChartData(dataTemp);
            setLoading(true);
        } else {
            let dataTemp = chartData;
            dataTemp.options.theme = {
                mode: "light",
            };
            setChartData(dataTemp);
            setLoading(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuCtx.dark]);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 300);
    }, [loading]);

    return !loading ? (
        <Chart
            options={chartData.options}
            series={chartData.series}
            height={350}
            type="radialBar"
        />
    ) : (
        <ChartPreloader />
    );
}
