export default function TableCol({
    children,
    sticky,
}) {

    let str = "table-cell ";
    if (sticky) {
        str += " sticky left-0 ";
    }

    return (
        <th className={str}>
            <div
                className="flex items-center cursor-pointer select-none"
            >
                <span>{children}</span>
            </div>
        </th>
    );
}
