const loggedRoutes = {
    //basic routes
    home: "/",
    dashboard: "/",
    dashboard_analytics: "/analytic-dashboard",
    dashboard_ecommerce: "/ecommerce-dashboard",
    profile: "/profile",
    settings: "/settings",
    single_link_menu: "/single-link-menu-page",
    nested_menu_link_1_1: "/sample-page-1.1",
    nested_menu_link_1_2: "/sample-page-1.2",
    nested_menu_link_2: "/sample-page-2",
    database_backup: "/backup/database",

    //list related
    user_list: "/users",
    role_list: "/roles",
    permission_list: "/permissions",

    //create
    create_users: "/users/create",
    create_roles: "/roles/create",
    create_permissions: "/permissions/create",

    //show by id
    show_user: {
        link: "/users",
        param: {
            id: "/:id",
        },
    },
    show_role: {
        link: "/roles",
        param: {
            id: "/:id",
        },
    },
    show_permission: {
        link: "/permissions",
        param: {
            id: "/:id",
        },
    },

    //edit related
    edit_user: {
        link: "/users/edit",
        param: {
            id: "/:id",
        },
    },
    edit_role: {
        link: "/roles/edit",
        param: {
            id: "/:id",
        },
    },
    edit_permission: {
        link: "/permissions/edit",
        param: {
            id: "/:id",
        },
    },
};

export default loggedRoutes;
