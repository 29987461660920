import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import reactRouteList from "../../config/react-route-list-config";
import NoBorderButton from "../Buttons/NoBorderButton";
import FormTitleDescription from "../Texts/FormTitleDescription";
import ForgotPasswordForm from "./ForgotPasswordForm";
import FormFooter from "./FormFooter";
export default function ForgotPassword() {
    const routes = reactRouteList.guestRoutes;
    const navigate = useNavigate();
    const [t] = useTranslation();
    return (
        <>
            <div className="w-full px-4 sms:px-0 sm:w-[450px]">
                <FormTitleDescription
                    title={t("Forgot password")}
                    description={t("Reset Password with Dashcode.")}
                ></FormTitleDescription>
                <ForgotPasswordForm />
                <div className="mt-8">
                    <FormFooter>
                        {t("Forget it, send me back to")}{" "}
                        <NoBorderButton onClick={() => navigate(routes.login)}>
                            {t("Sign in")}
                        </NoBorderButton>
                    </FormFooter>
                </div>
            </div>
        </>
    );
}
