import guestRoutes from "./reactRouteLists/guestRoutePathList";
import loggedRoutes from "./reactRouteLists/loggedRoutePathList";

const reactRouteList = {
    home: "/",
    notfound: "*",
    guestRoutes: guestRoutes,
    loggedRoutes: loggedRoutes
}

export default reactRouteList;