import AChart from "../Charts/AChart";

export default function AnalyticCard({chartdata,data, title}) {
    return (
        <div className="bg-white dark:bg-slate-800 rounded-md px-5 py-4 ">
            <div className="bg-sky-100  dark:bg-slate-700 w-full flex items-center h-full py-3 px-5 rounded-md">
                <AChart data={chartdata}/>
                <div className="ml-5">
                    <h4 className="font-Outfit font-medium text-base text-textColor dark:text-zinc-200">{title}</h4>
                    <p className="font-Outfit text-xl text-black dark:text-zinc-50 font-medium">{data}</p>
                </div>
            </div>
        </div>
    );
}