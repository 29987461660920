import { useTranslation } from "react-i18next";

export default function AnalyticCardBG() {
    const [t] = useTranslation();
    return (
        <div className="dasboardCard bg-white rounded-md px-5 py-4 flex items-center justify-between bg-hero-pattern bg-center bg-cover bg-no-repeat">
            <div className="w-48 ">
                <h3 className="font-Outfit font-medium text-xl pb-2">
                    {t("Welcome Your DashKit")}
                </h3>
                <p className="font-outfit text-xs text-textColor font-medium">
                    {t("Pro Plan For Better Service")}
                </p>
            </div>
            <span className="w-12 h-12 rounded-full bg-white text-textColor flex items-center justify-center font-medium text-xs truncate ">
                {t("Now")}
            </span>
        </div>
    );
}
