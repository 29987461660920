import { useTranslation } from "react-i18next";
import RadialBarChart from "../../../components/Charts/RadialBarChart";
import { radialBarChart } from "../../../data/radialbarchart";
import { permissionCheck } from "../../../utils/permission";
import UserTable from "./UserTable";

export default function TableAndChartSection() {
    const [t] = useTranslation();
    return (
        <div className="md:flex justify-between gap-8">
            {permissionCheck("menu users_list") && (
                <div className="md:w-8/12 bg-white dark:bg-slate-800 rounded-md">
                    <h3 className="px-6 py-5 font-Outfit font-normal text-black dark:text-zinc-50 text-xl border-b border-b-slate-100">
                        {t("All users")}
                    </h3>
                    <div className="pb-8">
                        <div className="overflow-auto">
                            <UserTable />
                        </div>
                    </div>
                </div>
            )}

            <div className="mt-8 md:mt-0 md:w-4/12 bg-white dark:bg-slate-800 rounded-md">
                <h3 className="px-6 py-5 font-Outfit font-normal text-black dark:text-zinc-50 text-xl border-b border-b-slate-100">
                    {t("Overview")}
                </h3>
                <div className="overflow-hidden px-6 py-2 flex items-center justify-center">
                    <div>
                        <RadialBarChart data={radialBarChart} />
                    </div>
                </div>
                <div className="px-6 bg-slate-50 dark:bg-slate-600 rounded-b-md py-10 grid grid-cols-3 gap-4">
                    <div className="space-y-1 font-Outfit font-normal text-slate-700 dark:text-zinc-200 text-xs">
                        <h6>{t("Invested Amount")}</h6>
                        <h3 className="text-sm font-medium text-slate-800 dark:text-zinc-50">
                            $4520.53
                        </h3>
                        <h5>+0.00123 (0.2%)</h5>
                    </div>
                    <div className="space-y-1 font-Outfit font-normal text-slate-700 dark:text-zinc-200 text-xs">
                        <h6>{t("Invested Amount")}</h6>
                        <h3 className="text-sm font-medium text-slate-800  dark:text-zinc-50">
                            $4520.53
                        </h3>
                        <h5>+0.00123 (0.2%)</h5>
                    </div>
                    <div className="space-y-1 font-Outfit font-normal text-slate-700 dark:text-zinc-200 text-xs">
                        <h6>{t("Invested Amount")}</h6>
                        <h3 className="text-sm font-medium text-slate-800  dark:text-zinc-50">
                            $4520.53
                        </h3>
                        <h5>+0.00123 (0.2%)</h5>
                    </div>
                </div>
            </div>
        </div>
    );
}
