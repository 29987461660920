// line Chart
export const columnChart = {
  series: [
    {
      name: "Net Profit",
      data: [44, 55, 57, 56, 61, 58],
    },
  ],
  options:{
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      offsetX: 0,
      offsetY: 0,
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
  
    plotOptions: {
      bar: {
        columnWidth: "50%",
        barHeight: "100%",
      },
    },
    legend: {
      show: false,
    },
  
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 2,
    },
  
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + "k";
        },
      },
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      show: false,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    colors: "#EDB899",
    grid: {
      show: false,
    },
  }
};