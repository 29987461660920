import { useTranslation } from "react-i18next";
import BarChart from "../../../components/Charts/BarChart";
import CurveStatisticsChart from "../../../components/Charts/CurveStatisticsChart";
import DonutChart from "../../../components/Charts/DonutChart";
import LineSatictsChart from "../../../components/Charts/LineSatictsChart";
import { barChartOne } from "../../../data/chartdata";
import { donutChart } from "../../../data/donutchart";
import { columnChart } from "../../../data/satisticchart2";
import { lineChart } from "../../../data/satisticcharts";

export default function ChartSection() {
    const [t] = useTranslation();
    return (
        <div className="md:flex gap-8 justify-between">
            <div className="md:w-8/12  bg-white dark:bg-slate-800 overflow-hidden p-6 rounded-md">
                <div>
                    <BarChart data={barChartOne} />
                </div>
            </div>


            <div className="mt-8 xl:mt-0 xl:w-4/12 bg-white dark:bg-slate-800 rounded-md w-full">
                <h3 className="px-6 py-5 font-Outfit font-normal text-black dark:text-zinc-50 text-xl border-b border-b-slate-100">
                    {t('Statistics')}
                </h3>
                <div className="overflow-hidden px-6 py-5 grid md:grid-cols-2 gap-4">
                    <div className="statisticsChartCard dark:bg-slate-700">
                        <div>
                            <h5>{t('Orders')}</h5>
                            <h3>150k+</h3>
                            <p>
                                <span className="text-red-400">-60% </span> 
                                {t('From last Week')}
                            </p>
                        </div>
                        <div className="mt-1">
                            <CurveStatisticsChart data={columnChart} />
                        </div>
                        
                    </div>
                    <div className="statisticsChartCard ">
                        <div>
                            <h5>{t('Profit')}</h5>
                            <h3>650k+</h3>
                            <p>
                                <span className="text-indigo-400">0.02% </span> 
                                {t('From last Week')}
                            </p>
                        </div>
                        <div className="mt-1">
                            <LineSatictsChart data={lineChart} />
                        </div>
                    </div>

                <div className="statisticsChartCard py-4 md:col-span-2 md:flex items-center justify-between">
                    <div>
                        <h5>{t('Profit')}</h5>
                        <h3>650k+</h3>
                        <p>
                            <span className="text-indigo-400">0.02% </span>
                            {t('From last Week')}
                        </p>
                    </div>
                    <DonutChart data={donutChart} />
                </div>
                </div>
            </div>
        </div>
    );
}
