import Moment from "react-moment";
import "moment/locale/fr";
import "moment/locale/de";

import { getCurrentLanguage } from "./Storage";
export function dateToYears(from_date) {
    try {
        return (
            <Moment locale={getCurrentLanguage().code} fromNow>
                {from_date}
            </Moment>
        );
    } catch {
        return "Undefined";
    }
}

export function formatedDate(from_date) {
    try {
        return <Moment locale={getCurrentLanguage().code} format="MMM DD, YYYY">{from_date}</Moment>;
    } catch {
        return "Undefined";
    }
}
