import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import apiRouteList from "../../config/api-route-list-config";
import { getTheme } from "../../utils/Storage";
import SubmitButton from "../Buttons/SubmitButton";
import FormTitleDescription from "../Texts/FormTitleDescription";
export default function VerifyEmail() {
    const [processing, setProcessing] = useState(false);
    const [t] = useTranslation();

    const resendVerification = async (e) => {
        e.preventDefault();
        setProcessing(true);
        try {
            const dbApi = apiRouteList.loggedRoutes.resendVerification;
            await axios({
                method: dbApi.method,
                url: dbApi.endpoint,
                headers: dbApi.headers,
            })
                .then(({ data }) => {
                    toast.success(data.message, {
                        position: "top-right",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: getTheme() === "dark" ? "dark" : "light",
                    });
                    setProcessing(false);
                })
                .catch((error) => {
                    setProcessing(false);
                });
        } catch (error) {
            toast.error("Error!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: getTheme() === "dark" ? "dark" : "light",
            });
            setProcessing(false);
        }
    };

    return (
        <>
            <div className="w-full px-4 sms:px-0 sm:w-[450px]">
                <FormTitleDescription
                    title={t("Verify Email")}
                    description={t(
                        "A verification link has been sent to your email. After successful verification please login from the login page."
                    )}
                ></FormTitleDescription>

                <div className="flex justify-center ">
                    <form onSubmit={(e) => resendVerification(e)} className="">
                        <SubmitButton processing={processing}>
                            {t("Resend Verification")}
                        </SubmitButton>
                    </form>
                </div>
            </div>
        </>
    );
}
