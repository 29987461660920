import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { MenuContext } from "../../contexts/MenuContext";
import { accordionMenuItems } from "../../data/accordionmenu";
import useScreen from "../../hooks/useScreen";
import { permissionCheck } from "../../utils/permission";
import SidebarLogo from "../Logo/SidebarLogo";
import AccordionNavMenu from "../Menu/AccordionNavMenu";

export default function SideBar() {
    const menuCtx = useContext(MenuContext);
    const location = useLocation();
    const screen = useScreen();
    const [t] = useTranslation();

    function checkActive(item) {
        let result = false;
        result = item.link === location.pathname;
        if (!result) {
            let locationSplitArray = location.pathname.split("/");
            let childLinkSplitArray = item.link.split("/");

            result = childLinkSplitArray[1] === locationSplitArray[1];
        }
        return result;
    }

    function permissionCheckMenu(accordionMenuItems) {
        //for making the menu
        let menu = [];

        // level 0 permission check
        accordionMenuItems.forEach((item, i) => {
            if (permissionCheck(item.permission) || item.permission === null) {
                let tempItem = item;
                tempItem.title = t(item.title);

                //check child for item
                if (tempItem.child !== undefined) {
                    let submenu = [];
                    // level 1 permission check
                    tempItem.child.forEach((subItem, j) => {
                        if (
                            permissionCheck(subItem.permission) ||
                            subItem.permission === null
                        ) {
                            let tempSubItem = subItem;
                            tempSubItem.childtitle = t(subItem.childtitle);

                            //check child for child  for item
                            if (tempSubItem.child !== undefined) {
                                let subsubmenu = [];
                                // level 2 permission check
                                tempSubItem.child.forEach((subSubItem, k) => {
                                    if (
                                        permissionCheck(
                                            subSubItem.permission
                                        ) ||
                                        subSubItem.permission === null
                                    ) {
                                        let tempSubSubItem = subSubItem;
                                        tempSubSubItem.childtitle = t(
                                            subSubItem.childtitle
                                        );
                                        //pushing sub sub item
                                        subsubmenu.push(tempSubSubItem);
                                    }
                                });
                                //adding sub sub menu
                                tempSubItem.child = subsubmenu;
                            }

                            //pushing sub item
                            submenu.push(tempSubItem);
                        }
                    });
                    //adding sub menu
                    tempItem.child = submenu;
                }

                //adding item to menu
                menu.push(tempItem);
            }
        });

        return menu.length > 0 ? menu : [];
    }

    useEffect(() => {
        //to set the current active submenu and subsubmenu
        accordionMenuItems.forEach((item, index_i) => {
            if (item.child !== undefined) {
                item.child.forEach((subItem, j) => {
                    if (subItem.child !== undefined) {
                        subItem.child.forEach((subSubItem, k) => {
                            if (checkActive(subSubItem)) {
                                menuCtx.setSidebarActiveSubMenu(() => ({
                                    j: j,
                                    i: index_i,
                                }));
                            }
                        });
                    } else {
                        if (checkActive(subItem)) {
                            menuCtx.setSidebarActiveSubMenu(() => ({
                                j: null,
                                i: index_i,
                            }));
                        }
                    }
                });
            } else {
                if (checkActive(item)) {
                    menuCtx.setSidebarActiveSubMenu(() => ({
                        j: null,
                        i: index_i,
                    }));
                }
            }
        });

        function accordionHandler() {
            menuCtx.setSidebarMenuItems([]);
            let menu = [];
            menu = permissionCheckMenu(accordionMenuItems);
            menuCtx.setSidebarMenuItems(menu);
        }
        accordionHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menuCtx.sidebarMenuItemsChanged]);

    useEffect(() => {
        if (screen.innerWidth > 766) {
            menuCtx.setSidebarMenuParam(false);
        } else {
            menuCtx.setSidebarMenuParam(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen.innerWidth]);

    return (
        <>
            <nav
                className={
                    "sidebarNav " +
                    (menuCtx.sidebarMenu ? "menu-collapse" : "menu-expand")
                }
            >
                <SidebarLogo />
                <div className="px-4 py-6 h-screen border-r border-r-gray-200 dark:border-r-slate-900 overflow-y-auto styled-scrollbars">
                    <AccordionNavMenu menus={menuCtx.sidebarMenuItems} />
                </div>
            </nav>
        </>
    );
}
