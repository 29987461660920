import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsContext } from '../../contexts/SettingsContext';
export default function GuestPageText() {
    const settingsCtx = useContext(SettingsContext);
    const [t] = useTranslation();
    return <>
            <h1 className="font-Outfit font-normal text-5xl text-[#425466] pt-8 leading-[65px]">{t('Unlock your')} <br />{t('project')} <span
                className="text-[#111112] font-bold">{t('performance')}</span></h1>
            <img className="w-full" src={settingsCtx.generalSettings.guest_background} alt="" />
    </>
}