import { useContext } from "react";
import { SettingsContext } from "../../contexts/SettingsContext";
export default function LogoAndTitle({ className }) {
    const settingsCtx = useContext(SettingsContext);
    return (
        <>
            <a href="/">
                <img
                    src={settingsCtx.generalSettings.guest_logo}
                    alt="logo"
                    className={"inline-block h-[55px] " + className}
                />
            </a>
        </>
    );
}
