import { useTranslation } from "react-i18next";
import ButtonBlack from "../../../components/Buttons/ButtonBlack";
import reactRouteList from "../../../config/react-route-list-config";
import CenteredLayout from "../../../Layouts/CenteredLayout";
import { changeBrowserTabTitle } from "../../../utils/Browser";
import errorImage404 from "../../../assets/images/404.svg";
export default function NotfoundPage() {
    changeBrowserTabTitle("Error 404! Page not found!");
    const [t] = useTranslation();
    return (
        <CenteredLayout>
            <div className="lg:w-3/12 md:w-6/12 sm:w-full">
                {" "}
                <img
                    src={errorImage404}
                    alt="404 error"
                    className="w-full mb-6"
                />
                <p className="text-center text-3xl m-2 block">
                    <b>{t("Not Found.")}</b>
                </p>
                <p className="text-center mt-4 mb-7">
                    {t(
                        "The page you are looking for might have been removed had its name changed or is temporarily unavailable."
                    )}
                </p>
                <p className="text-center mt-4 mb-7">
                    <ButtonBlack to={reactRouteList.home}>
                        {t("Go To Homepage")}
                    </ButtonBlack>
                </p>
            </div>
        </CenteredLayout>
    );
}
