export function processSortParams(sortParams){
    let sort="";
    for (const property in sortParams) {
        if(sortParams[property]===true){
            sort+=property+",";
        }else{
            sort+='-'+property+",";
        }
    }
    return {
        sort: sort.substring(0,sort.length-1)
    }
}