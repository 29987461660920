import DashboardLayout from "../../../../Layouts/DashboardLayout";
import ContentBodyWrapper from "../../../../Layouts/Containers/ContentBodyWrapper";
import ContentBodyHeader from "../../../../Layouts/Containers/ContentBodyHeader";
import { useParams } from "react-router-dom";
import reactRouteList from "../../../../config/react-route-list-config";
import ShowRole from "./ShowRole";
import { useTranslation } from "react-i18next";
import { changeBrowserTabTitle } from "../../../../utils/Browser";
import ButtonBlack from "../../../../components/Buttons/ButtonBlack";

export default function RoleShowPage() {
    let { id } = useParams();
    changeBrowserTabTitle("View Role");

    const [t] = useTranslation();

    const breadCrumbLink =
        reactRouteList.loggedRoutes.show_role.link + "/" + id;
    const breadCrumbItem = id;

    const buttons = (
        <ButtonBlack
            to={reactRouteList.loggedRoutes.role_list}
            icon={"material-symbols:arrow-back"}
        >
            {t("Back")}
        </ButtonBlack>
    );

    return (
        <>
            <DashboardLayout>
                <ContentBodyWrapper showFooter={true}>
                    <ContentBodyHeader
                        info={{
                            title: t("Roles"),
                        }}
                        breadcrumb={[
                            {
                                linkitem: t("Roles"),
                                link: reactRouteList.loggedRoutes.role_list,
                            },
                            { linkitem: breadCrumbItem, link: breadCrumbLink },
                        ]}
                        buttons={buttons}
                    />
                    <ShowRole id={id} />
                </ContentBodyWrapper>
            </DashboardLayout>
        </>
    );
}
