import { Icon } from "@iconify/react";
import { useContext, useState } from "react";
import { RolePermissionContext } from "../../contexts/RolePermissionContext";
export default function RolePermissionCard({
    title,
    permissionList,
    disabledChecked,
    permittedId,
}) {
    const [cardOpen, setCardOpen] = useState(true);
    const rolePermissionCtx = useContext(RolePermissionContext);
    function getPermissions(e, id) {
        let rolePermissionArrTemp = rolePermissionCtx.permissionArr;
        if (rolePermissionArrTemp.includes(id)) {
            var filtered = rolePermissionArrTemp.filter((value) => {
                return value !== id;
            });
            rolePermissionCtx.setPermissionArr(filtered);
        } else {
            rolePermissionArrTemp.push(id);
            rolePermissionCtx.setPermissionArr(rolePermissionArrTemp);
        }
    }

    return (
        <>
            <div className=" rolePermission">
                <div className="permissionCardHead flex justify-between">
                    <h4 className="permissionCardTitle capitalize">{title}</h4>
                    <button
                        type="button"
                        onClick={() => setCardOpen(!cardOpen)}
                    >
                        <Icon
                            className="permissionCardIcon text-xl"
                            icon={
                                !cardOpen ? "ph:plus-circle" : "ph:minus-circle"
                            }
                        />
                    </button>
                </div>
                <ul className={cardOpen ? "py-5 h-auto" : "py-0 h-0"}>
                    {permissionList.length !== 1 ? (
                        permissionList.map((item, index) => {
                            return (
                                <li key={index} className="permissionCardList">
                                    <div className="flex items-center justify-between gap-x-3">
                                        <label
                                            htmlFor="checkbox1"
                                            className="inputText"
                                        >
                                            {item.name}
                                        </label>
                                        <input
                                            onChange={(e) => {
                                                getPermissions(e, item.id);
                                            }}
                                            disabled={disabledChecked}
                                            defaultChecked={permittedId.includes(
                                                item.id
                                            )}
                                            type="checkbox"
                                            id="checkbox1"
                                            className={
                                                "checkbox-style" +
                                                (disabledChecked
                                                    ? " disabled:opacity-50"
                                                    : "")
                                            }
                                        />
                                    </div>
                                </li>
                            );
                        })
                    ) : (
                        <li className="permissionCardList singlePermissionCardList">
                            <div className="flex items-center justify-between gap-x-3">
                                <label
                                    htmlFor="checkbox1"
                                    className="inputText"
                                >
                                    {permissionList[0].name}
                                </label>
                                <input
                                    onChange={(e) => {
                                        getPermissions(e, permissionList[0].id);
                                    }}
                                    disabled={disabledChecked}
                                    defaultChecked={permittedId.includes(
                                        permissionList[0].id
                                    )}
                                    type="checkbox"
                                    id="checkbox1"
                                    className={
                                        "checkbox-style" +
                                        (disabledChecked
                                            ? " disabled:opacity-50"
                                            : "")
                                    }
                                />
                            </div>
                        </li>
                    )}
                </ul>
            </div>
        </>
    );
}
