import { createContext, useState } from "react";

export const SettingsContext = createContext({
    generalSettings: {},
    setGeneralSettings: (data) => {},
});

export default function SettingsContextProvider({ children }) {
    const [generalSettings, setGeneralSettings] = useState(
        JSON.parse(localStorage.getItem("generalSettings"))
            ? JSON.parse(localStorage.getItem("generalSettings"))
            : {}
    );

    function setGeneralSettingsHandler(data) {
        setGeneralSettings(data);
    }

    const value = {
        generalSettings: generalSettings,
        setGeneralSettings: setGeneralSettingsHandler,
    };

    return (
        <SettingsContext.Provider value={value}>
            {children}
        </SettingsContext.Provider>
    );
}
