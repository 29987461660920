import DashboardLayout from "../../../Layouts/DashboardLayout";
import ContentBodyWrapper from "../../../Layouts/Containers/ContentBodyWrapper";
import SettingsCard from "../../../components/Cards/SettingsCard";

import SettingPictureUploader from "../../../components/Forms/SettingPictureUploader";
import { useEffect, useState } from "react";
import apiRouteList from "../../../config/api-route-list-config";
import axios from "axios";
import { changeBrowserTabTitle } from "../../../utils/Browser";
export default function SettingsPage() {
    const [settingsEnv, setSettingsEnv] = useState([]);
    changeBrowserTabTitle("Settings");
    useEffect(() => {
        function loadFormsHandler() {
            let data = {};
            try {
                const settingsApi = apiRouteList.loggedRoutes.envShow;

                axios({
                    method: settingsApi.method,
                    url: settingsApi.endpoint,
                    headers: settingsApi.headers,
                })
                    .then((response) => {
                        data = response.data.data;
                        //formatting the data according to frontend's convenience
                        const arrayTemp = [];
                        let i = 0;
                        for (const key in data) {
                            arrayTemp.push({
                                id: i++,
                                title: key,
                                inputs: data[key],
                            });
                        }
                        setSettingsEnv(arrayTemp);
                    })
                    .catch((error) => {
                        setSettingsEnv([]);
                    });
            } catch (error) {
                setSettingsEnv([]);
            }
        }
        loadFormsHandler();
    }, []);
    return (
        <>
            <DashboardLayout>
                <ContentBodyWrapper showFooter={true}>
                    <div className="space-y-8">
                        <div className="overflow-hidden rounded-md">
                            <SettingPictureUploader />
                        </div>

                        <div className="rounded-md overflow-hidden">
                            <div className="bg-white dark:bg-slate-800 px-5 py-7">
                                <div className="grid md:grid-cols-2 xl:grid-cols-2 gap-7">
                                    {settingsEnv.map((item, index) => {
                                        return (
                                            <SettingsCard
                                                key={index}
                                                id={item.id}
                                                title={item.title}
                                                inputs={item.inputs}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </ContentBodyWrapper>
            </DashboardLayout>
        </>
    );
}
