import reactRouteList from "../config/react-route-list-config";

export const accordionMenuItems = [
    {
        title: "Dashboard",
        icon: "ant-design:home-outlined",
        permission: null,
        child: [
            {
                childtitle: "Analytic",
                link: reactRouteList.loggedRoutes.dashboard,
                permission: null,
            },
            {
                childtitle: "Ecommerce",
                link: reactRouteList.loggedRoutes.dashboard_ecommerce,
                permission: null,
            },
        ],
    },
    {
        title: "Users",
        permission: "menu users_list",
        icon: "ant-design:user",
        child: [
            {
                childtitle: "List",
                permission: "menu users_list",
                link: reactRouteList.loggedRoutes.user_list,
            },
        ],
    },
    {
        title: "Role Permissions",
        icon: "carbon:task-settings",
        permission: "menu role_permission",
        child: [
            {
                permission: "menu role_permission_permissions",
                childtitle: "Permissions",
                link: reactRouteList.loggedRoutes.permission_list,
            },
            {
                childtitle: "Roles",
                permission: "menu role_permission_roles",
                link: reactRouteList.loggedRoutes.role_list,
            },
        ],
    },
    {
        title: "Database Backup",
        icon: "iconoir:database-backup",
        permission: "menu database_backup",
        link: reactRouteList.loggedRoutes.database_backup,
    },

    {
        title: "Multi Level",
        icon: "mdi:subtasks",
        permission: null,
        child: [
            {
                permission: null,
                childtitle: "Sub menu 1",

                child: [
                    {
                        permission: null,
                        childtitle: "Sub menu 1.1",
                        link: reactRouteList.loggedRoutes.nested_menu_link_1_1,
                    },
                    {
                        childtitle: "Sub menu 1.2",
                        permission: null,
                        link: reactRouteList.loggedRoutes.nested_menu_link_1_2,
                    },
                ],
            },
            {
                childtitle: "Sub menu 2",
                permission: null,
                link: reactRouteList.loggedRoutes.nested_menu_link_2,
            },
        ],
    },
];
