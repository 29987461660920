import React from "react";

const Checkbox = React.forwardRef(({ onChange, defaultChecked }, ref) => {
    return (
        <>
            <input
                ref={ref}
                defaultChecked={defaultChecked}
                type="checkbox"
                onChange={onChange}
                className="checkbox-style"
            />
        </>
    );
});

export default Checkbox;
