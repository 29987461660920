import DashboardLayout from "../../../../Layouts/DashboardLayout";
import ContentBodyWrapper from "../../../../Layouts/Containers/ContentBodyWrapper";
import ContentBodyHeader from "../../../../Layouts/Containers/ContentBodyHeader";
import PermissionTable from "./PermissionTable";
import reactRouteList from "../../../../config/react-route-list-config";
import { useTranslation } from "react-i18next";
import { permissionCheck } from "../../../../utils/permission";
import { changeBrowserTabTitle } from "../../../../utils/Browser";
import ButtonBlack from "../../../../components/Buttons/ButtonBlack";
export default function PermissionListPage() {
    const [t] = useTranslation();
    changeBrowserTabTitle("Permissions");

    const buttons = (
        <ButtonBlack
            to={reactRouteList.loggedRoutes.create_permissions}
            icon={"material-symbols:add"}
        >
            {t("New")}
        </ButtonBlack>
    );

    return (
        <>
            <DashboardLayout>
                <ContentBodyWrapper showFooter={true}>
                    <ContentBodyHeader
                        info={{
                            title: t("Permissions"),
                        }}
                        breadcrumb={[
                            {
                                linkitem: t("Permissions"),
                                link: reactRouteList.loggedRoutes
                                    .permission_list,
                            }
                        ]}
                        buttons={
                            permissionCheck("permission create") && buttons
                        }
                    />
                    <PermissionTable />
                </ContentBodyWrapper>
            </DashboardLayout>
        </>
    );
}
